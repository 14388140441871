import React, { createRef, useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BigPlayButton, Player, ControlBar, LoadingSpinner, Shortcut } from 'video-react';

import HLSSource from './HLSSource';
import Navigation, { HorizontalList, VerticalList } from 'components/Navigation';
import SvgIcon from 'components/SvgIcon';
import { getImageUrl, typeFullscreen, visual1 } from 'api/ImageManager';
import { goBack, goTo, isReturnEvent, PATH_PLAY } from 'utils/NavigationUtils';
import { getMovie, setMovie, updateProgressMovie } from 'redux/actions/CatalogActions';
import useAuth from 'hooks/useAuth';
import useSystem from 'hooks/useSystem';
import useEventListener from 'hooks/useEventListener';
import ControlFocusable from './ControlFocusable';
import Title, { FONT_WEIGHT_BOLD as TITLE_BOLD, LEVEL_4 as TITLE_LEVEL_4 } from 'components/Title';
import Text, { SIZE_XXS as TEXT_XXS, SIZE_XS as TEXT_XS } from 'components/Text';
import { ReactComponent as IconPlay } from 'assets/icons/icon-play.svg';
import { ReactComponent as IconPause } from 'assets/icons/icon-pause.svg';
import { ReactComponent as IconReplay } from 'assets/icons/icon-replay.svg';
import { ReactComponent as IconNext } from 'assets/icons/icon-next.svg';
import { ReactComponent as IconBack } from 'assets/icons/icon-arrow-back.svg';

import 'video-react/styles/scss/video-react.scss';

const UPDATE_PROGRESS_VIDEO_INTERVAL = 10;

const ALTERNATIVE_KEYS = ['vewd', 'zeasn', 'lg', 'swisscom', 'localhost'];

const defaultShortcuts = [
    // Override default player shortcuts
    {
        keyCode: 37, // LEFT
        handle: () => {}
    },
    {
        keyCode: 39, // RIGHT
        handle: () => {}
    },
    {
        keyCode: 38, // UP
        handle: () => {}
    },
    {
        keyCode: 40, // DOWN
        handle: () => {}
    },
    {
        keyCode: 75, // k
        handle: () => {}
    },
    {
        keyCode: 70, // f
        handle: () => {}
    },
    {
        keyCode: 74, // j
        handle: () => {}
    },
    {
        keyCode: 76, // l
        handle: () => {}
    },
    {
        keyCode: 36, // Home
        handle: () => {}
    },
    {
        keyCode: 35, // End
        handle: () => {}
    },
    {
        keyCode: 190, // Shift + >
        shift: true,
        handle: () => {}
    },
    {
        keyCode: 188, // Shift + <
        shift: true,
        handle: () => {}
    }
];

const customShortcuts = [
    {
        keyCode: 415, // VK_PLAY
        handle: (player, actions) => {
            actions.play({
                action: 'play',
                source: 'shortcut'
            });
        }
    },
    {
        keyCode: 19, // VK_PAUSE
        handle: (player, actions) => {
            actions.pause({
                action: 'pause',
                source: 'shortcut'
            });
        }
    },
    {
        keyCode: 417, // VK_FAST_FWD
        handle: (player, actions) => {
            actions.forward(10, {
                action: 'forward-10',
                source: 'shortcut'
            });
        }
    },
    {
        keyCode: 412, // VK_REWIND
        handle: (player, actions) => {
            actions.replay(10, {
                action: 'replay-10',
                source: 'shortcut'
            });
        }
    },
    {
        keyCode: 413, // VK_STOP
        handle: (player, actions) => {
            actions.pause({
                action: 'pause',
                source: 'shortcut'
            });
            actions.seek(0, {
                action: 'seek',
                source: 'shortcut'
            });
        }
    }
];

const playerConf = {
    fluid: false,
    videoId: 'Player1',
    preload: 'auto',
    autoPlay: true,
    width: '100%',
    height: '100%'
};

const CustomPlayer = ({ hostname, ...restProps }) => {
    let navigation = createRef();
    let playerRef = createRef();
    let vListRef = createRef();

    const dispatch = useDispatch();
    const history = useHistory();
    const { FilmID: filmId } = useParams();
    const videoType = new URLSearchParams(window.location.search).get('type');
    const { profileVersion, user } = useAuth();
    const { deviceId } = useSystem();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isProgressLoaded, setIsProgressLoaded] = useState(false);
    const [videoPaused, setVideoPaused] = useState(false);
    const [currentTime, setCurrentTime] = useState(null);
    const [timeTotal, setTimeTotal] = useState(null);
    const [isEnded, setIsEnded] = useState(false);
    const [userActive, setUserActive] = useState(false);
    const [lastProgressUpdate, setLastProgressUpdate] = useState(null);
    const [isPlayFocus, setIsPlayFocus] = useState(false);
    const { isChannelsControlsDisabled, isChannelsMovies, movie, nextPlaylist } = useSelector(state => state.catalog);

    const isControlsDisabled = isChannelsMovies && isChannelsControlsDisabled;

    const shortcuts = useMemo(() => {
        // TODO
        return ALTERNATIVE_KEYS.includes(hostname)
            ? defaultShortcuts.concat(customShortcuts)
            : defaultShortcuts;
        // return defaultShortcuts;
    }, [hostname]);

    const convertTimeToDisplay = seconds => {
        let minutes = 0;
        let hours = 0;

        if (seconds >= 60) {
            minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
        }

        if (minutes >= 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
        }

        return (
            (hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '') +
            (minutes > 0 ? `${minutes.toString().padStart(2, '0')}:` : '00:') +
            Math.floor(seconds).toString().padStart(2, '0')
        );
    };

    const currentDisplayableTime = useMemo(() => convertTimeToDisplay(currentTime), [currentTime]);

    const totalDisplayableTime = useMemo(() => convertTimeToDisplay(timeTotal), [timeTotal]);

    const progressMarker = useMemo(() => {
        const position = Math.round(((currentTime * 100) / timeTotal) * 100) / 100;
        return {
            position: `${position}%`,
            background: `linear-gradient(90deg, #ffffff 0%, #ffffff ${position}%, #b3b3b3 ${position}%, #b3b3b3 100%)`
        };
    }, [currentTime, timeTotal]);

    const hlsSource = useMemo(() => {
        if (!movie) return;
        return videoType === 'trailer' ? movie.trailer[0].vimeo_hls : movie.vimeo_hls;
    }, [movie, videoType]);

    const keyDownListenerHandler = event => {
        if (isReturnEvent(event)) goBack(history);

        const keyCode = event.keyCode || event.which;
        switch (keyCode) {
            case 37:
                leftArrowHandler();
                break;
            case 39:
                rightArrowHandler();
                break;
            default:
                return;
        }
    };

    const playerStateChangeHandler = (state, prevState) => {
        const { paused, currentTime, duration, ended, userActivity } = state;
        const {
            paused: prevPaused,
            currentTime: prevTime,
            duration: prevDuration,
            ended: prevEnded,
            userActivity: prevUserActivity
        } = prevState;

        if (paused !== prevPaused) setVideoPaused(paused);
        if (currentTime !== prevTime) setCurrentTime(currentTime);
        if (duration !== prevDuration) setTimeTotal(duration);
        if (ended !== prevEnded) setIsEnded(ended);
        if (userActivity !== prevUserActivity) setUserActive(userActivity);
    };

    const onCanPlayHandler = () => {
        playerRef.manager.rootElement.firstChild.removeAttribute('src');
        playerRef.manager.video.video.firstChild.removeAttribute('src');
    };

    const onClickProgressBarHandler = event => {
        const { clientX, target } = event;
        const progressBar = target.getBoundingClientRect();
        const progressMarker = target.firstChild.getBoundingClientRect();

        const targetTime =
            ((clientX - progressBar.x + progressMarker.width / 2) * timeTotal) / progressBar.width;
        playerRef.seek(targetTime);
    };

    const backBtnAction = () => goBack(history);

    const replayBtnAction = () => playerRef.seek(0);

    const nextBtnAction = useCallback(() => {
        if ( nextPlaylist?.length > 0 ) {
            setIsEnded(false);
            const nextMovie = nextPlaylist[0];
            dispatch(setMovie(nextMovie, nextPlaylist.slice(1)));
            goTo(history, PATH_PLAY + '/' + nextMovie.transaction, true);
        } else {
            goBack(history);
        }
    }, [dispatch, history, nextPlaylist]);

    const playBtnAction = () => {
        if (videoPaused) playerRef.play();
        else playerRef.pause();
    };

    const controlFocusHandler = (i, event, type) => {
        switch (type) {
            case 'play':
                setIsPlayFocus(true);
                break;
            case 'back':
            case 'reset':
            case 'next':
                if (!userActive) playerRef.startControlsTimer();
                break;
            default:
                break;
        }
    };

    const playBtnBlurHandler = () => {
        setIsPlayFocus(false);
    };

    const rightArrowHandler = () => {
        if (!isPlayFocus) return;
        const { player } = playerRef.getState();
        playerRef.seek(player.currentTime + 10);
    };

    const leftArrowHandler = () => {
        if (!isPlayFocus) return;
        const { player } = playerRef.getState();
        playerRef.seek(player.currentTime - 10);
    };

    useEffect(() => {
        if (movie?.transaction !== filmId) {
            dispatch(getMovie(filmId, user.NoCli, profileVersion));
        } else {
            setIsLoaded(true);
        }
    }, [dispatch, filmId, movie, profileVersion, user]);

    useEffect(() => {
        if (isLoaded && playerRef) {
            playerRef.handleFocus();
            playerRef.subscribeToStateChange(playerStateChangeHandler);
        }
        return () => playerRef?.handleBlur();
    }, [isLoaded, playerRef]);

    useEffect(() => {
        if (!isEnded) return;
        nextBtnAction();
    }, [isEnded, nextBtnAction]);

    // TODO
    useEffect(() => {
        if (!userActive && !videoPaused) {
            navigation.forceFocus(vListRef.children[1].focusableId);
        }
    }, [userActive, videoPaused, navigation, vListRef]);

    /**
     * Update progress movie
     */
    useEffect(() => {
        if ( currentTime && timeTotal ) {
            const currentTimeInt = parseInt( currentTime );
            const timeTotalInt = parseInt( timeTotal );

            if ( ! lastProgressUpdate || currentTimeInt >= (lastProgressUpdate || 0) + UPDATE_PROGRESS_VIDEO_INTERVAL )  {
                dispatch(updateProgressMovie(user.NoCli, profileVersion, movie, currentTimeInt, timeTotalInt, deviceId));
                setLastProgressUpdate(currentTime);
            }
        }
    }, [currentTime, deviceId, dispatch, lastProgressUpdate, movie, profileVersion, timeTotal, user]);

    useEffect(() => {
        if (!isLoaded) return;
        const timeTotal = movie.timeTotal;
        const progressTime = (movie.progressTime ? movie.progressTime : movie.time) || null;
        if (!isProgressLoaded && progressTime && timeTotal && +progressTime < +timeTotal) {
            playerRef.video.seek(+progressTime);
            setIsProgressLoaded(true);
        }
    }, [isLoaded, isProgressLoaded, movie, playerRef]);

    useEventListener('keydown', keyDownListenerHandler);

    return (
        <Navigation ref={r => (navigation = r)}>
            <div className="player">
                <Player
                    poster={getImageUrl(filmId, typeFullscreen, visual1)}
                    ref={r => (playerRef = r)}
                    onCanPlay={onCanPlayHandler}
                    {...playerConf}
                >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    {isLoaded && <HLSSource isVideoChild src={hlsSource} />}
                        <ControlBar
                            className="player__controls__overlay"
                            autoHide={true}
                            autoHideTime={3000}
                            disableDefaultControls
                        >
                            <VerticalList ref={r => (vListRef = r)} className="player__controls">
                                <HorizontalList className="player__controls__top-left">
                                    <ControlFocusable
                                        action={backBtnAction}
                                        className="player__controls__button player__controls__button--back"
                                        onFocus={(i, e) => controlFocusHandler(i, e, 'back')}
                                    >
                                        <SvgIcon icon={IconBack} width="2vw" height="2vw" />
                                        <Text size={TEXT_XS}>Retour</Text>
                                    </ControlFocusable>
                                    {
                                        ! isControlsDisabled &&
                                        <>
                                            <ControlFocusable
                                                action={replayBtnAction}
                                                className="player__controls__button player__controls__button--replay"
                                                onFocus={(i, e) => controlFocusHandler(i, e, 'replay')}
                                            >
                                                <SvgIcon icon={IconReplay} width="2vw" height="2vw" />
                                                <Text size={TEXT_XS}>Revenir au début</Text>
                                            </ControlFocusable>
                                            {
                                                nextPlaylist?.length > 0 &&
                                                <ControlFocusable
                                                    action={nextBtnAction}
                                                    className="player__controls__button player__controls__button--next"
                                                    onFocus={(i, e) => controlFocusHandler(i, e, 'next')}
                                                >
                                                    <SvgIcon icon={IconNext} width="2vw" height="2vw" />
                                                    <Text size={TEXT_XS}>Episode suivant</Text>
                                                </ControlFocusable>
                                            }
                                        </>
                                    }
                                </HorizontalList>
                                <div className="player__controls__top-center">
                                    <Title level={TITLE_LEVEL_4} fontWeight={TITLE_BOLD}>
                                        {movie?.titre}
                                    </Title>
                                    {/* <Text>S1E2: episode</Text> */}
                                </div>
                                <div className="player__controls__bottom">
                                    <ControlFocusable
                                        action={playBtnAction}
                                        className="player__controls__bottom__play"
                                        defaultFocus={true}
                                        onFocus={(i, e) => controlFocusHandler(i, e, 'play')}
                                        onBlur={playBtnBlurHandler}
                                    >
                                        {
                                            videoPaused ?
                                            <>
                                                <SvgIcon icon={IconPlay} width="2vw" height="2vw" />
                                                <Text size={TEXT_XS}>Lecture</Text>
                                            </>
                                            :
                                            <>
                                                <SvgIcon icon={IconPause} width="2vw" height="2vw" />
                                                <Text size={TEXT_XS}>Pause</Text>
                                            </>
                                        }
                                    </ControlFocusable>
                                    {
                                        ! isControlsDisabled &&
                                        <div className="player__controls__bottom__progress">
                                            <div
                                                className="player__controls__bottom__progress__bar"
                                                style={{ background: progressMarker.background }}
                                                onClick={onClickProgressBarHandler}
                                            >
                                                <div
                                                    className="player__controls__bottom__progress__marker"
                                                    style={{ left: progressMarker.position }}
                                                />
                                                {
                                                    currentDisplayableTime && totalDisplayableTime &&
                                                    <Text
                                                        className="player__controls__bottom__progress__time"
                                                        size={TEXT_XXS}
                                                    >
                                                        {`${currentDisplayableTime} / ${totalDisplayableTime}`}
                                                    </Text>
                                                }
                                                {/* {totalDisplayableTime && (
                                                    <Text
                                                        className="player__controls__bottom__progress__time-total"
                                                        size={TEXT_XXS}
                                                    >
                                                        {totalDisplayableTime}
                                                    </Text>
                                                )} */}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </VerticalList>
                        </ControlBar>
                    <Shortcut clickable={false} dblclickable={false} shortcuts={shortcuts} />
                </Player>
            </div>
        </Navigation>
    );
};

CustomPlayer.propTypes = {};

CustomPlayer.defaultProps = {};

export default CustomPlayer;
