import React from 'react';
import PropTypes from 'prop-types';
import { VerticalList } from 'components/Navigation';

import Season from './Season';

const Seasons = ({
    manageFocus,
    navigation,
    seasons,
    setEpisodeAction,
    setMouseFocus,
    seasonActiveIndex,
    seasonFocusedIndex,
    setSeasonActiveIndex
}) => {
    const onSeasonActionHandler = (action, index) => {
        const episodeTarget = seasons[index].episodes[0];

        switch (action) {
            case 'focus':
                manageFocus(index);
                setEpisodeAction('scroll', episodeTarget.transaction);
                setSeasonActiveIndex(index);
                break;
            case 'enter':
                setEpisodeAction('focus', episodeTarget.transaction);
                break;
            case 'click':
                setEpisodeAction('scroll', episodeTarget.transaction);
                // setEpisodeAction('focus', episodeTarget.transaction);
                setSeasonActiveIndex(index);
                break;
            case 'mouseEnter':
                setMouseFocus(true);
                manageFocus(index);
                break;
            default:
                break;
        }
    };

    return (
        <VerticalList>
            {seasons.map((season, i) => (
                <Season
                    key={i}
                    isActive={seasonActiveIndex === i}
                    season={season}
                    setMouseFocus={setMouseFocus}
                    isFocus={seasonFocusedIndex === i}
                    navigation={navigation}
                    parentOnEnterHandler={() => onSeasonActionHandler('enter', i)}
                    parentOnClickHandler={() => onSeasonActionHandler('click', i)}
                    parentOnFocusHandler={() => onSeasonActionHandler('focus', i)}
                    parentOnMouseEnterHandler={() => onSeasonActionHandler('mouseEnter', i)}
                />
            ))}
        </VerticalList>
    );
};

Seasons.propTypes = {
    manageFocus: PropTypes.func,
    navigation: PropTypes.object,
    seasonFocusedIndex: PropTypes.number,
    seasons: PropTypes.array,
    seasonActiveIndex: PropTypes.number,
    setEpisodeAction: PropTypes.func,
    setMouseFocus: PropTypes.func,
    setSeasonActiveIndex: PropTypes.func
};

Seasons.defaultProps = {};

export default Seasons;
