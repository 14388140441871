import React from 'react';
import { LastLocationProvider } from 'react-router-last-location';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { SystemContext } from 'contexts';
import useAuth from 'hooks/useAuth';

import Catalog from 'pages/Catalog';
import Category from 'pages/Category';
import Channel from 'pages/Channels/Channel';
import ChannelsProgram from 'pages/Channels/ChannelsProgram';
import CustomPlayer from 'components/Player';
// import PlayerSafe from 'components/PlayerFireStick';
// import MyPlayer from 'components/Player/Player';
import Details from 'components/PageDetails';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import LogoutConfirm from 'pages/LogoutConfirm';
import Profile from 'pages/Profile/Profile';
import ProfileAvatar from 'pages/Profile/ProfileAvatar';
import ProfileEdit from 'pages/Profile/ProfileEdit';
import Search from 'pages/Search';
import SplashScreen from 'components/SplashScreen';
import Subscription from 'pages/Subscription';

import {
    customHistory,
    goTo,
    PATH_ROOT,
    PATH_HOME,
    PATH_LOGIN,
    PATH_EXIT,
    PATH_SUBSCRIBE,
    PATH_SEARCH,
    PATH_DETAIL, PATH_PARAMS_FILM_ID,
    PATH_PLAY,
    PATH_LANDING,
    PATH_PROFILE, PATH_PROFILE_AVATAR, PATH_PROFILE_EDIT,
    PATH_CATEGORY, PATH_PARAMS_CATEGROY_ID,
    PATH_CHANNEL, PATH_CHANNELS_PROGRAM, PATH_PARAMS_CHANNEL_ID,
    PATH_LOGOUT_CONFIRM, PATH_PARAMS_LOGOUT_ACTION,
} from 'utils/NavigationUtils';

const hostname = window.location.hostname.replace('.zone300.tv', '');

const Router = () => {
    const { isAuthenticated, isInitialized } = useAuth();
    return !isInitialized ? (
        <SplashScreen parent={this} />
    ) : (
        <BrowserRouter>
            <LastLocationProvider>
                <SystemContext.Consumer>
                    {systemValues => (
                        <Switch>
                            <Route
                                exact
                                path={PATH_ROOT}
                                render={props => {
                                    if (!isAuthenticated) {
                                        goTo(props.history, PATH_LANDING, true);
                                    } else {
                                        goTo(props.history, PATH_HOME, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_LANDING}
                                render={props => {
                                    if (!isAuthenticated) {
                                        return (
                                            <Landing {...props} hostname={systemValues.system} />
                                        );
                                    } else {
                                        if (customHistory.length > 0) {
                                            goTo(props.history, PATH_EXIT, true);
                                        } else {
                                            goTo(props.history, PATH_HOME);
                                        }
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_SUBSCRIBE}
                                render={props => (
                                    <Subscription {...props} hostname={systemValues.system} />
                                )}
                            />
                            <Route
                                exact
                                path={PATH_LOGIN}
                                render={props => {
                                    if (!isAuthenticated) {
                                        return <Login {...props} app={this} />;
                                    } else {
                                        goTo(props.history, PATH_HOME, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_HOME}
                                render={props => {
                                    if (isAuthenticated) {
                                        return (
                                            <Catalog
                                                {...props}
                                                app={this}
                                                // sidebarExit={customHistory.length > 0}
                                                sidebarExit={false}
                                            />
                                            // TODO condition on custom history context
                                        );
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_SEARCH}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Search {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_PROFILE}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Profile {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_PROFILE_AVATAR}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <ProfileAvatar {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_PROFILE_EDIT}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <ProfileEdit {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_CATEGORY + PATH_PARAMS_CATEGROY_ID}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Category {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_EXIT}
                                render={props => {
                                    if (['amazon', 'android'].includes(systemValues.system)) window.closeApp();
                                    window.close();
                                }}
                            />
                            <Route
                                exact
                                path={PATH_LOGOUT_CONFIRM + PATH_PARAMS_LOGOUT_ACTION}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <LogoutConfirm {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_DETAIL + PATH_PARAMS_FILM_ID}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Details {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_CHANNEL + PATH_PARAMS_CHANNEL_ID}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Channel {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_CHANNELS_PROGRAM}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <ChannelsProgram {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_PLAY + PATH_PARAMS_FILM_ID}
                                render={props => {
                                    if (isAuthenticated) {
                                        return (
                                            <CustomPlayer {...props} hostname={hostname} />
                                            // <MyPlayer
                                            //     {...props}
                                            //     hostname={hostname}
                                            //     systemContext={systemValues}
                                            //     isAndroid={['amazon', 'android'].includes(
                                            //         systemValues.system
                                            //     )}
                                            // />
                                        );
                                    } else {
                                        goTo(props.history, PATH_LANDING, true);
                                    }
                                }}
                            />
                        </Switch>
                    )}
                </SystemContext.Consumer>
            </LastLocationProvider>
        </BrowserRouter>
    );
};

export default Router;
