import React, { Component } from 'react';
import { HorizontalList } from 'components/Navigation';
import PropTypes from 'prop-types';

import ListItem from './ListItem.js';

/**
 * Horizontal list, wrappable, containing ListItems
 */
class List extends Component {
    /**
     * Array containing references of the items
     * @type {[]}
     */
    itemsRef = [];

    /**
     * Gap value in px for the vertical scroll on list focus
     * @type {number}
     */
    static SCROLL_GAP = 40;

    /**
     * Constructor of the List class
     * @param props
     */
    constructor(props) {
        super(props);
        this.leftArrowDisabled = false;
        this.rightArrowDisabled = false;
        this.hzScrollActive = true;

        this.state = {
            isLoaded: false,
            activeItem: null
        };

        this.horizontalList = React.createRef();
        this.onFocusList = this.onFocusList.bind(this);
    }

    /**
     * Set activeItem state to given index or null
     * @param index
     */
    setItemActive(index = null) {
        this.setState({ activeItem: index });
        if ( this.props.setProgramFocused ) this.props.setProgramFocused(false);
    };

    /**
     * Scroll to see the list in screen if not totally visible on focus event,
     * set active state to true
     */
    onFocusList() {
        const { viewport, rowRef } = this.props;
        if (!viewport?.getBoundingClientRect || !rowRef?.getBoundingClientRect) return;
        const categoryTop = rowRef.getBoundingClientRect().top;
        const categoryBottom = rowRef.getBoundingClientRect().bottom;
        const { top, bottom } = viewport.getBoundingClientRect();

        if (categoryBottom > bottom) {
            viewport.scrollTop += categoryBottom - bottom + List.SCROLL_GAP;
        } else if (categoryTop < top) {
            viewport.scrollTop += categoryTop - top /*- List.SCROLL_GAP*/;
        }

        this.props.setThisListActive();
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    render() {
        const { categoryIndex, goBack, hGap, isChannelMovie, isChannelRow, items, navigation, parentCategory, redirectTo, style, verticalAutoScroll, viewport, withProgression, wrappedList } = this.props;

        return (
            this.state.isLoaded && (
                <HorizontalList
                    ref={el => (this.horizontalList = el)}
                    className={'list' + (wrappedList ? ' list--wrapped' : '') + (isChannelRow ? ' list--channelRow' : '')}
                    onFocus={this.onFocusList}
                    retainLastFocusCatalog
                >
                    {
                        items.map((item, i) => (
                            <ListItem
                                key={i}
                                categoryIndex={categoryIndex + '-' + i}
                                goBack={goBack}
                                hGap={hGap ? hGap : 20}
                                horizontalAutoScroll
                                isChannelMovie={isChannelMovie}
                                item={item}
                                itemIndex={i}
                                navigation={navigation}
                                parentCategory={parentCategory}
                                parentList={this}
                                redirectTo={redirectTo}
                                ref={el => (this.itemsRef[i] = el)}
                                retainLastFocusCatalog
                                setThisItemActive={() => this.setItemActive(i)}
                                style={style}
                                verticalAutoScroll={verticalAutoScroll}
                                vGap={verticalAutoScroll ? 150 : null}
                                viewport={viewport}
                                withProgression={withProgression}
                            />
                        ))
                    }
                    {/* TODO Replace by ::after */}
                    <div className="list__gap" />
                </HorizontalList>
            )
        );
    }
}

export default List;

List.propTypes = {
    redirectTo: PropTypes.func,
    goBack: PropTypes.func,
    navigation: PropTypes.object,
    parentCategory: PropTypes.object,
    catIsInViewport: PropTypes.func,
    items: PropTypes.array,
    style: PropTypes.object,
    wrappedList: PropTypes.bool,
    withProgression: PropTypes.bool
};

/**
 * Default properties fot the List component
 * @type {{wrappedList: boolean}}
 */
List.defaultProps = {
    wrappedList: false,
    withProgression: false
};
