import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AvatarItem from 'components/AvatarItem';
import { Focusable } from 'components/Navigation';
import Text, { ALIGN_CENTER, SIZE_LARGE, SIZE_SMALL } from 'components/Text';

import { getImageUrl, typeThumb, typePoster, typeSquare, visual1, visual5, visual8, visual10 } from 'api/ImageManager';
import { setIsChannelsMovies, setMovie, setSpecificLastFocus } from 'redux/actions/CatalogActions';
import CssClassUtils from 'utils/CssClassUtils';
import { LAST_FOCUS_CATALOG, LAST_FOCUS_CATEGORY, LAST_FOCUS_CHANNELS_PROGRAM, LAST_FOCUS_SEARCH_GRID_INDEX, PATH_CHANNEL, PATH_DETAIL, PATH_PLAY } from 'utils/NavigationUtils';

class ListItem extends Component {
    static CSS_CLASS_BASE = 'list-item';

    static CSS_CLASS_ACTIVE =
        CssClassUtils.CLASS_SEPARATOR +
        ListItem.CSS_CLASS_BASE +
        CssClassUtils.CLASS_MOD_SEPARATOR +
        CssClassUtils.CLASS_STATE_ACTIVE;

    poster;

    constructor(props) {
        super(props);
        this.state = {
            isFocusInitialized: false,
            isLoaded: false,
            active: false,
            imageStatus: false,
            titleVisible: true
        };

        this.itemImg = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onEnterDown = this.onEnterDown.bind(this);
        this.isInViewport = this.isInViewport.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.refItem = this.refItem.bind(this);

        this.focusTimeout = null;
    };

    componentDidMount() {
        const { item } = this.props;

        switch (this.props.style.type) {
            case 'poster':
                this.poster = getImageUrl(item.transaction, typePoster, visual5);
                break;
            case 'posterLong':
                this.poster = getImageUrl(item.transaction, typePoster, visual10);
                break;
            case 'square':
                this.poster = getImageUrl(item.transaction, typeSquare, visual8);
                break;
            case 'thumbnail':
            default:
                this.poster = getImageUrl(item.transaction, typeThumb, visual1);
        }
        this.setState({ isLoaded: true });
    };

    componentWillUnmount() {
        clearTimeout( this.focusTimeout );
    };

    /**
     * Prevent rendering component if not in screen
     * @param nextProps
     * @param nextState
     * @param nextContext
     * @returns {boolean}
     */
    // shouldComponentUpdate( nextProps, nextState, nextContext ) {
    //     return this.isItemVisible();
    // };

    getClasses( active, type, size ) {
        const baseClass = 'list-item';
        let classes = baseClass;
        classes += active ? ` ${baseClass}--active` : '';
        classes += type ? ` ${baseClass}--${type.toLowerCase()}` : ` ${baseClass}--thumbnail`;
        classes += size ? ` ${baseClass}--${size.toLowerCase()}` : '';
        return classes;
    };

    getTimeFormat( timestamp ) {
        return `à ${new Intl.DateTimeFormat( 'fr', { timeStyle: 'short' } ).format(timestamp * 1000).replace( ':', ' h ' )}`;
    };

    /**
     * @TODO
     */
    handleImageErrored() {
        this.setState({ imageStatus: false });
    };

    /**
     * @TODO
     */
    handleImageLoaded() {
        if (this.props.item.aff_titre !== '1') {
            this.setState({ titleVisible: false });
        }
        this.setState({ imageStatus: true });
    };

    /**
     * Horizontal or/and vertical scroll if the focus item not in viewport
     */
    isInViewport(horizontal, horizontalGap = 0, vertical = false, verticalGap = 0) {
        const image = this.itemImg;
        if (!image) return;
        if (horizontal) {
            //Horizontal scroll
            const list = image.parentNode.parentNode;

            const itemLeft = image.getBoundingClientRect().left - image.offsetWidth * 0.05;
            const itemRight = image.getBoundingClientRect().right + image.offsetWidth * 0.05;
            const { left, right } = list.getBoundingClientRect();

            if (itemRight > right) {
                list.scrollLeft += itemRight - right + horizontalGap;
            } else if (itemLeft < left) {
                list.scrollLeft += itemLeft - left /*+ horizontalGap*/;
            }
        }

        if (vertical) {
            //Vertical scroll
            const { viewport } = this.props;

            const itemTop = image.getBoundingClientRect().top - image.offsetHeight * 0.05;
            const itemBottom = image.getBoundingClientRect().bottom + image.offsetHeight * 0.05;
            const { top, bottom } = viewport.getBoundingClientRect();

            if (itemBottom > bottom) {
                viewport.scrollTop += itemBottom - bottom + verticalGap;
            } else if (itemTop < top) {
                viewport.scrollTop += itemTop - top - verticalGap;
            }
        }
    };

    /**
     * Returns if true the component is in the screen or close to be
     * @returns {boolean}
     */
    isItemVisible() {
        const { parentList, itemIndex } = this.props;
        const { type: itemType, size: itemSize } = this.props.style;

        let lazyLoadingShift = 6;
        if (this.props.lazyLoadingShift) {
            lazyLoadingShift = this.props.lazyLoadingShift;
        } else if (itemType === 'square') {
            lazyLoadingShift = 6;
        } else if (itemType === 'poster') {
            if (itemSize === 'XS' || itemSize === 'XXS') {
                lazyLoadingShift = 9;
            } else if (itemSize === 'XL' || itemSize === 'XXL') {
                lazyLoadingShift = 6;
            } else {
                lazyLoadingShift = 7;
            } 
        } else if (itemType === 'posterLong') {
            if (itemSize === 'XXS') {
                lazyLoadingShift = 10;
            } else if (itemSize === 'XS') {
                lazyLoadingShift = 9;
            } else if (itemSize === 'XL' || itemSize === 'XXL') {
                lazyLoadingShift = 5;
            } else {
                lazyLoadingShift = 7;
            }
        }

        return (
            itemIndex > parentList.state.activeItem - lazyLoadingShift &&
            itemIndex < parentList.state.activeItem + lazyLoadingShift
        );
    };

    /**
     * Change active state to false on blur event
     */
    onBlur() {
        this.setState({ active: false });
    };

    /**
     * Redirect to detail page or player for resume reading row
     */
    onEnterDown() {
        if ( ! this.state.active ) return;
        const { channelIndex, channelMovies, channelsMoviesBySheetId, channelsProgramList, channelStartAt, item, itemIndex, isChannelMovie, isChannelProgram, isInLive, progressTimeForChannel, redirectTo, retainLastFocusCatalog, retainLastFocusCategory, retainLastFocusChannelsProgram, retainLastFocusSearch, timeTotalForChannel } = this.props;

        if ( item.channelLabelLink ) {
            if ( retainLastFocusCatalog ) this.props.setSpecificLastFocus( LAST_FOCUS_CATALOG, this.itemFocusable?.focusableId );
            redirectTo(`${ PATH_CHANNEL }/${ item.channelLabelLink }`);
            return;
        }

        let withProgression = this.props.withProgression;
        let nextPlaylist = [];

        if ( isChannelProgram ) {
            if ( isInLive && progressTimeForChannel && timeTotalForChannel ) withProgression = true;
            if ( channelsProgramList && channelsProgramList[ channelIndex ]?.moviesList ) {
                const nextProgramList = channelsProgramList[ channelIndex ].moviesList.filter( el => parseInt( el.startAt ) > channelStartAt );
                if ( nextProgramList && nextProgramList.length > 0 ) {
                    nextProgramList.forEach( el => {
                        nextPlaylist.push( channelsMoviesBySheetId[ el.sheetId ] );
                    });
                }
            }
        }

        if ( isChannelMovie ) {
            withProgression = true;
            nextPlaylist = channelMovies.slice(1);
        }

        this.props.setIsChannelsMovies(!!isChannelProgram || !!isChannelMovie);
        this.props.setMovie(item, nextPlaylist);
        if ( retainLastFocusCatalog ) this.props.setSpecificLastFocus( LAST_FOCUS_CATALOG, this.itemFocusable?.focusableId );
        if ( retainLastFocusCategory ) this.props.setSpecificLastFocus( LAST_FOCUS_CATEGORY, this.itemFocusable?.focusableId );
        if ( retainLastFocusChannelsProgram ) this.props.setSpecificLastFocus( LAST_FOCUS_CHANNELS_PROGRAM, [channelIndex, itemIndex] );
        if ( retainLastFocusSearch ) this.props.setSpecificLastFocus( LAST_FOCUS_SEARCH_GRID_INDEX, itemIndex );

        redirectTo(`${ withProgression ? PATH_PLAY : PATH_DETAIL }/${ item.transaction }`);
    };

    /**
     * Change some states chen item focus
     * @param index
     * @param evt
     * @returns {boolean}
     */
    onFocus(index, evt) {
        const { parentCategory, setCurrentDayIntFocused, setThisItemActive } = this.props;
        const { isLoaded } = this.state;

        if ( ! isLoaded ) return false;

        this.setState({ active: true });
        if ( parentCategory.setActualIndex ) parentCategory.setActualIndex(index);
        if ( parentCategory.setMouseFocus ) parentCategory.setMouseFocus(false);
        if ( parentCategory.setInputActive ) parentCategory.setInputActive(false);
        if ( setCurrentDayIntFocused ) setCurrentDayIntFocused(false);
        if ( setThisItemActive ) setThisItemActive();

        this.isInViewport(
            this.props.horizontalAutoScroll,
            this.props.hGap,
            this.props.verticalAutoScroll,
            this.props.vGap
        );
    };

    /**
     * Focus item and set state mouseFocus to true
     */
    onMouseEnter() {
        const { navigation, parentCategory } = this.props;

        if (navigation) navigation.forceFocus(this.itemFocusable.focusableId);
        parentCategory.setMouseFocus(true);
    };

    refItem(el) {
        const { channelIndex, isChannelProgram, isFirstLineGrid, isFirstLineGridElement, isFocused, isLastFocusElement, navigation } = this.props;
        const { isFocusInitialized } = this.state;

        if ( el ) this.itemFocusable = el;

        if ( navigation && this.itemFocusable.focusableId ) {
            if ( isFirstLineGrid ) navigation.retainFirstLineGridId(this.itemFocusable.focusableId);
            if ( isFirstLineGridElement ) navigation.retainFirstLineGridElementId(this.itemFocusable.focusableId);
            if ( isLastFocusElement ) navigation.retainLastFocusElementId(this.itemFocusable.focusableId);
            if ( isChannelProgram ) {
                this.focusTimeout = setTimeout(() => {
                    if ( el && el.focusableId ) {
                        navigation.retainChannelProgramMovieIds( el.focusableId, channelIndex );
                        if ( isFocused && ! isFocusInitialized ) {
                            this.setState({ isFocusInitialized: true });
                            if ( el && el.focusableId ) {
                                navigation.forceFocus( el.focusableId );
                                navigation.retainChannelProgramMovieFocusedId( el.focusableId, channelIndex );
                            }
                        }
                    }
                }, 300);
            }
        }
    };

    renderBadge( typeBadges ) {
        if ( typeBadges === 'New' ) return { class: '__badgeNew', text: 'Nouveauté' };
        if ( typeBadges === 'NewEpisode' ) return { class: '__badgeNew', text: 'Nouveaux épisodes' };
        if ( typeBadges === 'NewSeason' ) return { class: '__badgeNew', text: 'Nouvelle saison' };
        if ( typeBadges === 'EachFriday' ) return { class: '__badgeRecurrence', text: 'Chaque vendredi' };
        if ( typeBadges === 'EachWeek' ) return { class: '__badgeRecurrence', text: 'Chaque semaine' };

        return { class: '', text: '' };
    };

    render() {
        const { progressTimeForChannel, item, isInLive, navigation, style, timeTotalForChannel } = this.props;
        const { isLoaded, titleVisible } = this.state;

        let withProgression = this.props.withProgression;
        if ( isInLive && progressTimeForChannel && timeTotalForChannel ) {
            item.progressTime = progressTimeForChannel;
            item.timeTotal = timeTotalForChannel;
            withProgression = true;
        }

        const progress = Math.floor((+item.progressTime * 100) / +item.timeTotal);

        return (
            <Focusable
                className={this.getClasses(this.state.active, style.type, style.size)}
                ref={el => this.refItem(el)}
                onMouseEnter={this.onMouseEnter}
                onFocus={(i, evt) => this.onFocus(i, evt)}
                onBlur={this.onBlur}
                onClick={this.onEnterDown}
                onEnterDown={this.onEnterDown}
            >
                {
                    isLoaded &&
                    <>
                        <img
                            ref={el => (this.itemImg = el)}
                            src={this.poster}
                            alt={item.titre}
                            onLoad={this.handleImageLoaded.bind(this)}
                            onError={this.handleImageErrored.bind(this)}
                        />
                        {
                            titleVisible &&
                            <Text shadow={true}>{item.titre}</Text>
                        }
                        {
                            item.typeBadges && item.typeBadges.length > 0 &&
                            item.typeBadges.map( ( el, index ) => {
                                const badge = this.renderBadge( el );
                                return <Text key={index} className={ListItem.CSS_CLASS_BASE + badge.class}>{badge.text}</Text>
                            })
                        }
                        {
                            withProgression &&
                            <div className={ListItem.CSS_CLASS_BASE + '__progress-bar'}>
                                <div
                                    className={ListItem.CSS_CLASS_BASE + '__progress-bar--progression'}
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                        }
                        {
                            item.startAt &&
                            <Text size={SIZE_LARGE} className={ListItem.CSS_CLASS_BASE + '__startAt'}>
                                    { this.getTimeFormat( item.startAt ) }
                            </Text>
                        }
                        {
                            item.isInLive &&
                            <Text className={ListItem.CSS_CLASS_BASE + '__inLive'}>
                                En direct
                                <span className={ListItem.CSS_CLASS_BASE + '__inLiveDot'}/>
                            </Text>
                        }
                        {
                            item.channelTitle && item.channelImagePath &&
                            <div className={ListItem.CSS_CLASS_BASE + '__channelBox'}>
                                <AvatarItem
                                    alt={item.channelTitle}
                                    avatar={item.channelImagePath}
                                    className='channel-thumbnail'
                                    navigation={navigation}
                                    notFocusable={true}
                                />
                                <Text align={ALIGN_CENTER} size={SIZE_SMALL}>
                                    Chaîne {item.channelTitle}
                                </Text>
                            </div>
                        }
                    </>
                }
            </Focusable>
        );
    };
}

const stateToProps = state => ({
    channelMovies: state.catalog.channelMovies,
    channelsMoviesBySheetId: state.catalog.channelsMoviesBySheetId,
    channelsProgramList: state.catalog.channelsProgramList,
});

const dispatchToProps = dispatch => ({
    setIsChannelsMovies: isChannelsMovies => dispatch(setIsChannelsMovies(isChannelsMovies)),
    setMovie: (movie, nextPlaylist) => dispatch(setMovie(movie, nextPlaylist)),
    setSpecificLastFocus: (type, position) => dispatch(setSpecificLastFocus(type, position)),
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(ListItem);

ListItem.propTypes = {
    categoryIndex: PropTypes.string,
    goBack: PropTypes.func,
    hGap: PropTypes.number,
    horizontalAutoScroll: PropTypes.bool,
    item: PropTypes.object,
    itemIndex: PropTypes.number,
    navigation: PropTypes.object,
    parentList: PropTypes.object,
    parentCategory: PropTypes.object,
    redirectTo: PropTypes.func,
    retainLastFocusCatalog: PropTypes.bool,
    retainLastFocusCategory: PropTypes.bool,
    retainLastFocusChannelsProgram: PropTypes.bool,
    retainLastFocusSearch: PropTypes.bool,
    setThisItemActive: PropTypes.func,
    style: PropTypes.object,
    verticalAutoScroll: PropTypes.bool,
    vGap: PropTypes.number,
    viewport: PropTypes.object,
    withProgression: PropTypes.bool
};

ListItem.defaultProps = {
    horizontalAutoScroll: false,
    hGap: 0,
    retainLastFocusCatalog: false,
    retainLastFocusCategory: false,
    retainLastFocusChannelsProgram: false,
    retainLastFocusSearch: false,
    retainSearchFocus: false,
    style: { type: '', size: '' },
    verticalAutoScroll: false,
    vGap: 0,
    withProgression: false
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// const LAZY_LOADING_SHIFT = 5;

// const baseClass = 'list-item';
// const getClasses = (active, type, size) => {
//     let classes = baseClass;
//     classes += active ? ` ${baseClass}--active` : '';
//     classes += type ? ` ${baseClass}--${type}` : '';
//     classes += size ? ` ${baseClass}--${size}` : '';
//     return classes;
// };

// const ListItemV2 = (
//     {
//         hGap,
//         horizontalAutoScroll,
//         item,
//         itemIndex,
//         navigation,
//         parentCategory,
//         parentList,
//         setThisItemActive,
//         style,
//         verticalAutoScroll,
//         vGap,
//         viewport,
//         withProgression,
//         redirectTo
//     },
//     ref
// ) => {
//     const dispatch = useDispatch();

//     const [isActive, setIsActive] = useState(false);
//     const [isLoaded, setIsLoaded] = useState(false);
//     // const [imageStatus, setImageStatus] = useState(true);
//     const [titleVisible, setTitleVisible] = useState(true);

//     let focusableRef = createRef();
//     let imageRef = createRef();

//     const imageSrc = useMemo(() => {
//         let url = 'https://manager.zone300.com/api/cimage/webroot/img.php?src=';
//         switch (style.type) {
//             case 'square': {
//                 url += `${item.transaction}-VISUEL8.jpg&width=500&height=500`;
//                 break;
//             }
//             case 'poster': {
//                 url += `${item.posterFileName}.jpg&height=600`;
//                 break;
//             }
//             case 'thumbnail':
//             default: {
//                 url += `${item.thumbnailFileName}.jpg&h=200&crop-to-fit`;
//             }
//         }
//         return url;
//     }, [item, style]);

//     const currentProgression = useMemo(() => {
//         Math.floor((+item.progressTime * 100) / +item.timeTotal);
//     }, [item]);

//     const isItemVisible = useCallback(() => {
//         const { activeItem } = parentList.state;
//         return (
//             itemIndex > activeItem - LAZY_LOADING_SHIFT &&
//             itemIndex < activeItem + LAZY_LOADING_SHIFT
//         );
//     }, [itemIndex, parentList.state]);

//     const isInViewport = useCallback(() => {
//         if (!imageRef) return;

//         if (horizontalAutoScroll) {
//             //Horizontal scroll
//             const list = imageRef.parentNode.parentNode;

//             const itemLeft = imageRef.getBoundingClientRect().left - imageRef.offsetWidth * 0.05;
//             const itemRight = imageRef.getBoundingClientRect().right + imageRef.offsetWidth * 0.05;
//             const { left, right } = list.getBoundingClientRect();

//             if (itemRight > right) {
//                 list.scrollLeft += itemRight - right + hGap;
//             } else if (itemLeft < left) {
//                 list.scrollLeft += itemLeft - left /*+ hGap*/;
//             }
//         }

//         if (verticalAutoScroll) {
//             //Vertical scroll
//             const { viewport } = this.props;

//             const itemTop = imageRef.getBoundingClientRect().top - imageRef.offsetHeight * 0.05;
//             const itemBottom =
//                 imageRef.getBoundingClientRect().bottom + imageRef.offsetHeight * 0.05;
//             const { top, bottom } = viewport.getBoundingClientRect();

//             if (itemBottom > bottom) {
//                 viewport.scrollTop += itemBottom - bottom + vGap;
//             } else if (itemTop < top) {
//                 viewport.scrollTop += itemTop - top /*- vGap*/;
//             }
//         }
//     }, [hGap, horizontalAutoScroll, imageRef, vGap, verticalAutoScroll]);

//     const itemAction = () => {
//         if (!isActive) return;
//         const { item, withProgression } = this.props;
//         dispatch(setMovie(item));
//         redirectTo((withProgression ? '/play/' : '/detail/') + item.transaction);
//     };

//     const onMouseEnterHandler = () => {
//         if (navigation) navigation.forceFocus(focusableRef.focusableId);
//         parentCategory.setMouseFocus(true);
//     };

//     const onFocusHandler = (i, event) => {
//         if (!isLoaded) return false;

//         parentCategory.setActualIndex(i);
//         parentCategory.setMouseFocus(false);
//         setIsActive(true);
//         setThisItemActive();
//         isInViewport();
//     };

//     const onBlurHandler = () => setIsLoaded(false);

//     const onClickHandler = () => itemAction();

//     const onEnterDownHandler = () => itemAction();

//     const onImageLoadedHandler = () => {
//         if (item.aff_titre !== '1') setTitleVisible(false);
//         // setImageStatus(true);
//     };

//     const onImageErrorHandler = () => {
//         //setImageStatus(false);
//     };

//     // useEffect(() => {}, [])

//     return (
//         <Focusable
//             className={getClasses(isActive, style.type, style.size)}
//             ref={el => (focusableRef = el)}
//             onMouseEnter={onMouseEnterHandler}
//             onFocus={onFocusHandler}
//             onBlur={onBlurHandler}
//             onClick={onClickHandler}
//             onEnterDown={onEnterDownHandler}
//         >
//             <>
//                 <img
//                     ref={el => (imageRef = el)}
//                     src={imageSrc}
//                     alt={item.titre}
//                     onLoad={onImageLoadedHandler}
//                     onError={onImageErrorHandler}
//                 />
//                 {titleVisible && <Text shadow={true}>{item.titre}</Text>}
//                 {withProgression && (
//                     <div className={`${baseClass}__progress-bar`}>
//                         <div
//                             className={`${baseClass}__progress-bar--progression`}
//                             style={{ width: `${currentProgression}%` }}
//                         />
//                     </div>
//                 )}
//             </>
//         </Focusable>
//     );
// };

// export default forwardRef(ListItemV2);
