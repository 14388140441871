import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import Row from './Row';
import { goBack, goTo } from 'utils/NavigationUtils';

const RowGroup = (
    { rows, catalog, navigation, viewport, setListActive, groupIndex },
    ref
) => {
    const history = useHistory();

    const redirectTo = url => goTo(history, url);

    return (
        <>
            {
                rows
                    .filter(row => ! row.hidden && row.movies.length > 0)
                    .map((row, i) => (
                        <Row
                            key={i}
                            categoryIndex={`${groupIndex}-${i}`}
                            displayProps={row.display}
                            items={row.movies}
                            parent={catalog}
                            setListActive={setListActive}
                            redirectTo={redirectTo}
                            goBack={() => goBack(history)}
                            navigation={navigation}
                            viewport={viewport}
                            ref={ref}
                        />
                    ))
            }
        </>
    );
};

// rowGroups.propTypes = {
//     catalog: PropTypes.object,
//     navigation: PropTypes.object,
//     rows: PropTypes.array.isRequired,
//     setListActive: PropTypes.func,
//     viewport: PropTypes.object
// };

// rowGroups.defaultProps = {};

export default forwardRef(RowGroup);
