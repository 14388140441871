import React, { Component } from 'react';

import { AuthContext } from 'contexts';

import Button, { SIZE_BIG as BUTTON_BIG, STYLE_OUTLINE as BUTTON_OUTLINE } from 'components/Button';
import Image from 'components/Image';
import Text, { ALIGN_CENTER, SIZE_LARGE } from 'components/Text';
import Navigation, { HorizontalList, VerticalList } from 'components/Navigation';

import { isReturnEvent, goTo, LOGOUT_ACTION_DISCONNECT, LOGOUT_ACTION_EXIT, PATH_EXIT, PATH_HOME } from 'utils/NavigationUtils';

class LogoutConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmBtnAction: null,
            isNavigationLoaded: false,
            questionText: '',
        };

        this.navigation = React.createRef();

        this.keyDownListener = this.keyDownListener.bind(this);
    };

    componentDidMount() {
        const logoutAction = this.props.match.params.LogoutAction;

        if ( logoutAction === LOGOUT_ACTION_DISCONNECT ) {
            this.setState({
                confirmBtnAction: () => this.context.logout(),
                questionText: 'Voulez-vous vous déconnecter ?'
            });
        } else if ( logoutAction === LOGOUT_ACTION_EXIT ) {
            this.setState({
                confirmBtnAction: () => goTo( this.props.history, PATH_EXIT ),
                questionText: 'Voulez-vous quitter ?'
            });
        } else {
            goTo( this.props.history, PATH_HOME, true );
        }

        window.addEventListener('keydown', this.keyDownListener);
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    };

    keyDownListener = evnt => {
        if ( isReturnEvent( evnt ) ) goTo( this.props.history, PATH_HOME, true );
    };

    render() {
        const { history } = this.props;
        const { confirmBtnAction, isNavigationLoaded, questionText } = this.state;

        return (
            <Navigation ref={ el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <VerticalList className="login">
                    <Image
                        url={require('assets/img/grille-hd.jpg')}
                        size={Image.SIZE_FULLSCREEN}
                    />
                    {
                        isNavigationLoaded &&
                        <div className="popup-container">
                            <div className="popup-box">
                                <Text align={ALIGN_CENTER} size={SIZE_LARGE}>{questionText}</Text>
                                <HorizontalList>
                                    <Button
                                        className={'popup-btn'}
                                        focus={true}
                                        navigation={this.navigation}
                                        size={BUTTON_BIG}
                                        style={BUTTON_OUTLINE}
                                        url={() => { if( confirmBtnAction ) confirmBtnAction(); }}
                                        history={history}
                                    >
                                        <Text>Oui</Text>
                                    </Button>
                                    <Button
                                        className={'popup-btn'}
                                        navigation={this.navigation}
                                        size={BUTTON_BIG}
                                        style={BUTTON_OUTLINE}
                                        url={() => goTo( this.props.history, PATH_HOME, true )}
                                        history={history}
                                    >
                                        <Text>Non</Text>
                                    </Button>
                                </HorizontalList>
                            </div>
                        </div>
                    }
                </VerticalList>
            </Navigation>
        );
    }
}

LogoutConfirm.contextType = AuthContext;

export default LogoutConfirm;