import React, { Component } from 'react';

import { AuthContext } from 'contexts';

import AvatarItem from 'components/AvatarItem';
import Button, { SIZE_ROUND, SIZE_GIANT, BLOCK_HALF, BLOCK_FULL } from 'components/Button';
import Image from 'components/Image';
import Input from 'components/Input';
import Navigation, { HorizontalList, VerticalList } from 'components/Navigation';
import Text, { ALIGN_CENTER, SIZE_LARGE } from 'components/Text';
import Title, { LEVEL_1 } from 'components/Title';
import Wrapper from 'components/Wrapper';

import { goTo, isReturnEvent, PATH_PROFILE, PATH_PROFILE_AVATAR } from 'utils/NavigationUtils';

class ProfileEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avatar: 0,
            avatarId: '',
            errorMessage: '',
            firstName: '',
            isNavigationLoaded: false,
        };

        this.navigation = React.createRef();

        this.handleFirstNameInputChange = this.handleFirstNameInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);
    };

    componentDidMount() {
        window.addEventListener('keydown', this.keyDownListener);
        
        const { profileEdit } = this.context;
        if ( profileEdit ) this.setState({ avatar: profileEdit.avatar, avatarId: profileEdit.avatarId, firstName: profileEdit.firstName });
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    };

    handleFirstNameInputChange = event => {
        const { changeProfileEdit, profileEdit } = this.context;
        const { errorMessage } = this.state;
        const { value } = event.target;
        
        if ( errorMessage ) this.setState({ errorMessage: '' });
        changeProfileEdit({ avatarId: profileEdit.avatarId, avatar: profileEdit.avatar, firstName: value, type: profileEdit.type, version: profileEdit.version });
        this.setState({ firstName: value });
    };

    handleRemove = async () => {
        const { removeProfile, profileEdit, user } = this.context;
        const { history } = this.props;

        if ( profileEdit.version === 0 ) {
            this.setState({ errorMessage: 'Veuillez entrer votre prénom' });
            return false;
        }

        const data = {
            NoCli: user.NoCli,
            version: profileEdit.version,
        };

        const res = await removeProfile( data );
        if ( res ) {
            goTo(history, PATH_PROFILE);
        } else {
            this.setState({ errorMessage: 'Une erreur est survenue' });
        }
    };

    handleSave = async () => {
        const { addProfile, profileEdit, updateProfile, user } = this.context;
        const { history } = this.props;
        const { avatarId, firstName } = this.state;

        if ( firstName === '' ) {
            this.setState({ errorMessage: 'Veuillez entrer votre prénom' });
            return false;
        }

        let data = {
            avatarId: avatarId,
            firstName: firstName,
            NoCli: user.NoCli,
        };

        if ( profileEdit.type === 'add' ) {
            const res = await addProfile( data );
            if ( res ) {
                goTo(history, PATH_PROFILE);
            } else {
                this.setState({ errorMessage: 'Une erreur est survenue' });
            }
        } else if ( profileEdit.type === 'edit' ) {
            data.version = profileEdit.version;
            const res = await updateProfile( data );
            if ( res ) {
                goTo(history, PATH_PROFILE);
            } else {
                this.setState({ errorMessage: 'Une erreur est survenue' });
            }
        } else {
            this.setState({ errorMessage: 'Une erreur est survenue' });
        }
    };

    keyDownListener = evnt => {
        if ( isReturnEvent( evnt ) ) goTo(this.props.history, PATH_PROFILE);
    };

    render() {
        const { profileEdit } = this.context;
        const { history } = this.props;
        const { avatar, errorMessage, firstName, isNavigationLoaded } = this.state;
        
        return (
            <Navigation ref={ el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <VerticalList className="login">
                    <Image
                        url={require('assets/img/grille-hd.jpg')}
                        size={Image.SIZE_FULLSCREEN}
                    />
                    {
                        isNavigationLoaded &&
                        <>
                            <Wrapper overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                                <Title level={LEVEL_1}>
                                    { `${ profileEdit.type === 'add' ? 'Ajouter' : 'Modifer' } un profil` }
                                </Title>
                                <div className={'list list--center'}>
                                    <VerticalList className={'avatar-item--box'}>
                                        <AvatarItem
                                            action={ () => goTo(history, PATH_PROFILE_AVATAR) }
                                            alt={'avatar'}
                                            avatar={avatar}
                                            editable={true}
                                            focus={true}
                                            navigation={this.navigation}
                                        />
                                        <Input
                                            type="text"
                                            label="Prénom"
                                            name="firstName"
                                            size={Input.SIZE_BIG}
                                            navigation={this.navigation}
                                            onChange={this.handleFirstNameInputChange}
                                            value={firstName}
                                        />
                                        <Text align={ALIGN_CENTER} className={'avatar-item--error'} size={SIZE_LARGE}>{ errorMessage }</Text>
                                        <Button
                                            className={'avatar-item--button-save'}
                                            navigation={this.navigation}
                                            size={SIZE_ROUND}
                                            url={this.handleSave}
                                        >
                                            <Text size={SIZE_LARGE}>Enregistrer</Text>
                                        </Button>
                                    </VerticalList>
                                </div>
                            </Wrapper>
                            {
                                profileEdit.version === 0 || profileEdit.type === 'add' ?
                                <Wrapper sticky={Wrapper.STICKY_BOTTOM} padding={Wrapper.PADDING_NONE}>
                                    <HorizontalList>
                                        <Button
                                            navigation={this.navigation}
                                            block={BLOCK_FULL}
                                            size={SIZE_GIANT}
                                            url={PATH_PROFILE}
                                            history={history}
                                        >
                                            <Text size={SIZE_LARGE}>Retour</Text>
                                        </Button>
                                    </HorizontalList>
                                </Wrapper>
                                :
                                <Wrapper sticky={Wrapper.STICKY_BOTTOM} padding={Wrapper.PADDING_NONE}>
                                    <HorizontalList>
                                        <Button
                                            navigation={this.navigation}
                                            block={BLOCK_HALF}
                                            size={SIZE_GIANT}
                                            url={PATH_PROFILE}
                                            history={history}
                                        >
                                            <Text size={SIZE_LARGE}>Retour</Text>
                                        </Button>
                                        <Button
                                            navigation={this.navigation}
                                            block={BLOCK_HALF}
                                            size={SIZE_GIANT}
                                            url={this.handleRemove}
                                            history={history}
                                            className={'button--error'}
                                        >
                                            <Text size={SIZE_LARGE}>Supprimer le profil</Text>
                                        </Button>
                                    </HorizontalList>
                                </Wrapper>
                            }
                        </>
                    }
                </VerticalList>
            </Navigation>
        );
    };
}

ProfileEdit.contextType = AuthContext;

export default ProfileEdit;