import { LAST_FOCUS_ALL, LAST_FOCUS_CATALOG, LAST_FOCUS_CATEGORY, LAST_FOCUS_CHANNELS_PROGRAM, LAST_FOCUS_SEARCH, LAST_FOCUS_SEARCH_GRID_INDEX } from "utils/NavigationUtils";

const initialState = {
    avatarList: [],
    avatarListLoaded: false,
    catsList: [],
    catsListLoaded: false,
    channelMovies: [],
    channels: [],
    channelsMoviesBySheetId: {},
    channelsProgramDay: '',
    channelsProgramList: [],
    isChannelsControlsDisabled: false,
    isChannelsMovies: false,
    themes: ['monz3', 'chasse', 'peche', 'channelsProgram', 'search'],
    // TODO: search should not be a theme. Will be delete with layout (used for selected item in sidebar menu)
    themeActive: 0,
    dataLoaded: false,
    movie: null,
    nextPlaylist: [],
    carousel: [],
    rowGroups: {},
    rowsOrder: [
        'spotlights',
        'channels',
        'news',
        'pinnedFirst',
        'inProgress',
        'mostViewed',
        'favorites',
        'pinnedSecond',
        'moments',
        'forUser',
        'pages',
        // 'completed',
    ],
    lastFocusCatalog: [],
    lastFocusCategory: [],
    lastFocusChannelsProgram: null,
    lastFocusSearch: [],
    lastFocusSearchGridIndex: null,
    searchedMovies: [],
    searchedMoviesDisplay: {},
    searchText: null,
    searchResult: [],
};

const CatalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_MOVIE_TO_FAVORITES': {
            const movie = action.payload;
            const rowGroups = { ...state.rowGroups };
            rowGroups.favorites[0].movies.push(movie);
            return {
                ...state,
                rowGroups,
            };
        }

        case 'REMOVE_MOVIE_FROM_FAVORITES': {
            const movie = action.payload;
            const rowGroups = { ...state.rowGroups };
            rowGroups.favorites[0].movies = rowGroups.favorites[0].movies.filter(m => {
                return m.transaction !== movie.transaction;
            });
            return {
                ...state,
                rowGroups,
            };
        }

        case 'FORCE_DATA_RELOAD': {
            return {
                ...state,
                dataLoaded: false,
            };
        }

        case 'GET_AVATAR_LIST': {
            const { avatarList } = action.payload;

            return {
                ...state,
                avatarList,
                avatarListLoaded: true,
            };
        }

        case 'GET_CATS_LIST': {
            const { catsList } = action.payload;

            return {
                ...state,
                catsList,
                catsListLoaded: true,
            };
        }

        case 'GET_CHANNEL_MOVIES': {
            const { channelMovies, channels, isChannelsControlsDisabled } = action.payload;

            return {
                ...state,
                channelMovies,
                channels,
                isChannelsControlsDisabled,
            };
        }

        case 'GET_CHANNELS_PROGRAM': {
            const { channelsMoviesBySheetId, channelsProgramList, isChannelsControlsDisabled } = action.payload;

            return {
                ...state,
                channelsMoviesBySheetId,
                channelsProgramList,
                isChannelsControlsDisabled,
            };
        }

        case 'SET_CHANNELS_PROGRAM_DAY': {
            const { channelsProgramDay } = action.payload;

            return {
                ...state,
                channelsProgramDay,
            };
        }

        case 'SET_IS_CHANNELS_MOVIES': {
            const { isChannelsMovies } = action.payload;

            return {
                ...state,
                isChannelsMovies,
            }
        }

        case 'GET_FULL_CATALOG_NEW': {
            const { carousel, rows: rowGroups } = action.payload;
            return {
                ...state,
                carousel,
                rowGroups,
                dataLoaded: true,
            };
        }

        case 'GET_MOVIE':
        case 'SET_MOVIE': {
            let { movie, nextPlaylist } = action.payload;
            nextPlaylist = ( ! nextPlaylist ) ? [] : nextPlaylist;

            // (TEMPORARY) Construct seasons
            if (movie.type === 'series') {
                const episodes = [...movie.episodes];
                movie.seasons = [
                    {
                        number: 1,
                        episodes: episodes
                    }
                    // {
                    //     number: 2,
                    //     episodes: movie.episodes.map((episode, i) => ({
                    //         ...episode,
                    //         titre: `Episode ${i + 1} saison  2`,
                    //         transaction: episode.transaction + 'a'
                    //     }))
                    // }
                ];

                movie.episodeToStartIndexes = {
                    seasonIndex: 0,
                    episodeIndex: 0
                };

                for (let si = 0; si < movie.seasons?.length; si++) {
                    const season = movie.seasons[si];
                    const isLastSeason = si >= movie.seasons.length - 1;

                    for (let ei = 0; ei < season.episodes.length; ei++) {
                        const { time, timeTotal } = season.episodes[ei];
                        const isLastEpisode = ei >= season.episodes.length - 1;

                        if (time && timeTotal) {
                            if (timeTotal - time > 60) {
                                movie.episodeToStartIndexes = {
                                    seasonIndex: si,
                                    episodeIndex: ei
                                };
                            } else {
                                // Considered as video ended
                                if (isLastSeason && isLastEpisode) {
                                    movie.episodeToStartIndexes = {
                                        seasonIndex: 0,
                                        episodeIndex: 0
                                    };
                                } else {
                                    movie.episodeToStartIndexes = {
                                        seasonIndex: isLastEpisode ? si + 1 : si,
                                        episodeIndex: ei + 1
                                    };
                                }
                            }
                        }
                    }
                }
            }
            
            return {
                ...state,
                movie,
                nextPlaylist,
            };
        }

        case 'CLEAR_SEARCH_MOVIE': {
            return {
                ...state,
                searchText: null,
                searchResult: [],
            };
        }

        case 'SEARCH_MOVIE': {
            const { searchText, searchResult } = action.payload;

            return {
                ...state,
                searchText,
                searchResult,
            };
        }

        case 'CLEAR_SEARCHED_MOVIES': {
            return {
                ...state,
                searchedMovies: [],
                searchedMoviesDisplay: {},
            };
        }

        case 'SEARCHED_MOVIES': {
            const { searchedMovies, searchedMoviesDisplay } = action.payload;

            return {
                ...state,
                searchedMovies,
                searchedMoviesDisplay,
            };
        }

        case 'CLEAR_SPECIFIC_LAST_FOCUS': {
            const { type } = action.payload;

            if ( type === LAST_FOCUS_ALL ) return { ...state, lastFocusCatalog: [], lastFocusCategory: [], lastFocusChannelsProgram: null, lastFocusSearch: [], lastFocusSearchGridIndex: null };
            if ( type === LAST_FOCUS_CATALOG ) return { ...state, lastFocusCatalog: [] };
            if ( type === LAST_FOCUS_CATEGORY ) return { ...state, lastFocusCategory: [] };
            if ( type === LAST_FOCUS_CHANNELS_PROGRAM ) return { ...state, lastFocusChannelsProgram: null };
            if ( type === LAST_FOCUS_SEARCH ) return { ...state, lastFocusSearch: [], lastFocusSearchGridIndex: null };

            return { ...state };
        }

        case 'SET_SPECIFIC_LAST_FOCUS': {
            const { position, type } = action.payload;

            if ( type === LAST_FOCUS_CATALOG ) return { ...state, lastFocusCatalog: position };
            if ( type === LAST_FOCUS_CATEGORY ) return { ...state, lastFocusCategory: position };
            if ( type === LAST_FOCUS_CHANNELS_PROGRAM ) return { ...state, lastFocusChannelsProgram: position };
            if ( type === LAST_FOCUS_SEARCH ) return { ...state, lastFocusSearch: position };
            if ( type === LAST_FOCUS_SEARCH_GRID_INDEX ) return { ...state, lastFocusSearchGridIndex: position };

            return { ...state };
        }

        case 'SET_THEME': {
            let themeChanged = false;
            const themeActive = state.themes.indexOf(action.payload);
            if ( state.themeActive !== themeActive ) themeChanged = true;
            
            return {
                ...state,
                themeActive,
                dataLoaded: ! themeChanged,
            };
        }

        case 'UPDATE_STORE_PROGRESS_MOVIE': {
            let { movie, currentTime, totalTime } = action.payload;

            if (!state.rowGroups?.inProgress) return { ...state };

            const rowGroups = { ...state.rowGroups };
            currentTime = Math.floor(currentTime).toString();
            totalTime = Math.floor(totalTime).toString();

            const progressRow = rowGroups.inProgress[0];
            let movieFound = false;

            for (let i = 0; i < progressRow.movies.length; i++) {
                if (progressRow.movies[i].transaction === movie.transaction) {
                    movieFound = true;
                    progressRow.movies[i].progressTime = currentTime;
                    progressRow.movies[i].timeTotal = totalTime;
                    break;
                }
            }

            if (!movieFound) {
                progressRow.movies.unshift({
                    ...movie,
                    progressTime: currentTime,
                    timeTotal: totalTime,
                });
            }
            rowGroups.inProgress[0] = progressRow;

            return {
                ...state,
                rowGroups,
            };
        }

        default:
            return { ...state };
    }
};

export default CatalogReducer;