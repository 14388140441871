import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuthContext } from 'contexts';

import { VerticalList } from 'components/Navigation';
import MenuItem from './MenuItem';
import SvgIcon from './SvgIcon';

import { clearSearch, clearSpecificLastFocus, setThemeActive } from 'redux/actions/CatalogActions';
import { goTo, LAST_FOCUS_SEARCH, LOGOUT_ACTION_DISCONNECT, LOGOUT_ACTION_EXIT, PATH_HOME, PATH_LOGOUT, PATH_LOGOUT_CONFIRM, PATH_SEARCH, PATH_PROFILE } from 'utils/NavigationUtils';
// import { goTo, LAST_FOCUS_SEARCH, PATH_EXIT, PATH_CHANNELS_PROGRAM, PATH_HOME, PATH_LOGOUT, PATH_SEARCH, PATH_PROFILE } from 'utils/NavigationUtils';

import { ReactComponent as Home } from 'assets/icons/icon-home.svg';
import { ReactComponent as Hunting } from 'assets/icons/icon-hunt-deer.svg';
import { ReactComponent as Fishing } from 'assets/icons/icon-fishing-fish.svg';
import { ReactComponent as Search } from 'assets/icons/icon-search.svg';
import { ReactComponent as Logout } from 'assets/icons/icon-log-out.svg';
import { ReactComponent as Quit } from 'assets/icons/icon-close.svg';
import { ReactComponent as Profile } from 'assets/icons/icon-profile.svg';
// import { ReactComponent as Play } from 'assets/icons/icon-play.svg';

class Menu extends Component {
    /**
     * Array containing data for the sidebar items
     * @type {[{ref: string, icon: string, keyId: string, text: string, url: string}]}
     */
    static ITEMS = [
        {
            text: 'Mon Zone300',
            ref: 'monz3',
            keyId: 'monz3',
            icon: Home,
            url: PATH_HOME,
            replaceUrl: true
        },
        {
            text: 'Chasse',
            ref: 'chasse',
            keyId: 'chasse',
            icon: Hunting,
            url: PATH_HOME,
            replaceUrl: true,
            indentLvl: 1
        },
        {
            text: 'Pêche',
            ref: 'peche',
            keyId: 'peche',
            icon: Fishing,
            url: PATH_HOME,
            replaceUrl: true,
            indentLvl: 1
        },
        // TO CHANGE
        // {
        //     text: 'En direct',
        //     ref: 'channelsProgram',
        //     keyId: 'channelsProgram',
        //     icon: Play,
        //     url: PATH_CHANNELS_PROGRAM,
        //     replaceUrl: false,
        // },
        {
            text: 'Recherche',
            ref: 'search',
            keyId: 'search',
            icon: Search,
            url: PATH_SEARCH,
            replaceUrl: false,
            separator: true
        },
        {
            text: 'Mon compte',
            ref: 'profile',
            keyId: 'profile',
            icon: Profile,
            url: PATH_PROFILE,
            replaceUrl: false,
        },
        {
            text: 'Déconnexion',
            ref: 'disconnect',
            keyId: 'disconnect',
            icon: Logout,
            url: PATH_LOGOUT,
            replaceUrl: true
        },
        {
            text: 'Quitter',
            ref: 'quit',
            keyId: 'quit',
            icon: Quit,
            // url: PATH_EXIT,
            url: `${PATH_LOGOUT_CONFIRM}/${LOGOUT_ACTION_EXIT}`,
            replaceUrl: true,
            minVersion: '210511' //min version for display in Android and Fire TVs
        }
    ];

    /**
     * Constructor method of the Menu component
     * @param props
     */
    constructor(props) {
        super(props);

        this.onGo = this.onGo.bind(this);
    }

    /**
     * Use only state to define Categories state theme
     * @param item
     */
    onGo(item) {
        if (item.url === PATH_LOGOUT) {
            goTo(this.props.history, `${PATH_LOGOUT_CONFIRM}/${LOGOUT_ACTION_DISCONNECT}`);
            // this.context.logout();
        // } else if (window.location.pathname === item.url && item.url === PATH_HOME) {
        //     this.props.setThemeActive(item.keyId);
        //     goTo(this.props.history, item.url, item.replaceUrl);
        } else {
            if ( window.location.pathname !== PATH_SEARCH && item.url === PATH_SEARCH ) {
                this.props.clearSearch();
                this.props.clearSpecificLastFocus( LAST_FOCUS_SEARCH );
            }
            this.props.setThemeActive(item.keyId);
            goTo(this.props.history, item.url, item.replaceUrl);
        }
        this.props.closeSidebar();
    }

    render() {
        const { themes, themeActive } = this.props;
        const { user } = this.context;

        return (
            <ul className="menu">
                <div className="menu__close">
                    {
                        Menu.ITEMS
                            .filter(
                                item =>
                                    !item.minVersion ||
                                    !window.getAndroidCapsuleVersion() ||
                                    window.getAndroidCapsuleVersion() >= item.minVersion
                            )
                            .filter(
                                item => {
                                    if ( user && user.profileMax && parseInt( user.profileMax ) > 1 ) return item.url !== PATH_LOGOUT;
                                    return item.url !== PATH_PROFILE;
                                }
                            )
                            .map((item, i) => (
                                <SvgIcon
                                    key={i}
                                    icon={item.icon}
                                    separator={item.separator}
                                    selected={themes[themeActive] === item.keyId}
                                />
                            ))
                    }
                </div>
                <VerticalList
                    className="menu__open"
                    ref={el => (this.menuVList = el)}
                    onFocus={() => this.props.openSidebar()}
                    onBlur={() => this.props.closeSidebar()}
                >
                    {
                        Menu.ITEMS
                            .filter(
                                item =>
                                    !item.minVersion ||
                                    !window.getAndroidCapsuleVersion() ||
                                    window.getAndroidCapsuleVersion() >= item.minVersion
                            )
                            .filter(
                                item => {
                                    if ( user && user.profileMax && parseInt( user.profileMax ) > 1 ) return item.url !== PATH_LOGOUT;
                                    return item.url !== PATH_PROFILE;
                                }
                            )
                            .map((item, i) => (
                                <MenuItem
                                    key={i}
                                    ref={el => (this[item.ref] = el)}
                                    goTo={() => this.onGo(item)}
                                    keyId={item.keyId}
                                    icon={item.icon}
                                    navigation={this.props.navigation}
                                    parentSidebar={this}
                                    selected={themes[themeActive] === item.keyId}
                                    visible={this.props.visible}
                                    separator={item.separator}
                                >
                                    {item.text}
                                </MenuItem>
                            ))
                    }
                </VerticalList>
            </ul>
        );
    }
}

Menu.contextType = AuthContext;

const stateToProps = state => ({
    themeActive: state.catalog.themeActive,
    themes: state.catalog.themes
});

const dispatchToProps = dispatch => ({
    clearSearch: () => dispatch(clearSearch()),
    clearSpecificLastFocus: type => dispatch(clearSpecificLastFocus(type)),
    setThemeActive: theme => dispatch(setThemeActive(theme)),
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(Menu);
