import React, { Component } from 'react';

import { AuthContext } from 'contexts';

import AvatarItem from 'components/AvatarItem';
import Button, { SIZE_ROUND, SIZE_GIANT, BLOCK_HALF } from 'components/Button';
import Image from 'components/Image';
import Navigation, { HorizontalList, VerticalList } from 'components/Navigation';
import Text, { ALIGN_CENTER, SIZE_LARGE } from 'components/Text';
import Title, { LEVEL_1 } from 'components/Title';
import Wrapper from 'components/Wrapper';

import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { goTo, isReturnEvent, LOGOUT_ACTION_DISCONNECT, PATH_HOME,  PATH_LOGOUT_CONFIRM, PATH_PROFILE_EDIT } from 'utils/NavigationUtils';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNavigationLoaded: false,
        };

        this.navigation = React.createRef();

        this.keyDownListener = this.keyDownListener.bind(this);
    };

    componentDidMount() {
        window.addEventListener('keydown', this.keyDownListener);
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    };

    keyDownListener = evnt => {
        if ( isReturnEvent( evnt ) ) goTo(this.props.history, PATH_HOME, true);
    };

    render() {
        const { changeProfileEdit, changeProfileVersion, profileVersion, user } = this.context;
        const { history } = this.props;
        const { isNavigationLoaded } = this.state;
        
        return (
            <Navigation ref={ el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <VerticalList className="login">
                    <Image
                        url={require('assets/img/grille-hd.jpg')}
                        size={Image.SIZE_FULLSCREEN}
                    />
                    {
                        isNavigationLoaded &&
                        <>
                            <Wrapper overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                                <Title level={LEVEL_1}>
                                    Changer de profil
                                </Title>
                                {
                                    user && user.NoCli &&
                                    <HorizontalList className={'list list--center'}>
                                        <VerticalList className={'avatar-item--box'}>
                                            <AvatarItem
                                                action={ () => changeProfileVersion( 0 ) }
                                                alt={'avatar'}
                                                avatar={user.avatar}
                                                focus={profileVersion === 0}
                                                navigation={this.navigation}
                                                selected={profileVersion === 0}
                                            />
                                            <Text align={ALIGN_CENTER} className={'avatar-item--name'} size={SIZE_LARGE}>{ user.Prenom }</Text>
                                            <Button
                                                className={'avatar-item--button'}
                                                navigation={this.navigation}
                                                size={SIZE_ROUND}
                                                url={ () => {
                                                    changeProfileEdit({ avatarId: user.avatarId, avatar: user.avatar, firstName: user.Prenom, type: 'edit', version: 0 });
                                                    goTo(history, PATH_PROFILE_EDIT);
                                                }}
                                            >
                                                <Text size={SIZE_LARGE}>Modifier</Text>
                                            </Button>
                                        </VerticalList>
                                        {
                                            user.profiles && user.profiles.length > 0 &&
                                            user.profiles.map( ( item, index ) => (
                                                <VerticalList key={index} className={'avatar-item--box'}>
                                                    <AvatarItem
                                                        action={ () => changeProfileVersion( item.version ) }
                                                        alt={'avatar'}
                                                        avatar={item.avatar}
                                                        focus={profileVersion === item.version}
                                                        navigation={this.navigation}
                                                        selected={profileVersion === item.version}
                                                    />
                                                    <Text align={ALIGN_CENTER} className={'avatar-item--name'} size={SIZE_LARGE}>{ item.firstName }</Text>
                                                    <Button
                                                        className={'avatar-item--button'}
                                                        navigation={this.navigation}
                                                        size={SIZE_ROUND}
                                                        url={ () => {
                                                            changeProfileEdit({ avatarId: item.avatarId, avatar: item.avatar, firstName: item.firstName, type: 'edit', version: item.version });
                                                            goTo(history, PATH_PROFILE_EDIT);
                                                        }}
                                                    >
                                                        <Text size={SIZE_LARGE}>Modifier</Text>
                                                    </Button>
                                                </VerticalList>
                                            ))
                                        }
                                        {
                                            user.profiles && user.profileMax && user.profileMax > user.profiles.length + 1 &&
                                            <VerticalList className={'avatar-item--box'}>
                                                <AvatarItem
                                                    action={ () => {
                                                        changeProfileEdit({ avatarId: 0, avatar: '', firstName: '', type: 'add' });
                                                        goTo(history, PATH_PROFILE_EDIT);
                                                    }}
                                                    icon={IconAdd}
                                                    navigation={this.navigation}
                                                />
                                                <Text align={ALIGN_CENTER} className={'avatar-item--name'} size={SIZE_LARGE}>Ajouter</Text>
                                                <div className='avatar-item--space-no-button' />
                                            </VerticalList>
                                        }
                                    </HorizontalList>
                                }
                            </Wrapper>
                            <Wrapper sticky={Wrapper.STICKY_BOTTOM} padding={Wrapper.PADDING_NONE}>
                                <HorizontalList>
                                    <Button
                                        navigation={this.navigation}
                                        block={BLOCK_HALF}
                                        size={SIZE_GIANT}
                                        url={() => goTo(history, PATH_HOME, true)}
                                        history={history}
                                        className={'button--success'}
                                    >
                                        <Text size={SIZE_LARGE}>Retour à la plateforme</Text>
                                    </Button>
                                    <Button
                                        navigation={this.navigation}
                                        block={BLOCK_HALF}
                                        size={SIZE_GIANT}
                                        url={() => goTo(history, `${PATH_LOGOUT_CONFIRM}/${LOGOUT_ACTION_DISCONNECT}`)}
                                        history={history}
                                    >
                                        <Text size={SIZE_LARGE}>Déconnexion</Text>
                                    </Button>
                                </HorizontalList>
                            </Wrapper>
                        </>
                    }
                </VerticalList>
            </Navigation>
        );
    };
}

Profile.contextType = AuthContext;

export default Profile;
