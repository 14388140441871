import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuthContext } from 'contexts';

import AvatarItem from 'components/AvatarItem';
import Image from 'components/Image';

import Navigation, { Grid, VerticalList } from 'components/Navigation';
import Title, { LEVEL_1 } from 'components/Title';
import Wrapper from 'components/Wrapper';

import { getAvatarList } from 'redux/actions/CatalogActions';
import { goTo, isReturnEvent, PATH_PROFILE_EDIT } from 'utils/NavigationUtils';

class ProfileAvatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avatar: '',
            avatarId: 0,
        };

        this.navigation = React.createRef();
        this.scrollableDiv = React.createRef();

        this.handleAvatarSelect = this.handleAvatarSelect.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);
    };

    componentDidMount() {
        window.addEventListener('keydown', this.keyDownListener);

        const { profileEdit } = this.context;
        if ( profileEdit.avatar ) this.setState({ avatar: profileEdit.avatar, avatarId: profileEdit.avatarId });

        const { avatarListLoaded } = this.props;
        if ( ! avatarListLoaded ) this.props.getAvatarList();
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    };

    handleAvatarSelect = item => {
        const { changeProfileEdit, profileEdit } = this.context;
        const { history } = this.props;

        changeProfileEdit({ avatar: item.imagePath, avatarId: item.id, firstName: profileEdit.firstName, type: profileEdit.type, version: profileEdit.version });
        goTo(history, PATH_PROFILE_EDIT);
    };

    keyDownListener = evnt => {
        const { history } = this.props;

        if ( isReturnEvent( evnt ) ) goTo(history, PATH_PROFILE_EDIT);
    };

    render() {
        const { avatarList, avatarListLoaded } = this.props;
        const { avatarId, isNavigationLoaded } = this.state;

        let avatars = [];
        if ( avatarListLoaded ) {
            Object.keys(avatarList).forEach( el => {
                avatarList[ el ].forEach( item => avatars.push( item ) );
            });
        }
        
        return (
            <Navigation ref={ el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <VerticalList className="login">
                    <Image
                        url={require('assets/img/grille-hd.jpg')}
                        size={Image.SIZE_FULLSCREEN}
                    />
                {
                    isNavigationLoaded &&
                    <>
                    <Wrapper overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                    <Title level={LEVEL_1} className='avatar-list-title'>
                        Choisir un avatar
                    </Title>
                    <div ref={el => (this.searchPage = el)}>
                        <VerticalList>
                            <div className="avatar-list-content" ref={this.scrollableDiv}>
                                <>
                                    {
                                        avatarListLoaded && avatars && avatars.length > 0 &&
                                        <Grid
                                            ref={el => (this.grid = el)}
                                            columns={6}
                                            rows={Math.ceil(avatars.length / 6)}
                                        >
                                            {
                                                avatars.map( ( item, index ) =>
                                                    <AvatarItem
                                                        key={index}
                                                        action={ () => this.handleAvatarSelect( item ) }
                                                        alt={'avatar'}
                                                        avatar={item.imagePath}
                                                        focus={index === 0}
                                                        navigation={this.navigation}
                                                        selected={item.id === avatarId}
                                                        verticalAutoScroll
                                                        vGap={300}
                                                        viewport={this.scrollableDiv.current}
                                                    />
                                                )
                                            }
                                        </Grid>
                                    }
                                </>
                            </div>
                        </VerticalList>
                    </div>
                    </Wrapper>
                    </>
                }
                </VerticalList>
            </Navigation>
        );
    };
}

ProfileAvatar.contextType = AuthContext;

const stateToProps = state => ({
    avatarList: state.catalog.avatarList,
    avatarListLoaded: state.catalog.avatarListLoaded,
});

const dispatchToProps = dispatch => ({
    getAvatarList: () => dispatch(getAvatarList()),
});

export default connect(stateToProps, dispatchToProps)(ProfileAvatar);