import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';

import { AuthContext } from 'contexts';

import AvatarItem from 'components/AvatarItem';
import Button, { SIZE_BIG as BUTTON_BIG, STYLE_OUTLINE as BUTTON_OUTLINE, SIZE_ROUND as BUTTON_ROUND } from 'components/Button';
import Image from 'components/Image';
import List from 'components/List';
import Navigation, { VerticalList, HorizontalList, Focusable } from 'components/Navigation';
import Sidebar from 'components/SideBar';
import SvgIcon from 'components/SvgIcon';
import Text, { ALIGN_CENTER, COLOR_DEFAULT, COLOR_GREY, COLOR_SECONDARY, SIZE_LARGE, SIZE_SMALL } from 'components/Text';
import Title, { LEVEL_1 } from 'components/Title';

import { API_IMAGE } from 'api/ImageManager';
import { ReactComponent as CSA10 } from 'assets/icons/icon-csa-10.svg';
import { ReactComponent as CSA12 } from 'assets/icons/icon-csa-12.svg';
import { ReactComponent as CSA16 } from 'assets/icons/icon-csa-16.svg';
import { ReactComponent as CSA18 } from 'assets/icons/icon-csa-18.svg';
import { ReactComponent as CSAAll } from 'assets/icons/icon-csa-all.svg';
import { ReactComponent as DefHD } from 'assets/icons/icon-hd.svg';
import { ReactComponent as DefUHD } from 'assets/icons/icon-4k.svg';
import { ReactComponent as Def43 } from 'assets/icons/icon-4-3.svg';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconPlay } from 'assets/icons/icon-play.svg';
import { ReactComponent as IconThumbDown } from 'assets/icons/icon-thumb-down.svg';
import { ReactComponent as IconThumbDownFilled } from 'assets/icons/icon-thumb-down-filled.svg';
import { ReactComponent as IconThumbUp } from 'assets/icons/icon-thumb-up.svg';
import { ReactComponent as IconThumbUpFilled } from 'assets/icons/icon-thumb-up-filled.svg';
import { addMovieToFavorites, getChannelMovies, getFullCatalogNew, setIsChannelsMovies, setMovie } from 'redux/actions/CatalogActions';
import { goBack, goTo, isReturnEvent, PATH_CHANNEL, PATH_CHANNELS_PROGRAM, PATH_HOME, PATH_PLAY } from 'utils/NavigationUtils';
import { THUMB_ADD_DOWN, THUMB_ADD_UP, THUMB_DOWN, THUMB_REMOVE_DOWN, THUMB_REMOVE_UP, THUMB_UP } from 'utils/ThumbsUtils';

class Channel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: null,
            actualIndex: null,
            channelLabel: '',
            isInFavorite: false,
            isLoaded: false,
            isNavigationLoaded: false,
            isThumbDown: false,
            isThumbUp: false,
            mouseFocus: false,
            programFocused: false,
        };

        this.navigation = React.createRef();
        this.scrollableDiv = React.createRef();
        this.sidebar = React.createRef();

        this.keyDownListener = this.keyDownListener.bind(this);
        this.refSidebar = this.refSidebar.bind(this);
        this.playBtnAction = this.playBtnAction.bind(this);
        this.setBtnFocused = this.setBtnFocused.bind(this);
        this.setChannelFocused = this.setChannelFocused.bind(this);
        this.setItemActive = this.setItemActive.bind(this);
        this.setListActive = this.setListActive.bind(this);
        this.setProgramFocused = this.setProgramFocused.bind(this);
        this.thumbBtnAction = this.thumbBtnAction.bind(this);

        this.focusSidebarTimeout = null;
    }

    componentDidMount() {
        const channelLabel = this.props.match.params.ChannelID;
        if ( ! channelLabel ) goTo( this.props.history, PATH_HOME );
        this.setState({ channelLabel: channelLabel });

        const { sidebarExit } = this.props;

        this.props.getChannelMovies(channelLabel).then( () => this.setState({ isLoaded: true }) );
        if ( sidebarExit && this.sidebar.menu.quit.item.focusableId ) this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
        window.addEventListener('keydown', this.keyDownListener);
    };

    componentDidUpdate( prevProps, prevState ) {
        const { channelLabel } = this.state;

        if ( channelLabel !== this.props.match.params.ChannelID ) {
            this.setState({ channelLabel: this.props.match.params.ChannelID, isLoaded: false });
            this.props.getChannelMovies(this.props.match.params.ChannelID).then( () => this.setState({ isLoaded: true }) );
        }

        const { profileVersion, thumbsDown, thumbsUp, user } = this.context;
        const { channelMovies, rowGroups, themeActive, themes } = this.props;

        if ( prevProps.channelMovies !== channelMovies && channelMovies.length > 0 ) {
            const movieId = channelMovies[0].transaction;

            if ( ! rowGroups.favorites ) {
                this.props.getFullCatalogNew(themes[themeActive], user.NoCli, profileVersion);
            } else {
                let isInFav = false;
                for ( const movie of rowGroups.favorites[0].movies ) {
                    if (movie.transaction === movieId) {
                        isInFav = true;
                        break;
                    }
                }
                this.setState({ isInFavorite: isInFav });
            }

            this.setState({
                isThumbDown: thumbsDown.includes( movieId ),
                isThumbUp: thumbsUp.includes( movieId ),
            });
        }

        if ( prevProps.rowGroups !== rowGroups && rowGroups.favorites && rowGroups.favorites.length > 0 && channelMovies.length > 0 ) {
            let isInFav = false;
            for ( const movie of rowGroups.favorites[0].movies ) {
                if (movie.transaction === channelMovies[0].transaction) {
                    isInFav = true;
                    break;
                }
            }
            this.setState({ isInFavorite: isInFav });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
        clearTimeout( this.focusDayTimeout );
        clearTimeout( this.focusSidebarTimeout );
    };

    shouldComponentUpdate( nextProps, nextState, nextContext ) {
        if ( this.props.match.params.action === 'quit' && ! nextProps.match.params.action ) return false;
        return true;
    };

    favoriteBtnAction(inFavorite) {
        const { profileVersion, user } = this.context;
        const { channelMovies } = this.props;

        if ( channelMovies && channelMovies.length > 0 ) this.props.addMovieToFavorites( inFavorite, channelMovies[0], user.NoCli, profileVersion );
    };

    getChannelClasses(label) {
        const { channelLabel } = this.state;
        return `catsList ${ channelLabel === label ? '' : 'channel-not-selected' }`;
    };

    getCsaIcon(csa) {
        switch (csa) {
            case 'csa10':
                return CSA10;
            case 'csa12':
                return CSA12;
            case 'csa16':
                return CSA16;
            case 'csa18':
                return CSA18;
            case 'csaAll':
                return CSAAll;
            default:
                return null;
        }
    };

    getDefinitionIcon(definition) {
        switch (definition) {
            case 'high-definition':
                return DefHD;
            case '4k':
                return DefUHD;
            case '4-3':
                return Def43;
            default:
                return null;
        }
    };

    getRoles(role) {
        switch ( role ) {
            case 'actor': return 'Acteur';
            case 'producer': return 'Producteur';
            case 'director': return 'Réalisateur';
            default : return 'Acteur';
        }
    };

    keyDownListener(evnt) {
        if ( isReturnEvent(evnt) ) goBack(this.props.history);
    };

    playBtnAction() {
        const { channelMovies, history } = this.props;
        if ( channelMovies && channelMovies.length > 0 ) {
            const movie = channelMovies[0];
            const nextPlaylist = channelMovies.slice(1);
    
            this.props.setIsChannelsMovies(true);
            this.props.setMovie(movie, nextPlaylist);
            goTo(history, `${PATH_PLAY}/${movie.transaction}`);
        }
    };

    refSidebar(el) {
        if ( el ) this.sidebar = el;
    };

    setActualIndex(index) {
        this.setState({ actualIndex: index });
    };

    setBtnFocused(active) {
        if ( active && this.scrollableDiv.current ) this.scrollableDiv.current.scrollTop = window.innerHeight;
    };

    setChannelFocused(active) {
        if ( active ) this.setState({ programFocused: false });
        if ( active && this.scrollableDiv.current ) this.scrollableDiv.current.scrollTop = 0;
    };

    setItemActive(index = null) {
        this.setState({ activeItem: index });
    };

    setListActive(index = null) {
        this.setState({ programFocused: false });
    };

    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    };

    setProgramFocused(active) {
        this.setState({ programFocused: false });
    };

    thumbBtnAction(type) {
        const { profileVersion, updateThumbs, user } = this.context;
        const { channelMovies } = this.props;
        const { isThumbDown, isThumbUp } = this.state;

        if ( channelMovies && channelMovies.length > 0 ) {
            const movieId = channelMovies[0].transaction;
            let action = '';

            if ( type === THUMB_DOWN ) {
                if ( isThumbDown ) {
                    action = THUMB_REMOVE_DOWN;
                    this.setState({ isThumbDown: false });
                } else {
                    action = THUMB_ADD_DOWN;
                    this.setState({ isThumbDown: true, isThumbUp: false });
                }
            } else if ( type === THUMB_UP ) {
                if ( isThumbUp ) {
                    action = THUMB_REMOVE_UP;
                    this.setState({ isThumbUp: false });
                } else {
                    action = THUMB_ADD_UP;
                    this.setState({ isThumbDown: false, isThumbUp: true });
                }
            }

            if ( action ) updateThumbs({ action, movieId, userId: user.NoCli, version: profileVersion });
        }
    };

    render() {
        const { channelMovies, channels, history } = this.props;
        const { channelLabel, isInFavorite, isLoaded, isNavigationLoaded, isThumbDown, isThumbUp, programFocused } = this.state;

        return (
            <Navigation specialChannelProgramPage={true} ref={el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <div className="category-page">
                    <HorizontalList>
                        <Sidebar
                            ref={el => { if ( el ) this.sidebar = el; }}
                            history={history}
                            navigation={this.navigation}
                            parent={this}
                        />
                        <div className="channel-page" ref={this.scrollableDiv}>
                            <VerticalList>
                                {
                                    isNavigationLoaded &&
                                    <>
                                        <HorizontalList className='channel-title-box'>
                                            {
                                                channels && channels.length &&
                                                <div className='channels-title-box'>
                                                    {
                                                        channels.map( ( el, i ) =>
                                                            <div key={i} className="channels-program-line-title-box channel-page-line-title-box">
                                                                <AvatarItem
                                                                    action={() => goTo(history, `${PATH_CHANNEL}/${el.label}`)}
                                                                    alt={el.title}
                                                                    avatar={el.imagePath}
                                                                    className={this.getChannelClasses(el.label)}
                                                                    focus={el.label === channelLabel}
                                                                    onFocus={() => this.setState({ programFocused: false })}
                                                                    navigation={this.navigation}
                                                                    setChannelFocused={this.setChannelFocused}
                                                                />
                                                                <Text align={ALIGN_CENTER} size={SIZE_LARGE}>{el.title}</Text>
                                                            </div>
                                                        )
                                                    }
                                                    <div className='f-grow' />
                                                    <Focusable
                                                        onClick={() => goTo(history, PATH_CHANNELS_PROGRAM)}
                                                        onEnterDown={() => goTo(history, PATH_CHANNELS_PROGRAM)}
                                                        onFocus={() => this.setState({ programFocused: true })}
                                                        onMouseEnter={() => this.setState({ programFocused: true })}
                                                    >
                                                        <Text
                                                            align={ALIGN_CENTER}
                                                            className='text--bold'
                                                            color={programFocused ? COLOR_SECONDARY : COLOR_DEFAULT}
                                                            size={SIZE_LARGE}
                                                        >
                                                            Programme TV
                                                        </Text>
                                                    </Focusable>
                                                </div>
                                            }
                                        </HorizontalList>
                                        {
                                            isLoaded && channelMovies && channelMovies.length > 0 &&
                                            <>
                                                <div className="channel-main-movie-box">
                                                    <div className="category channel-main-movie">
                                                        <div className="category__arrow__container">
                                                            <List
                                                                ref={el => (this.list = el)}
                                                                isChannelMovie={true}
                                                                items={[channelMovies[0]]}
                                                                rowRef={this.row}
                                                                categoryIndex={1}
                                                                setThisListActive={() => this.setListActive(1)}
                                                                setProgramFocused={this.setProgramFocused}
                                                                parentCategory={this}
                                                                redirectTo={url => goTo(history, url)}
                                                                goBack={goBack}
                                                                navigation={this.navigation}
                                                                style={{ size: 'xxl' }}
                                                                viewport={this.scrollableDiv.current}
                                                                verticalAutoScroll={true}
                                                                wrappedList={false}
                                                                withProgression={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="channel-main-movie-info">
                                                        {
                                                            channelMovies[0].titlePngFileName ?
                                                            <Image
                                                                className="details__infos__title-img"
                                                                url={`${API_IMAGE}${channelMovies[0].titlePngFileName}.png&h=100&crop-to-fit`}
                                                                onError={e => (e.target.style.display = 'none')}
                                                            />
                                                            :
                                                            <Title level={LEVEL_1}>{channelMovies[0].titre}</Title>
                                                        }
                                                        {
                                                            channelMovies[0].categories?.length > 0 &&
                                                            <div className="details__infos__categories">
                                                                {
                                                                    channelMovies[0].categories.map((category, i) => 
                                                                        <Text size={SIZE_SMALL} key={i}>
                                                                            {category.name}
                                                                        </Text>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            channelMovies[0].synopsis &&
                                                            <Text className="details__infos__synopsis">{channelMovies[0].synopsis}</Text>
                                                        }
                                                        <div className="details__infos__meta">
                                                            {
                                                                channelMovies[0].shootingDate &&
                                                                <Text size={SIZE_SMALL}>{channelMovies[0].shootingDate}</Text>
                                                            }
                                                            {
                                                                this.getCsaIcon(channelMovies[0].csa) &&
                                                                <SvgIcon icon={this.getCsaIcon(channelMovies[0].csa)} height="1.85vh" />
                                                            }
                                                            {
                                                                this.getDefinitionIcon(channelMovies[0].definition) &&
                                                                <SvgIcon icon={this.getDefinitionIcon(channelMovies[0].definition)} height="1.85vh" />
                                                            }
                                                        </div>
                                                        {
                                                            channelMovies[0].distribution && channelMovies[0].distribution.length > 0 &&
                                                            <div className="channel-page-line-distribution-box">
                                                                {
                                                                    channelMovies[0].distribution.map((el, i) =>
                                                                        <div key={i}>
                                                                            <AvatarItem
                                                                                alt={el.title}
                                                                                avatar={el.imagePath}
                                                                                className='channel-main-movie-distribution'
                                                                                navigation={this.navigation}
                                                                                notFocusable={true}
                                                                            />
                                                                            <Text align={ALIGN_CENTER} size={SIZE_SMALL}>
                                                                                {el.title}
                                                                            </Text>
                                                                            <Text align={ALIGN_CENTER} color={COLOR_GREY} size={SIZE_SMALL}>
                                                                                {this.getRoles(el.role)}
                                                                            </Text>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <HorizontalList className="details__infos__btns channel-main-movie-btns">
                                                    <Button
                                                        navigation={this.navigation}
                                                        size={BUTTON_BIG}
                                                        style={BUTTON_OUTLINE}
                                                        setBtnFocused={this.setBtnFocused}
                                                        url={this.playBtnAction}
                                                        history={history}
                                                    >
                                                        <SvgIcon icon={IconPlay} height="2.41vh" width="2.41vh" />
                                                        <Text>Lecture</Text>
                                                    </Button>
                                                    <Button
                                                        navigation={this.navigation}
                                                        size={BUTTON_ROUND}
                                                        style={BUTTON_OUTLINE}
                                                        setBtnFocused={this.setBtnFocused}
                                                        url={() => this.favoriteBtnAction(isInFavorite)}
                                                        history={history}
                                                    >
                                                        {
                                                            isInFavorite ?
                                                            <SvgIcon icon={IconCheck} height="2.9vh" width="2.9vh" />
                                                            :
                                                            <SvgIcon icon={IconAdd} height="2.9vh" width="2.9vh" />
                                                        }
                                                    </Button>
                                                    <Button
                                                        navigation={this.navigation}
                                                        size={BUTTON_ROUND}
                                                        style={BUTTON_OUTLINE}
                                                        setBtnFocused={this.setBtnFocused}
                                                        url={() => this.thumbBtnAction(THUMB_UP)}
                                                        history={history}
                                                    >
                                                        {
                                                            isThumbUp ?
                                                            <SvgIcon icon={IconThumbUpFilled} height="2.9vh" width="2.9vh" />
                                                            :
                                                            <SvgIcon icon={IconThumbUp} height="2.9vh" width="2.9vh" />
                                                        }
                                                    </Button>
                                                    <Button
                                                        navigation={this.navigation}
                                                        size={BUTTON_ROUND}
                                                        style={BUTTON_OUTLINE}
                                                        setBtnFocused={this.setBtnFocused}
                                                        url={() => this.thumbBtnAction(THUMB_DOWN)}
                                                        history={history}
                                                    >
                                                        {
                                                            isThumbDown ?
                                                            <SvgIcon icon={IconThumbDownFilled} height="2.9vh" width="2.9vh" />
                                                            :
                                                            <SvgIcon icon={IconThumbDown} height="2.9vh" width="2.9vh" />
                                                        }
                                                    </Button>
                                                </HorizontalList>
                                            </>
                                        }
                                        {
                                            isLoaded && channelMovies && channelMovies.length > 1 &&
                                            <div className="category">
                                                <Text size={SIZE_LARGE}>La suite du programme</Text>
                                                <div className="category__arrow__container">
                                                    <List
                                                        ref={el => (this.list = el)}
                                                        items={channelMovies.slice(1)}
                                                        rowRef={this.row}
                                                        categoryIndex={1}
                                                        setThisListActive={() => this.setListActive(1)}
                                                        parentCategory={this}
                                                        redirectTo={url => goTo(history, url)}
                                                        goBack={goBack}
                                                        hGap={200}
                                                        navigation={this.navigation}
                                                        viewport={this.scrollableDiv.current}
                                                        verticalAutoScroll={true}
                                                        wrappedList={false}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </VerticalList>
                        </div>    
                    </HorizontalList>
                </div>
            </Navigation>
        );
    }
}

Channel.contextType = AuthContext;

const stateToProps = state => ({
    channelMovies: state.catalog.channelMovies,
    channels: state.catalog.channels,
    rowGroups: state.catalog.rowGroups,
    themeActive: state.catalog.themeActive,
    themes: state.catalog.themes,
});

const dispatchToProps = dispatch => ({
    addMovieToFavorites: (isInFavorite, movie, userId, version) => dispatch(addMovieToFavorites(isInFavorite, movie, userId, version)),
    getChannelMovies: channelLabel => dispatch(getChannelMovies(channelLabel)),
    getFullCatalogNew: (themeId, userId, version) => dispatch(getFullCatalogNew(themeId, userId, version)),
    setIsChannelsMovies: isChannelsMovies => dispatch(setIsChannelsMovies(isChannelsMovies)),
    setMovie: (movie, nextPlaylist) => dispatch(setMovie(movie, nextPlaylist)),
});

export default connect(stateToProps, dispatchToProps)(withLastLocation(Channel));