import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuthContext } from 'contexts';

import Carousel from 'components/Carousel';
import CatsRow from 'components/CatsRow';
import Loader from 'components/Loader';
import Navigation, { Focusable, VerticalList, HorizontalList } from 'components/Navigation';
import RowGroup from 'components/RowGroup';
import Sidebar from 'components/SideBar';

import { getCatsList, getFullCatalogNew } from 'redux/actions/CatalogActions';
import { goBack, goTo, isReturnEvent, PATH_HOME } from 'utils/NavigationUtils';

/**
 * Page containing carousel, and lists of films
 */
class Catalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeList: null,
            carouselActive: true,
            showCatalog: false,
        };

        this.carouselMouseEnter = this.carouselMouseEnter.bind(this);
        this.redirectTo = this.redirectTo.bind(this);
        this.setListActive = this.setListActive.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);

        this.catalogTimeout = null;
        this.catalogTimeout_ = null;
        this.rowsRef = [];
    };

    componentDidMount() {
        const { profileVersion, user } = this.context;
        const { dataLoaded, getFullCatalog, lastFocusCatalog, sidebarExit, themeActive, themes } = this.props;

        if ( ! dataLoaded ) {
            this.props.getCatsList( themes[ themeActive ] );
            getFullCatalog( themes[ themeActive ], user.NoCli, profileVersion );
        } else {
            this.setState({ showCatalog: true });
            if ( sidebarExit ) {
                if ( this.sidebar.menu.quit.item.focusableId ) this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
            } else {
                this.catalogTimeout = setTimeout( () => {
                    if ( lastFocusCatalog && lastFocusCatalog.length > 0 ) {
                        this.navigation.forceFocus( lastFocusCatalog );
                    } else if ( this.carouselFocusable ) {
                        this.navigation.forceFocus( this.carouselFocusable.focusableId );
                    } else {
                        this.navigation.forceFocus( this?.rowsRef[0]?.list?.itemsRef[0]?.itemFocusable?.focusableId );
                    }
                }, 300);
            }
        }

        window.addEventListener('keydown', this.keyDownListener);
    };

    componentDidUpdate( prevProps, prevState, snapshot ) {
        const { profileVersion, user } = this.context;
        const { dataLoaded, getFullCatalog, sidebarExit, themeActive, themes } = this.props;

        // Fetch data from api if theme has changed in reducer
        if ( prevProps.dataLoaded && ! dataLoaded ) {
            this.props.getCatsList( themes[ themeActive ] );
            getFullCatalog( themes[ themeActive ], user.NoCli, profileVersion );
            this.setState({ showCatalog: false });
        } else if ( ! prevProps.dataLoaded && dataLoaded ) {
            this.setState({ showCatalog: true });
            if ( sidebarExit ) {
                this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
            } else {
                this.catalogTimeout_ = setTimeout( () => {
                    if ( this.carouselFocusable ) {
                        this.navigation.forceFocus( this.carouselFocusable.focusableId );
                    } else {
                        this.navigation.forceFocus( this?.rowsRef[0]?.list?.itemsRef[0]?.itemFocusable?.focusableId );
                    }
                }, 300);
            }
        }
    };

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
        clearTimeout( this.catalogTimeout );
        clearTimeout( this.catalogTimeout_ );
    };

    shouldComponentUpdate( nextProps, nextState, nextContext ) {
        if ( this.props.match.params.action === 'quit' && ! nextProps.match.params.action ) return false;
        return true;
    };

    /**
     * Set focus on carousel on mouse enter event
     */
    carouselMouseEnter() {
        this.navigation.forceFocus(this.carouselFocusable.focusableId);
    };

    /**
     * Open sidebar on specified item focus
     * @param evnt
     */
    keyDownListener(evnt) {
        if (isReturnEvent(evnt) && this.sidebar && this.navigation && !this.sidebar.state.active) {
            this.sidebar.setActive(true);
            this.navigation.forceFocus(this.sidebar.menu.quit.item.focusableId);
            goTo(this.props.history, PATH_HOME, true);
        }
    };

    redirectTo(url) {
        goTo(this.props.history, url);
    };

    /**
     * Set carouselActive state and focus/blur actual visible carousel element
     * @param active
     */
    setCarouselActive(active) {
        this.setState({ carouselActive: active });

        if (this.carousel && this.carousel.carousel) {
            active
                ? this.carousel.carousel.carouselWrapperRef.focus()
                : this.carousel.carousel.carouselWrapperRef.blur();
        }
    };

    /**
     * Set activeList state to given index or null
     * @param index
     */
    setListActive(index = null) {
        this.setState({ activeList: index });
    };

    render() {
        const { carouselActive, showCatalog } = this.state;
        const { catsList, history, rowsOrder, rowGroups, carouselContent } = this.props;

        return (
            <>
                {   
                    showCatalog ?
                    <Navigation ref={el => (this.navigation = el)}>
                        <div ref={el => (this.catalog = el)} className="catalog">
                            <HorizontalList>
                                <Sidebar
                                    ref={el => (this.sidebar = el)}
                                    history={history}
                                    navigation={this.navigation}
                                    parent={this}
                                />
                                <VerticalList ref={el => (this.mainFocus = el)}>
                                    { carouselContent?.length > 0 && 
                                        <Focusable
                                            className="carousel--focusable"
                                            ref={el => (this.carouselFocusable = el)}
                                            onMouseEnter={this.carouselMouseEnter}
                                            onFocus={() => {
                                                this.setCarouselActive(true);
                                                this.setListActive();
                                            }}
                                            onBlur={() => this.setCarouselActive(false)}
                                            navDefault={true}
                                        >
                                            <Carousel
                                                parent={this}
                                                infiniteLoop={true}
                                                showThumbs={false}
                                                useKeyboardArrows={true}
                                                autoPlay={true}
                                                showStatus={false}
                                                ref={el => (this.carousel = el)}
                                                images={this.props.carouselContent}
                                                focusableItem={this.carouselFocusable}
                                                className={
                                                    'carousel' +
                                                    (carouselActive ? ' carousel--active' : '')
                                                }
                                                redirectTo={this.redirectTo}
                                                goBack={() => goBack(history)}
                                            />
                                        </Focusable>
                                    }
                                    {
                                        catsList && catsList.length > 0 &&
                                        <CatsRow
                                            ref={el => (this.rowsRef[0] = el)}
                                            catalog={this}
                                            history={history}
                                            items={catsList}
                                            navigation={this.navigation}
                                            rowIndex={0}
                                            setListActive={this.setListActive}
                                            viewport={this.catalog}
                                        />
                                    }
                                    {
                                        rowsOrder
                                            .filter( groupName => rowGroups[groupName]?.length > 0 )
                                            .map( ( groupName, i ) => (
                                                <RowGroup
                                                    key={i+1}
                                                    groupIndex={i+1}
                                                    rows={rowGroups[groupName]}
                                                    groupName={groupName}
                                                    catalog={this}
                                                    navigation={this.navigation}
                                                    viewport={this.catalog}
                                                    ref={el => (this.rowsRef[i+1] = el)}
                                                    setListActive={this.setListActive}
                                                />
                                            ))
                                    }
                                </VerticalList>
                            </HorizontalList>
                        </div>
                    </Navigation>
                    :
                    <Loader className="catalog__loader" />
                }
            </>
        );
    };
}

Catalog.contextType = AuthContext;

const stateToProps = state => ({
    carouselContent: state.catalog.carousel,
    catsList: state.catalog.catsList,
    catsListLoaded: state.catalog.catsListLoaded,
    dataLoaded: state.catalog.dataLoaded,
    lastFocusCatalog: state.catalog.lastFocusCatalog,
    rowGroups: state.catalog.rowGroups,
    rowsOrder: state.catalog.rowsOrder,
    themeActive: state.catalog.themeActive,
    themes: state.catalog.themes
});

const dispatchToProps = dispatch => ({
    getCatsList: themeId => dispatch(getCatsList(themeId)),
    getFullCatalog: (themeId, userId, version) => dispatch(getFullCatalogNew(themeId, userId, version)),
});

export default connect(stateToProps, dispatchToProps)(Catalog);