import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';

import { AuthContext } from 'contexts';

import AvatarItem from 'components/AvatarItem';
import ListItem from 'components/ListItem';
import Navigation, { VerticalList, HorizontalList, Grid } from 'components/Navigation';
import Sidebar from 'components/SideBar';
import Text, { ALIGN_CENTER, SIZE_LARGE } from 'components/Text';

import { getSearchedMovies } from 'redux/actions/CatalogActions';
import { goBack, goTo, isReturnEvent, PATH_HOME } from 'utils/NavigationUtils';

class Category extends Component {
    static GRID_COLUMNS = 4;

    constructor(props) {
        super(props);

        this.state = {
            activeItem: null,
            actualIndex: null,
            isGridLoaded: false,
            isNavigationLoaded: false,
            mouseFocus: false,
        };

        this.navigation = React.createRef();
        this.scrollableDiv = React.createRef();

        this.keyDownListener = this.keyDownListener.bind(this);
        
        this.gridTimeout = null;
    }

    componentDidMount() {
        const categoryUrl = this.props.match.params.CategoryID;
        if ( ! categoryUrl ) goTo( this.props.history, PATH_HOME );

        const { profileVersion, user } = this.context;
        const { searchedMoviesDisplay, sidebarExit } = this.props;

        if ( searchedMoviesDisplay.url !== categoryUrl ) {
            this.props.getSearchedMovies( categoryUrl, user.NoCli, profileVersion );
        }
        
        if ( sidebarExit && this.sidebar.menu.quit.item.focusableId ) this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
        window.addEventListener('keydown', this.keyDownListener);
    };

    componentDidUpdate( prevProps, prevState ) {
        const { lastFocusCategory } = this.props;
        const { isGridLoaded } = this.state;

        if ( prevState.isGridLoaded !== isGridLoaded && isGridLoaded ) {
            if ( lastFocusCategory && lastFocusCategory.length > 0 ) {
                this.gridTimeout = setTimeout( () => {
                    if ( lastFocusCategory && lastFocusCategory.length > 0 ) this.navigation.forceFocus( lastFocusCategory );
                }, 300);
            } else {
                this.navigation.forceFocus( this.grid.focusableId );
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
        clearTimeout( this.gridTimeout );
    };

    shouldComponentUpdate( nextProps, nextState, nextContext ) {
        if ( this.props.match.params.action === 'quit' && ! nextProps.match.params.action ) return false;
        return true;
    };

    setActualIndex(index) {
        this.setState({ actualIndex: index });
    };

    setItemActive(index = null) {
        this.setState({ activeItem: index });
    };

    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    };

    keyDownListener(evnt) {
        if ( isReturnEvent(evnt) ) goTo( this.props.history, PATH_HOME );
    };

    render() {
        const { history, searchedMovies, searchedMoviesDisplay } = this.props;
        const { isGridLoaded, isNavigationLoaded } = this.state;

        return (
            <Navigation ref={el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <div className="category-page">
                    <HorizontalList>
                        <Sidebar
                            ref={el => (this.sidebar = el)}
                            history={history}
                            navigation={this.navigation}
                            parent={this}
                        />
                        <VerticalList>
                            {
                                isNavigationLoaded &&
                                <>
                                    <div className='category-page--title-box'>
                                        <AvatarItem
                                            alt={searchedMoviesDisplay.title}
                                            avatar={searchedMoviesDisplay.imagePathCircle}
                                            className={'catsList'}
                                            notFocusable={true}
                                        />
                                        <Text align={ALIGN_CENTER} size={SIZE_LARGE}>{searchedMoviesDisplay.title}</Text>
                                    </div>
                                    <div className="search__content category-page--content" ref={this.scrollableDiv}>
                                        {
                                            searchedMovies && searchedMovies.length > 0 &&
                                            <Grid
                                                ref={el => {
                                                    this.grid = el;
                                                    if ( ! isGridLoaded ) this.setState({ isGridLoaded: true });
                                                }}
                                                columns={Category.GRID_COLUMNS}
                                                rows={Math.ceil( searchedMovies.length / Category.GRID_COLUMNS )}
                                            >
                                                {
                                                    searchedMovies.map( ( item, i ) =>
                                                        <ListItem
                                                            categoryIndex={`0-${i}`}
                                                            goBack={() => goBack(history)}
                                                            item={item}
                                                            itemIndex={i}
                                                            key={i}
                                                            lazyLoadingShift={20}
                                                            navigation={this.navigation}
                                                            parentCategory={this}
                                                            parentList={this}
                                                            redirectTo={url => goTo(history, url)}
                                                            retainLastFocusCategory
                                                            setThisItemActive={() => this.setItemActive(i)}
                                                            verticalAutoScroll
                                                            vGap={200}
                                                            viewport={this.scrollableDiv.current}
                                                        />
                                                    )
                                                }
                                            </Grid>
                                        }
                                    </div>
                                </>
                            }
                        </VerticalList>
                    </HorizontalList>
                </div>
            </Navigation>
        );
    }
}

Category.contextType = AuthContext;

const stateToProps = state => ({
    lastFocusCategory: state.catalog.lastFocusCategory,
    searchedMovies: state.catalog.searchedMovies,
    searchedMoviesDisplay: state.catalog.searchedMoviesDisplay,
});

const dispatchToProps = dispatch => ({
    getSearchedMovies: (search, userId, version) => dispatch(getSearchedMovies(search, userId, version)),
});

export default connect(stateToProps, dispatchToProps)(withLastLocation(Category));