import React, { useState, useEffect, useCallback, useMemo, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Focusable } from 'components/Navigation';
import Text, { COLOR_GREY as TEXT_COLOR_GREY } from 'components/Text';
import Title, { LEVEL_2, COLOR_PRIMARY as TITLE_COLOR_PRIMARY, COLOR_GREY as TITLE_COLOR_GREY } from 'components/Title';

import { getImageUrl, typeThumb, visual1, visual2 } from 'api/ImageManager';
import { setIsChannelsMovies, setMovie } from 'redux/actions/CatalogActions';
import { goTo, PATH_PLAY } from 'utils/NavigationUtils';

const baseClass = 'episode';

const getCssClasses = (isActive, isFocus) => {
    let classes = baseClass;
    classes += isActive ? ` ${baseClass}--active` : '';
    classes += isFocus ? ` ${baseClass}--focus` : '';
    return classes;
};

const Episode = ({
    episode,
    episodesListRef,
    navigation,
    vGap,
    isFocus,
    setEpisodeFocusedIndex,
    forceFocus,
    parentSetEpisodeToFocus,
    parentSetEpisodeToScrollTo,
    scrollTo,
    setMouseFocus,
    isActive
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let focusableRef = createRef();
    let episodeRef = createRef();

    const [thumbErrors, setThumbErrors] = useState(0);
    const thumbUrl = useMemo(() => getImageUrl(episode.transaction, typeThumb, visual2), [episode]);

    const isInViewport = useCallback(
        (bringToTop = false) => {
            const episodesList = episodeRef.parentNode.parentNode;
            const series = episodesList.parentNode.parentNode.parentNode;
            const item = episodeRef.getBoundingClientRect();
            const itemTop = item.top - episodeRef.offsetHeight * 0.05;
            const itemBottom = item.bottom + episodeRef.offsetHeight * 0.05;
            const { top } = episodesList.getBoundingClientRect();
            const { bottom } = series.getBoundingClientRect();

            if (bringToTop) {
                episodesList.scrollTop += itemTop - top;
                return;
            }
            if (itemBottom > bottom) {
                episodesList.scrollTop += itemBottom - bottom + vGap;
            } else if (itemTop < top) {
                episodesList.scrollTop += itemTop - top - vGap;
            }
        },
        [episodeRef, vGap]
    );

    const onMouseEnterHandler = () => {
        navigation.current?.forceFocus(focusableRef.focusableId);
        setMouseFocus(true);
    };

    const onFocusHandler = (i, event) => {
        isInViewport();
        setMouseFocus(false);
        setEpisodeFocusedIndex();
    };

    const onEnterDownHandler = () => {
        if (!isFocus) return;
        dispatch(setMovie({ ...episode, progressTime: episode.time }));
        dispatch(setIsChannelsMovies(false));
        goTo(history, `${PATH_PLAY}/${episode.transaction}`);
    };

    const imageOnErrorHandler = evt => {
        if (thumbErrors < 1) {
            evt.target.src = getImageUrl(episode.transaction, typeThumb, visual1);
        } else if (thumbErrors < 2) {
            evt.target.src = require('../../assets/img/placeholder-500x281.jpg');
        }
        setThumbErrors(prevState => prevState + 1);
    };

    useEffect(() => {
        if (!forceFocus || !navigation.current || !focusableRef.focusableId) return;

        const { currentFocusedPath } = navigation.current;
        if (
            currentFocusedPath[currentFocusedPath.length - 1].focusableId !==
            focusableRef.focusableId
        ) {
            navigation.current.forceFocus(focusableRef.focusableId);
        }
    }, [forceFocus, navigation, focusableRef.focusableId]);

    useEffect(() => {
        if (!scrollTo || !episodesListRef.current || !focusableRef) return;

        if (scrollTo) {
            episodesListRef.current.lastFocusChild = focusableRef.indexInParent;
            isInViewport(true);
            parentSetEpisodeToScrollTo(null);
            parentSetEpisodeToFocus(null);
        }
    }, [
        scrollTo,
        focusableRef,
        episodesListRef,
        isInViewport,
        parentSetEpisodeToScrollTo,
        parentSetEpisodeToFocus
    ]);

    return (
        <div className={getCssClasses(isActive, isFocus)} ref={r => (episodeRef = r)}>
            <Focusable
                ref={r => (focusableRef = r)}
                onMouseEnter={onMouseEnterHandler}
                onFocus={onFocusHandler}
                onClick={onEnterDownHandler}
                onEnterDown={onEnterDownHandler}
            >
                <div className="episode__thumbnail" ref={r => (episodeRef = r)}>
                    <img src={thumbUrl} onError={imageOnErrorHandler} alt="" />
                </div>
                <div className="episode__infos">
                    <Title level={LEVEL_2} color={isFocus ? TITLE_COLOR_PRIMARY : TITLE_COLOR_GREY}>
                        {episode.titre}
                    </Title>
                    <Text color={isFocus ? null : TEXT_COLOR_GREY}>{episode.synopsis}</Text>
                </div>
            </Focusable>
        </div>
    );
};

Episode.propTypes = {
    episode: PropTypes.object.isRequired,
    episodesListRef: PropTypes.object,
    forceFocus: PropTypes.bool,
    isActive: PropTypes.bool,
    isFocus: PropTypes.bool,
    navigation: PropTypes.object,
    parentSetEpisodeToFocus: PropTypes.func,
    parentSetEpisodeToScrollTo: PropTypes.func,
    scrollTo: PropTypes.bool,
    setEpisodeFocusedIndex: PropTypes.func,
    setMouseFocus: PropTypes.func,
    vGap: PropTypes.number
};

Episode.defaultProps = {
    forceFocus: false,
    verticalAutoScroll: true,
    vGap: 40
};

export default Episode;
