import React, { Component } from 'react';
import { connect } from 'react-redux';

import Arrow from './Arrow';
import AvatarItem from './AvatarItem';
import List from './List';
import { HorizontalList, VerticalList } from './Navigation';
import Text, { ALIGN_CENTER, SIZE_SMALL } from 'components/Text';

import { clearSearchedMovies, clearSpecificLastFocus } from 'redux/actions/CatalogActions';
import { goTo, LAST_FOCUS_ALL, PATH_CATEGORY } from 'utils/NavigationUtils';

class CatsRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actualIndex: 0,
            isLoaded: false,
            mouseFocus: false,
            activeList: null,
        };

        this.horizontalList = React.createRef();
        this.row = React.createRef();

        this.handleAvatarClick = this.handleAvatarClick.bind(this);
        this.onFocusList = this.onFocusList.bind(this);
        this.setActualIndex = this.setActualIndex.bind(this);
        this.setMouseFocus = this.setMouseFocus.bind(this);
    };

    componentDidMount() {
        this.setState({ isLoaded: true });
    };

    focusNewElement(direction) {
        const newIndex = this.state.actualIndex + (direction ? 1 : -1);
        if (newIndex >= 0 && newIndex < this.props.items.length) {
            this.props.navigation.forceFocus(this.horizontalList.children[newIndex].children[0].focusableId);
        }

        this.setMouseFocus(true);
    };

    handleAvatarClick( url ) {
        this.props.clearSearchedMovies();
        this.props.clearSpecificLastFocus( LAST_FOCUS_ALL );
        if ( url ) goTo( this.props.history, `${PATH_CATEGORY}/${url}` );
    };

    onFocusList() {
        const { rowIndex, setListActive, viewport } = this.props;

        if ( ! viewport?.getBoundingClientRect || ! this.row?.getBoundingClientRect ) return;

        const categoryTop = this.row.getBoundingClientRect().top;
        const categoryBottom = this.row.getBoundingClientRect().bottom;
        const { bottom, top } = viewport.getBoundingClientRect();

        if ( categoryBottom > bottom ) {
            viewport.scrollTop += categoryBottom - bottom + List.SCROLL_GAP;
        } else if ( categoryTop < top ) {
            viewport.scrollTop += categoryTop - top;
        }

        setListActive(rowIndex);
    };

    setActualIndex(index) {
        this.setState({ actualIndex: index });
    };

    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    };

    render() {
        const { catalog, items, navigation, rowIndex } = this.props;
        const { actualIndex, isLoaded, mouseFocus } = this.state;

        return (
            <div ref={el => (this.row = el)} className="category">
                <div className="category__arrow__container">
                    {   
                        catalog.state.activeList === rowIndex && mouseFocus && actualIndex > 0 &&
                        <Arrow
                            direction={Arrow.DIRECTION_LEFT}
                            background={Arrow.BCKGND_GRADIENT}
                            onClick={() => this.focusNewElement(false)}
                        />
                    }
                    {
                        catalog.state.activeList === rowIndex && mouseFocus && actualIndex < items.length - 1 &&
                        <Arrow
                            direction={Arrow.DIRECTION_RIGHT}
                            background={Arrow.BCKGND_GRADIENT}
                            onClick={() => this.focusNewElement(true)}
                        />
                    }
                    {
                        isLoaded &&
                        <HorizontalList
                            ref={el => (this.horizontalList = el)}
                            className={'list list--catsList'}
                            onFocus={this.onFocusList}
                        >
                            {
                                items && items.length > 0 &&
                                items.map( ( item, index ) =>
                                    <VerticalList key={index} className={'avatar-item--box'}>
                                        <AvatarItem
                                            action={url => this.handleAvatarClick(item.url)}
                                            alt={item.title}
                                            avatar={item.imagePathCircle}
                                            className={'catsList'}
                                            catIndex={index}
                                            horizontalAutoScroll
                                            hGap={300}
                                            navigation={navigation}
                                            setActualIndex={this.setActualIndex}
                                            setMouseFocus={this.setMouseFocus}
                                        />
                                        <Text align={ALIGN_CENTER} className={'avatar-item--name avatar-item--name--catsList'} size={SIZE_SMALL}>{ item.name }</Text>
                                    </VerticalList>
                                )
                            }
                            <div className="list__gap" />
                        </HorizontalList>
                    }
                </div>
            </div>
        );
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
    clearSearchedMovies: () => dispatch(clearSearchedMovies()),
    clearSpecificLastFocus: type => dispatch(clearSpecificLastFocus(type)),
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(CatsRow);