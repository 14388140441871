import React, { Component } from 'react';
import { connect } from 'react-redux';

import AvatarItem from 'components/AvatarItem';
import ListItem from 'components/ListItem';
import { HorizontalList, Grid } from 'components/Navigation';
import Text, { ALIGN_CENTER, SIZE_LARGE } from 'components/Text';

import { goBack, goTo, PATH_CHANNEL } from 'utils/NavigationUtils';

class ChannelProgramColumn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLastFocusInitialized: false,
            isGridLoaded: false,
        };

        this.grid = React.createRef();
        this.scrollableDiv = React.createRef();

        this.isLastFocusElement = this.isLastFocusElement.bind(this);
        this.isFocused = this.isFocused.bind(this);
        
        this.focusLastFocusTimeout = null;
    }

    componentDidUpdate( prevProps, prevState ) {
        const { isNavigationLoaded, lastFocusChannelsProgram, navigation } = this.props;
        const { isGridLoaded, isLastFocusInitialized } = this.state;

        if ( isNavigationLoaded && isGridLoaded && lastFocusChannelsProgram && ! isLastFocusInitialized ) {
            this.setState({ isLastFocusInitialized: true });
            this.focusLastFocusTimeout = setTimeout(() => {
                navigation.forceLastFocusElementId();
            }, 1000);
        }
    };

    componentWillUnmount() {
        clearTimeout( this.focusLastFocusTimeout );
    };

    getTimeFormat( timestamp ) {
        return `à ${new Intl.DateTimeFormat( 'fr', { timeStyle: 'short' } ).format(timestamp * 1000).replace( ':', ' h ' )}`;
    };

    isFocused( channelIndex, itemIndex, isAtSameTime ) {
        const { lastFocusChannelsProgram } = this.props;

        if ( ! lastFocusChannelsProgram || lastFocusChannelsProgram.length !== 2 || channelIndex !== lastFocusChannelsProgram[0] ) return isAtSameTime;
        return itemIndex === lastFocusChannelsProgram[1];
    };

    isLastFocusElement( channelIndex, itemIndex ) {
        const { lastFocusChannelsProgram } = this.props;

        return lastFocusChannelsProgram && lastFocusChannelsProgram.length === 2 && channelIndex === lastFocusChannelsProgram[0] && itemIndex === lastFocusChannelsProgram[1];
    };

    render() {
        const { channelIndex, channelsMoviesBySheetId, isLoaded, history, moviesList, moviesInfo, navigation, parent, setCurrentDayIntFocused, setItemActive } = this.props;
        const { isGridLoaded } = this.state;

        return (
            <>
                {
                    moviesList && moviesList.length > 0 &&
                    <div className="channels-program-line-box">
                        <div className="channels-program-line-title-box">
                            <AvatarItem
                                action={() => goTo(history, `${PATH_CHANNEL}/${moviesInfo.label}`)}
                                alt={moviesInfo.title}
                                avatar={moviesInfo.imagePath}
                                className={'catsList'}
                                isChannelProgramAvatar={true}
                                isChannelProgramMainAvatar={channelIndex === 0}
                                focus={channelIndex === 0}
                                navigation={navigation}
                                setCurrentDayIntFocused={setCurrentDayIntFocused}
                            />
                            <Text align={ALIGN_CENTER} size={SIZE_LARGE}>{moviesInfo.title}</Text>
                        </div>
                        <div className="channels-program-list-box" ref={this.scrollableDiv}>
                            {
                                isLoaded && 
                                <Grid
                                    ref={el => {
                                        if ( el ) this.grid = el;
                                        if ( ! isGridLoaded ) this.setState({ isGridLoaded: true });
                                    }}
                                    columns={1}
                                    rows={moviesList.length}
                                >
                                    {
                                        moviesList.map( ( item, i ) => 
                                            <HorizontalList key={i}>
                                                <div className="channels-program-item-box">
                                                    <div className="channels-program-item-info-box">
                                                        <Text size={SIZE_LARGE} className="channels-program-item-time">
                                                            { this.getTimeFormat( item.startAt ) }
                                                        </Text>
                                                        {
                                                            item.isInLive &&
                                                            <Text className="channels-program-item-inLive">
                                                                En direct
                                                                <span className="channels-program-item-inLive-dot"/>
                                                            </Text>
                                                        }
                                                    </div>
                                                    <ListItem
                                                        categoryIndex={`channelProgram-${i}`}
                                                        channelIndex={channelIndex}
                                                        channelStartAt={item.startAt}
                                                        goBack={() => goBack(history)}
                                                        isChannelProgram={true}
                                                        isFocused={this.isFocused( channelIndex, i, item.isAtSameTime )}
                                                        isInLive={item.isInLive}
                                                        isLastFocusElement={this.isLastFocusElement( channelIndex, i )}
                                                        item={channelsMoviesBySheetId[ item.sheetId ]}
                                                        itemIndex={i}
                                                        lazyLoadingShift={20}
                                                        navigation={navigation}
                                                        parentCategory={parent}
                                                        parentList={parent}
                                                        progressTimeForChannel={item.progressTime}
                                                        redirectTo={url => goTo(history, url)}
                                                        retainLastFocusChannelsProgram
                                                        setCurrentDayIntFocused={setCurrentDayIntFocused}
                                                        setThisItemActive={() => setItemActive(i)}
                                                        timeTotalForChannel={item.timeTotal}
                                                        verticalAutoScroll
                                                        vGap={200}
                                                        viewport={this.scrollableDiv.current}
                                                    />
                                                </div>
                                            </HorizontalList>
                                        )
                                    }
                                </Grid>
                            }
                        </div>
                    </div>    
                }
            </>
        );
    }
}

const stateToProps = state => ({
    channelsMoviesBySheetId: state.catalog.channelsMoviesBySheetId,
    lastFocusChannelsProgram: state.catalog.lastFocusChannelsProgram,
});

export default connect(stateToProps)(ChannelProgramColumn);