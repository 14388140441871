import React, { createRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Focusable } from 'components/Navigation';

import StringUtils from 'utils/StringUtils';
import { goBack, goTo } from 'utils/NavigationUtils';

export const SIZE_MEDIUM = 'medium';
export const SIZE_BIG = 'big';
export const SIZE_GIANT = 'giant';
export const BLOCK_DEFAULT = 'block-default';
export const BLOCK_FULL = 'block-full';
export const BLOCK_HALF = 'block-half';
export const STYLE_PLAIN = 'style-plain';
export const STYLE_OUTLINE = 'style-outline';
export const SIZE_ROUND = 'round';

const baseClass = 'button';

const getCssClasses = (className, style, size, block, isFocus) => {
    let classes = (className ? `${className} ` : '') + baseClass;

    switch (size) {
        case SIZE_GIANT:
            classes += ` ${baseClass}--${SIZE_GIANT}`;
            break;
        case SIZE_BIG:
            classes += ` ${baseClass}--${SIZE_BIG}`;
            break;
        case SIZE_ROUND:
            classes += ` ${baseClass}--${SIZE_ROUND}`;
            break;
        case SIZE_MEDIUM:
        default:
            classes += ` ${baseClass}--${SIZE_MEDIUM}`;
    }

    switch (block) {
        case BLOCK_FULL:
            classes += ` ${baseClass}--${BLOCK_FULL}`;
            break;
        case BLOCK_HALF:
            classes += ` ${baseClass}--${BLOCK_HALF}`;
            break;
        default:
            break;
    }

    switch (style) {
        case STYLE_OUTLINE:
            classes += ` ${baseClass}--${STYLE_OUTLINE}`;
            break;
        case STYLE_PLAIN:
        default:
            classes += ` ${baseClass}--${STYLE_PLAIN}`;
    }

    classes += isFocus ? ` ${baseClass}--focus` : '';

    return classes;
};

const Button = ({
    block,
    children,
    className,
    focusOnLoad,
    focus,
    navigation,
    parentOnFocusHandler,
    replaceUrl,
    setBtnFocused,
    size,
    style,
    url
}) => {
    const history = useHistory();
    const [isFocus, setIsFocus] = useState(false);
    const [isFocusInitialized, setIsFocusInitialized] = useState(false);
    let buttonRef = createRef();

    const ButtonAction = (url, replaceUrl = false) => {
        if (typeof url === 'function') {
            url();
        } else {
            if (StringUtils.isEmpty(url)) {
                goBack(history);
            } else {
                goTo(history, url, replaceUrl);
            }
        }
    };

    const onMouseEnterHandler = () => {
        if (typeof navigation?.forceFocus === 'function') {
            navigation.forceFocus(buttonRef.focusableId);
        } else if (typeof navigation?.current?.forceFocus === 'function') {
            navigation.current.forceFocus(buttonRef.focusableId);
        } else {
            return;
        }
        
        // setIsFocus(true);
    };

    const onFocusHandler = event => {
        if (typeof parentOnFocusHandler === 'function') {
            parentOnFocusHandler(event);
        }
        setIsFocus(true);
        if ( setBtnFocused ) setBtnFocused(true);
    };

    const onBlurHandler = () => {
        setIsFocus(false);
    };

    const onEnterDownHandler = () => {
        ButtonAction(url, replaceUrl);
    };

    const onClickHandler = () => {
        ButtonAction(url);
    };

    return (
        <Focusable
            ref={ el => {
                buttonRef = el;
                if ( focus && ! isFocusInitialized ) {
                    const focusTimeout = setTimeout(() => {
                        setIsFocusInitialized(true);
                        if ( el && el.focusableId ) navigation.forceFocus( el.focusableId );
                        clearTimeout( focusTimeout );
                    }, 500);
                }
            }}
            // ref={r => (buttonRef = r)}
            className={getCssClasses(className, style, size, block, isFocus)}
            onMouseEnter={onMouseEnterHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onEnterDown={onEnterDownHandler}
            onClick={onClickHandler}
            navDefault={focusOnLoad}
        >
            {children}
        </Focusable>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    block: PropTypes.oneOf([BLOCK_DEFAULT, BLOCK_HALF, BLOCK_FULL]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.string]),
    focusOnLoad: PropTypes.bool,
    navigation: PropTypes.object,
    parentOnFocusHandler: PropTypes.func,
    replaceUrl: PropTypes.bool,
    size: PropTypes.oneOf([SIZE_MEDIUM, SIZE_BIG, SIZE_GIANT, SIZE_ROUND]),
    style: PropTypes.oneOf([STYLE_PLAIN, STYLE_OUTLINE]),
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

Button.defaultProps = {
    className: '',
    focusOnLoad: false,
    replaceUrl: false
};

export default Button;
