import ApiService from 'services/ApiService';

const catalogOrder = [
    'spotlights',
    'channels',
    'news',
    'pinnedFirst',
    'inProgress',
    'mostViewed',
    'favorites',
    'pinnedSecond',
    'moments',
    'forUser',
    'pages',
    // 'completed',
];

const toggleUserFavoriteMovie = async ( isInFavorite, movieId, userId, version ) => {
    const action = isInFavorite ? 'remove' : 'add';
    return ApiService.get(`user/toggleFavorites.php?action=${action}&NoCli=${userId}&version=${version}&movieId=${movieId}`)
        .then(response => {
            if (!response.data.ret) throw new Error();
            return true;
        })
        .catch(() => false);
};

export const addMovieToFavorites = ( isInFavorite, movie, userId, version ) => async dispatch => {
    const res = await toggleUserFavoriteMovie( isInFavorite, movie.transaction, userId, version );
    if ( ! res ) return;

    if ( ! isInFavorite ) {
        dispatch({
            type: 'ADD_MOVIE_TO_FAVORITES',
            payload: movie,
        });
    } else {
        dispatch({
            type: 'REMOVE_MOVIE_FROM_FAVORITES',
            payload: movie,
        });
    }
};

export const forceDataReload = () => async dispatch => {
    dispatch({
        type: 'FORCE_DATA_RELOAD',
        payload: [],
    });
};

export const getAvatarList = () => async dispatch => {
    dispatch({
        type: 'GET_AVATAR_LIST',
        payload: {
            avatarList: await ApiService.get(`/user/getAvatarsList.php`)
                            .then( res => {
                                return res.data.avatars;
                            })
                            .catch(() => [])
        }
    });
};

const getThemeId = theme => {
    switch (theme) {
        case 'chasse':
            return 2;
        case 'peche':
            return 3;
        default:
            return 0;
    }
};

export const getCatsList = theme => async dispatch => {
    const themeId = getThemeId( theme );
    dispatch({
        type: 'GET_CATS_LIST',
        payload: {
            catsList: await ApiService.get(`/movies/getCatsList.php?themeId=${ themeId }`)
                            .then( res => {
                                if ( res.data?.data && res.data.data.length > 0 && res.data.data[0].cats && res.data.data[0].cats.length > 0 ) {
                                    return res.data.data[0].cats;
                                } else {
                                    return [];
                                }
                            })
                            .catch(() => [])
        }
    });
};

const getChannelMoviesRequest = async channelLabel => {
    return ApiService.get(`/movies/getChannelMovies.php?channelLabel=${channelLabel}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return res.data.data;
        })
        .catch(() => []);
};

export const getChannelMovies = channelLabel => async dispatch => {
    const res = await getChannelMoviesRequest(channelLabel);

    dispatch({
        type: 'GET_CHANNEL_MOVIES',
        payload: {
            channelMovies: res.channelMovies,
            channels: res.channels,
            isChannelsControlsDisabled: res.isChannelsControlsDisabled,
        }
    });
};

const getChannelsProgramRequest = async () => {
    return ApiService.get(`/movies/getChannelsProgram.php`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return res.data.data;
        })
        .catch(() => []);
};

export const getChannelsProgram = () => async dispatch => {
    const res = await getChannelsProgramRequest();

    dispatch({
        type: 'GET_CHANNELS_PROGRAM',
        payload: {
            channelsMoviesBySheetId: res.channelsMoviesBySheetId,
            channelsProgramList: res.channelsProgram,
            isChannelsControlsDisabled: res.isChannelsControlsDisabled,
        }
    });
};

export const setChannelsProgramDay = channelsProgramDay => async dispatch => {
    dispatch({
        type: 'SET_CHANNELS_PROGRAM_DAY',
        payload: { channelsProgramDay },
    });
};

export const setIsChannelsMovies = isChannelsMovies => async dispatch => {
    dispatch({
        type: 'SET_IS_CHANNELS_MOVIES',
        payload: { isChannelsMovies },
    });
};

const getCarouselRequest = async ( themeId, userId, version ) => {
    return ApiService.get(`/movies/getSliderMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            let carousel = [];
            if ( res.data.ret ) carousel = carousel.concat(res.data.data[0].movies);
            return carousel;
        })
        .catch(() => []);
};

const getRowsRequestsNew = async ( themeId, userId, version ) => {
    let favorites, progresses, forUser;
    if  ( userId ) {
        favorites = ApiService.get(`/movies/getFavoritesMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
            .then( res => {
                if ( ! res.data.ret ) throw new Error();
                return { favorites: res.data.data };
            })
            .catch( () => ({
                favorites: []
            }));

        // Progresses (inProgress, completed)
        progresses = ApiService.get( `/movies/getProgressMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}` )
            .then( res => {
                if ( ! res.data.ret ) throw new Error();
                res.data.data.inProgress.forEach( data => ( data.display.withProgression = true ) );
                return res.data.data;
            })
            .catch( () => ({
                inProgress: [],
                completed: [],
            }));

        forUser = ApiService.get(`/movies/getForUserMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
            .then(res => {
                if ( ! res.data.ret ) throw new Error();
                return { forUser: res.data.data };
            })
            .catch(() => ({
                forUser: [],
            }));
    }

    const channels = ApiService.get(`/movies/getChannels.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            res.data.data.forEach( data => ( data.display.withProgression = true ) );
            return { channels: res.data.data };
        })
        .catch(() => ({
            channels: [],
        }));

    const moments = ApiService.get(`/movies/getMomentMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return { moments: res.data.data };
        })
        .catch(() => ({
            moments: [],
        }));

    const mostViewed = ApiService.get( `/movies/getMostViewedMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}` )
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return { mostViewed: res.data.data };
        })
        .catch(() => ({
            mostViewed: [],
        }));

    const news = ApiService.get(`/movies/getNewMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return { news: res.data.data };
        })
        .catch(() => ({
            news: [],
        }));

    const pages = ApiService.get(`/movies/getPageMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return { pages: res.data.data };
        })
        .catch(() => ({
            pages: [],
        }));

    // Pins (pinnedFirst, PinnedSecond)
    const pins = ApiService.get(`/movies/getPinnedMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return res.data.data;
        })
        .catch(() => ({
            pinnedFirst: [],
            pinnedSecond: [],
        }));

    const spotlights = ApiService.get(`/movies/getSpotlightedMovies.php?themeId=${themeId}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return { spotlights: res.data.data };
        })
        .catch(() => ({
            spotlights: [],
        }));

    return Promise.all([
        channels,
        favorites,
        forUser,
        news,
        moments,
        mostViewed,
        pages,
        pins,
        progresses,
        spotlights,
    ])
        .then( values => {
            let catalog = {};
            catalogOrder.forEach( catalogRow => {
                const valueIndex = values.findIndex(row => Array.isArray(row[catalogRow]));
                catalog[catalogRow] = values[valueIndex][catalogRow];
            });

            // TODO : fix focus problem on last line (completed one) to remove this fix and uncomment catalogOrder line
            const completedIndex = values.findIndex(row => Array.isArray(row['completed']));
            catalog['pages'] = catalog['pages'].concat( values[completedIndex]['completed'] );

            return catalog;
        })
        .catch(() => []);
};

export const getFullCatalogNew = ( theme, userId, version ) => async dispatch => {
    const themeId = getThemeId( theme );
    dispatch({
        type: 'GET_FULL_CATALOG_NEW',
        payload: {
            carousel: await getCarouselRequest( themeId, userId, version ),
            rows: await getRowsRequestsNew( themeId, userId, version ),
        }
    });
};

export const getMovie = ( movieId, userId, version ) => async dispatch => {
    const res = await ApiService.get(`/movies/getMovieById.php?movieId=${movieId}&NoCli=${userId}&version=${version}`);
    const movie = res.data.data[0];
    dispatch({
        type: 'GET_MOVIE',
        payload: { movie },
    });
};

export const setMovie = ( movie, nextPlaylist ) => async dispatch => {
    dispatch({
        type: 'SET_MOVIE',
        payload: { movie, nextPlaylist },
    });
};

const getSearchRequest = async ( search, userId, version ) => {
    return ApiService.get(`/movies/getSearchedMoviesByString.php?searchText=${search}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return res.data.data[0].movies;
        })
        .catch(() => []);
};

export const clearSearch = () => async dispatch => {
    dispatch({
        type: 'CLEAR_SEARCH_MOVIE',
        payload: {},
    });
};

export const getSearch = ( search, userId, version ) => async dispatch => {
    const res = await getSearchRequest( search, userId, version );

    dispatch({
        type: 'SEARCH_MOVIE',
        payload: {
            searchText: search,
            searchResult: res
        }
    });
};

const getSearchedMoviesRequest = async ( search, userId, version ) => {
    return ApiService.get(`/movies/getSearchedMovies.php?searchText=${search}&NoCli=${userId}&version=${version}`)
        .then( res => {
            if ( ! res.data.ret ) throw new Error();
            return res.data.data[0];
        })
        .catch(() => []);
};

export const clearSearchedMovies = () => async dispatch => {
    dispatch({
        type: 'CLEAR_SEARCHED_MOVIES',
        payload: {},
    });
};

export const getSearchedMovies = ( search, userId, version ) => async dispatch => {
    const res = await getSearchedMoviesRequest( search, userId, version );

    dispatch({
        type: 'SEARCHED_MOVIES',
        payload: {
            searchedMovies: res.movies,
            searchedMoviesDisplay: res.display,
        }
    });
};

export const clearSpecificLastFocus = type => async dispatch => {
    dispatch({
        type: 'CLEAR_SPECIFIC_LAST_FOCUS',
        payload: { type },
    });
};

export const setSpecificLastFocus = ( type, position ) => async dispatch => {
    dispatch({
        type: 'SET_SPECIFIC_LAST_FOCUS',
        payload: { type, position },
    });
};

export const setThemeActive = theme => async dispatch => {
    dispatch({
        type: 'SET_THEME',
        payload: theme
    });
};

const updateProgressMovieRequest = async ( userId, version, movieId, currentTime, totalTime, device ) => {
    const params = new URLSearchParams();
    params.append('device', device);
    params.append('movieId', movieId);
    params.append('NoCli', userId);
    params.append('progressTime', currentTime);
    params.append('timeTotal', totalTime);
    params.append('version', version);

    return ApiService.post('movies/updateProgressMovies.php', params)
        .then( response => {
            if ( ! response.data.ret ) throw new Error();
            return true;
        })
        .catch(err => false);
};

export const updateProgressMovie = (userId, version, movie, currentTime, totalTime, device) => async dispatch => {
    const res = await updateProgressMovieRequest(
        userId,
        version,
        movie.transaction,
        currentTime,
        totalTime,
        device
    );

    if ( ! res ) return;

    dispatch({
        type: 'UPDATE_STORE_PROGRESS_MOVIE',
        payload: {
            movie,
            currentTime,
            totalTime,
        }
    });
};