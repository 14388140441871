import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'hooks/useAuth';

import AvatarItem from 'components/AvatarItem';
import Button, { SIZE_BIG as BUTTON_BIG, SIZE_ROUND as BUTTON_ROUND, STYLE_OUTLINE as BUTTON_OUTLINE } from 'components/Button';
import Image from 'components/Image';
import { HorizontalList } from 'components/Navigation';
import SvgIcon from 'components/SvgIcon';
import Text, { ALIGN_CENTER, COLOR_GREY, SIZE_SMALL as TEXT_SMALL } from 'components/Text';
import Title, { LEVEL_1, LEVEL_4 } from 'components/Title';

import { getApiAdminUrl } from 'api/ApiManager';
import { ReactComponent as CSA10 } from 'assets/icons/icon-csa-10.svg';
import { ReactComponent as CSA12 } from 'assets/icons/icon-csa-12.svg';
import { ReactComponent as CSA16 } from 'assets/icons/icon-csa-16.svg';
import { ReactComponent as CSA18 } from 'assets/icons/icon-csa-18.svg';
import { ReactComponent as CSAAll } from 'assets/icons/icon-csa-all.svg';
import { ReactComponent as DefHD } from 'assets/icons/icon-hd.svg';
import { ReactComponent as DefUHD } from 'assets/icons/icon-4k.svg';
import { ReactComponent as Def43 } from 'assets/icons/icon-4-3.svg';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconPlay } from 'assets/icons/icon-play.svg';
import { ReactComponent as IconThumbDown } from 'assets/icons/icon-thumb-down.svg';
import { ReactComponent as IconThumbDownFilled } from 'assets/icons/icon-thumb-down-filled.svg';
import { ReactComponent as IconThumbUp } from 'assets/icons/icon-thumb-up.svg';
import { ReactComponent as IconThumbUpFilled } from 'assets/icons/icon-thumb-up-filled.svg';
import { addMovieToFavorites, getFullCatalogNew, setMovie } from 'redux/actions/CatalogActions';
import { goTo, PATH_PLAY } from 'utils/NavigationUtils';
import { THUMB_ADD_DOWN, THUMB_ADD_UP, THUMB_DOWN, THUMB_REMOVE_DOWN, THUMB_REMOVE_UP, THUMB_UP } from 'utils/ThumbsUtils';

// TODO
// add casting
// if progress: nb episodes, localization

const DetailsInformation = ({ navigation, movie, isVisible, setVisible }) => {
    const { profileVersion, updateThumbs, user, thumbsDown, thumbsUp } = useAuth();
    const dispatch = useDispatch();
    const history = useHistory();
    const { FilmID: filmId } = useParams();

    const { rowGroups, themes, themeActive } = useSelector(state => state.catalog);
    const [isInFavorite, setIsInFavorite] = useState(false);
    const [isThumbDown, setIsThumbDown] = useState(false);
    const [isThumbUp, setIsThumbUp] = useState(false);

    const infosRef = createRef();

    useEffect( () => {
        if ( ! rowGroups.favorites ) {
            dispatch(getFullCatalogNew(themes[themeActive], user.NoCli, profileVersion));
        } else {
            let isInFav = false;
            for (const movie of rowGroups.favorites[0].movies) {
                if (movie.transaction === filmId) {
                    isInFav = true;
                    break;
                }
            }
            setIsInFavorite(isInFav);
        }
    }, [dispatch, filmId, profileVersion, rowGroups, themes, themeActive, user.NoCli]);

    useEffect( () => {
        if ( movie && movie.transaction ) {
            setIsThumbDown( thumbsDown.includes( movie.transaction ) );
            setIsThumbUp( thumbsUp.includes( movie.transaction ) );
        }
    }, [movie, thumbsDown, thumbsUp]);

    const episodeToStart = useMemo( () => {
        if ( ! movie?.episodeToStartIndexes ) return null;
        const { seasonIndex, episodeIndex } = movie.episodeToStartIndexes;
        const episode = movie.seasons[seasonIndex].episodes[episodeIndex];

        return {
            ...episode,
            seasonIndex,
            episodeIndex,
            timePercentage: Math.floor((+episode.time * 100) / +episode.timeTotal)
        };
    }, [movie]);

    // const episodePosition = useMemo(() => {
    //     if (!movie?.episodeToStartIndexes) return null;
    //     const { seasonIndex, episodeIndex } = movie.episodeToStartIndexes;
    //     return {
    //         ...moviePosition,
    //         seasonIndex,
    //         episodeIndex
    //     };
    // }, [movie, moviePosition]);

    const calcDisplayTimeLeft = useCallback( () => {
        if (!episodeToStart) return null;

        const { timeTotal, time } = episodeToStart;
        let secondsLeft = timeTotal - time;
        let minutesLeft = 0;
        let hoursLeft = 0;

        if (secondsLeft >= 60) {
            minutesLeft = Math.floor(secondsLeft / 60);
            secondsLeft = secondsLeft % 60;
        }

        if (minutesLeft >= 60) {
            hoursLeft = Math.floor(minutesLeft / 60);
            minutesLeft = minutesLeft % 60;
        }

        if (hoursLeft > 0 || minutesLeft > 0) {
            return (
                (hoursLeft > 0 ? `${hoursLeft} h ` : '') +
                (minutesLeft > 0 ? `${minutesLeft} min` : '') +
                ' restantes'
            );
        }
    }, [episodeToStart]);

    const CsaIcon = useMemo( () => {
        switch (movie?.csa) {
            case 'csa10':
                return CSA10;
            case 'csa12':
                return CSA12;
            case 'csa16':
                return CSA16;
            case 'csa18':
                return CSA18;
            case 'csaAll':
                return CSAAll;
            default:
                return null;
        }
    }, [movie]);

    const DefinitionIcon = useMemo( () => {
        switch (movie?.definition) {
            case 'high-definition':
                return DefHD;
            case '4k':
                return DefUHD;
            case '4-3':
                return Def43;
            default:
                return null;
        }
    }, [movie]);

    const titlePng = useMemo( () =>
        movie?.titlePngFileName
            ? `${getApiAdminUrl}cimage/webroot/img.php?src=../../../DATA/TMP/${movie.titlePngFileName}.png&h=160&crop-to-fit`
            : ''
    , [movie]);

    const getRoles = role => {
        switch ( role ) {
            case 'actor': return 'Acteur';
            case 'producer': return 'Producteur';
            case 'director': return 'Réalisateur';
            default : return 'Acteur';
        }
    };

    const favoriteBtnAction = isInFavorite => {
        dispatch(addMovieToFavorites(isInFavorite, movie, user.NoCli, profileVersion));
    };

    const onFocusBtnHandler = e => {
        if ( ! isVisible ) setVisible(true);
    };

    const seriesPlayBtnAction = () => {
        if (!episodeToStart?.transaction) return;

        const { seasonIndex, episodeIndex } = movie.episodeToStartIndexes;
        const nextEpisodes = movie.seasons[seasonIndex].episodes.slice(episodeIndex + 1);

        dispatch(setMovie(episodeToStart, nextEpisodes));
        goTo(history, PATH_PLAY + '/' + episodeToStart.transaction);
    };

    const thumbBtnAction = type => {
        if ( movie.transaction ) {
            const movieId = movie.transaction;
            let action = '';

            if ( type === THUMB_DOWN ) {
                if ( isThumbDown ) {
                    action = THUMB_REMOVE_DOWN;
                    setIsThumbDown(false);
                } else {
                    action = THUMB_ADD_DOWN;
                    setIsThumbDown(true);
                    setIsThumbUp(false);
                }
            } else if ( type === THUMB_UP ) {
                if ( isThumbUp ) {
                    action = THUMB_REMOVE_UP;
                    setIsThumbUp(false);
                } else {
                    action = THUMB_ADD_UP;
                    setIsThumbDown(false);
                    setIsThumbUp(true);
                }
            }

            if ( action ) updateThumbs({ action, movieId, userId: user.NoCli, version: profileVersion });
        }
    };

    return (
        <div className="details__infos" ref={infosRef}>
            {movie &&
                (titlePng ? (
                    <Image
                        className="details__infos__title-img"
                        url={titlePng}
                        onError={e => (e.target.style.display = 'none')}
                    />
                ) : (
                    <Title level={LEVEL_1}>{movie.titre}</Title>
                ))}
            <HorizontalList className="details__infos__btns">
                {movie?.type === 'series' ? (
                    <Button
                        parentOnFocusHandler={onFocusBtnHandler}
                        navigation={navigation}
                        size={BUTTON_BIG}
                        style={BUTTON_OUTLINE}
                        focusOnLoad={true}
                        url={seriesPlayBtnAction}
                        history={history}
                    >
                        <SvgIcon icon={IconPlay} height="2.41vh" width="2.41vh" />
                        <Text>
                            {episodeToStart?.time <= 0 || !episodeToStart?.timeTotal
                                ? 'Lecture'
                                : 'Reprendre'}
                        </Text>
                    </Button>
                ) : (
                    <Button
                        parentOnFocusHandler={onFocusBtnHandler}
                        navigation={navigation}
                        size={BUTTON_BIG}
                        style={BUTTON_OUTLINE}
                        focusOnLoad={true}
                        url={PATH_PLAY + '/' + filmId}
                        history={history}
                    >
                        <SvgIcon icon={IconPlay} height="2.41vh" width="2.41vh" />
                        <Text>Lecture</Text>
                    </Button>
                )}
                {movie?.transaction && (
                    <>
                        {/* TODO PATH_PLAY add trailer to path or pass video filename in path params ?  */}
                        {movie?.trailer && (
                            <Button
                                parentOnFocusHandler={onFocusBtnHandler}
                                navigation={navigation}
                                size={BUTTON_BIG}
                                style={BUTTON_OUTLINE}
                                url={`${PATH_PLAY}/${filmId}?type=trailer`}
                                history={history}
                            >
                                <Text>Bande annonce</Text>
                            </Button>
                        )}
                        <Button
                            parentOnFocusHandler={onFocusBtnHandler}
                            navigation={navigation}
                            size={BUTTON_ROUND}
                            style={BUTTON_OUTLINE}
                            url={() => favoriteBtnAction(isInFavorite)}
                            history={history}
                        >
                            {
                                isInFavorite ?
                                <SvgIcon icon={IconCheck} height="2.9vh" width="2.9vh" />
                                :
                                <SvgIcon icon={IconAdd} height="2.9vh" width="2.9vh" />
                            }
                        </Button>
                        <Button
                            parentOnFocusHandler={onFocusBtnHandler}
                            navigation={navigation}
                            size={BUTTON_ROUND}
                            style={BUTTON_OUTLINE}
                            url={() => thumbBtnAction(THUMB_UP)}
                            history={history}
                        >
                            {
                                isThumbUp ?
                                <SvgIcon icon={IconThumbUpFilled} height="2.9vh" width="2.9vh" />
                                :
                                <SvgIcon icon={IconThumbUp} height="2.9vh" width="2.9vh" />
                            }
                        </Button>
                        <Button
                            parentOnFocusHandler={onFocusBtnHandler}
                            navigation={navigation}
                            size={BUTTON_ROUND}
                            style={BUTTON_OUTLINE}
                            url={() => thumbBtnAction(THUMB_DOWN)}
                            history={history}
                        >
                            {
                                isThumbDown ?
                                <SvgIcon icon={IconThumbDownFilled} height="2.9vh" width="2.9vh" />
                                :
                                <SvgIcon icon={IconThumbDown} height="2.9vh" width="2.9vh" />
                            }
                        </Button>
                    </>
                )}
            </HorizontalList>
            {movie && (
                <>
                    {(episodeToStart?.episodeIndex > 0 || episodeToStart?.seasonIndex > 0) && (
                        <div className="details__infos__progress">
                            <Title level={LEVEL_4}>{episodeToStart.titre}</Title>
                            {episodeToStart?.time > 0 && episodeToStart?.timeTotal && (
                                <div className="details__infos__progress__bar">
                                    <span className="details__infos__progress__bar--bar">
                                        <span
                                            className="details__infos__progress__bar--progression"
                                            style={{
                                                width: `${episodeToStart.timePercentage}%`
                                            }}
                                        />
                                    </span>
                                    <Text size={TEXT_SMALL}>{calcDisplayTimeLeft()}</Text>
                                </div>
                            )}
                        </div>
                    )}
                    {movie?.categories?.length > 0 && (
                        <div className="details__infos__categories">
                            {movie.categories.map((category, i) => (
                                <Text size={TEXT_SMALL} key={i}>
                                    {category.name}
                                </Text>
                            ))}
                        </div>
                    )}
                    <Text className="details__infos__synopsis">{movie.synopsis_txt}</Text>
                    <div className="details__infos__meta">
                        {movie.shootingDate && <Text size={TEXT_SMALL}>{movie.shootingDate}</Text>}
                        {CsaIcon && <SvgIcon icon={CsaIcon} height="1.85vh" />}
                        {DefinitionIcon && <SvgIcon icon={DefinitionIcon} height="1.85vh" />}
                    </div>
                    {
                        movie.distribution && movie.distribution.length > 0 &&
                        <div className="channel-page-line-distribution-box">
                            {
                                movie.distribution.map((el, i) =>
                                    <div key={i}>
                                        <AvatarItem
                                            alt={el.title}
                                            avatar={el.imagePath}
                                            className='channel-main-movie-distribution'
                                            navigation={navigation}
                                            notFocusable={true}
                                        />
                                        <Text align={ALIGN_CENTER} size={TEXT_SMALL}>
                                            {el.title}
                                        </Text>
                                        <Text align={ALIGN_CENTER} color={COLOR_GREY} size={TEXT_SMALL}>
                                            {getRoles(el.role)}
                                        </Text>
                                    </div>
                                )
                            }
                        </div>
                    }
                </>
            )}
        </div>
    );
};

DetailsInformation.propTypes = {
    isVisible: PropTypes.bool,
    movie: PropTypes.object,
    navigation: PropTypes.object,
    setVisible: PropTypes.func,
};

DetailsInformation.defaultProps = {};

export default DetailsInformation;
