import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';

import { AuthContext } from 'contexts';

import ChannelProgramColumn from 'components/ChannelProgramColumn';
import Navigation, { VerticalList, HorizontalList, Focusable } from 'components/Navigation';
import Sidebar from 'components/SideBar';
import SvgIcon from 'components/SvgIcon';
import Text, { ALIGN_CENTER, SIZE_LARGE, SIZE_SMALL } from 'components/Text';

import { ReactComponent as ChevronLeft } from 'assets/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/icon-chevron-right.svg';
import { clearSpecificLastFocus, getChannelsProgram, setChannelsProgramDay } from 'redux/actions/CatalogActions';
import { goBack, isReturnEvent, LAST_FOCUS_CHANNELS_PROGRAM } from 'utils/NavigationUtils';

class ChannelsProgram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: null,
            actualIndex: null,
            currentChannelMoviesList: [],
            currentDayIntFocused: null,
            currentDayIntSelected: null,
            daysList: {},
            daysListToShow: {},
            isChannelProgramDaysInitialized: false,
            isCurrentDayLoaded: false,
            isNavigationLoaded: false,
            mouseFocus: false,
            showLeftArrow: false,
            showRightArrow: false,
        };

        this.itemFocusable = React.createRef();
        this.navigation = React.createRef();
        this.sidebar = React.createRef();

        this.handleDayClick = this.handleDayClick.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);
        this.refDayItem = this.refDayItem.bind(this);
        this.refSidebar = this.refSidebar.bind(this);
        this.setCurrentDayIntFocused = this.setCurrentDayIntFocused.bind(this);
        this.setItemActive = this.setItemActive.bind(this);

        this.daysItemFocusableIds = {};
        this.focusDayTimeout = null;
        this.focusSidebarTimeout = null;
    }

    componentDidMount() {
        const { channelsProgramList, sidebarExit } = this.props;

        if ( channelsProgramList.length < 1 ) {
            this.props.getChannelsProgram();
        } else {
            this.updateChannelsProgramDisplay(false);
        }
        
        if ( sidebarExit && this.sidebar.menu.quit.item.focusableId ) this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
        window.addEventListener('keydown', this.keyDownListener);
    };

    componentDidUpdate( prevProps, prevState ) {
        const { channelsProgramList } = this.props;
        const { currentDayIntFocused, isCurrentDayLoaded } = this.state;

        if ( prevProps.channelsProgramList !== channelsProgramList ) this.updateChannelsProgramDisplay(false);
        if ( prevState.isCurrentDayLoaded !== isCurrentDayLoaded && ! isCurrentDayLoaded ) this.updateChannelsProgramDisplay(true);
        if ( prevState.currentDayIntFocused !== currentDayIntFocused ) this.updateChannelsProgramDisplay(true);
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
        clearTimeout( this.focusDayTimeout );
        clearTimeout( this.focusSidebarTimeout );
    };

    shouldComponentUpdate( nextProps, nextState, nextContext ) {
        if ( this.props.match.params.action === 'quit' && ! nextProps.match.params.action ) return false;
        return true;
    };

    getDayClasses( dayInt ) {
        const { currentDayIntFocused, currentDayIntSelected } = this.state;
        return `channels-program-days-box ${ currentDayIntFocused === dayInt ? 'focused' : '' } ${ currentDayIntSelected === dayInt ? 'selected' : '' }`;
    };

    handleDayClick( dayInt ) {
        if ( this.daysItemFocusableIds[ dayInt ] ) this.navigation.retainChannelProgramDayId( this.daysItemFocusableIds[ dayInt ] );
        this.focusDayTimeout = setTimeout(() => {
            if ( this.navigation ) this.navigation.forceFocusChannelProgramMainAvatar();
        }, 1200);
        this.props.clearSpecificLastFocus( LAST_FOCUS_CHANNELS_PROGRAM );
        this.props.setChannelsProgramDay( dayInt );
        this.setState({ currentDayIntSelected: dayInt, isCurrentDayLoaded: false });
    };

    keyDownListener(evnt) {
        if ( isReturnEvent(evnt) ) if ( isReturnEvent(evnt) ) goBack(this.props.history);;
    };

    refDayItem(el, dayInt) {
        const { channelsProgramDay, channelsProgramList } = this.props;
        const { isChannelProgramDaysInitialized } = this.state;

        if ( el && el.focusableId && ! this.daysItemFocusableIds[ dayInt ] ) this.daysItemFocusableIds[ dayInt ] = el.focusableId;
        if ( ! isChannelProgramDaysInitialized ) {
            if ( channelsProgramDay ) {
                if ( channelsProgramDay === dayInt ) {
                    if ( el ) this.itemFocusable = el;
                    if ( this.navigation && this.itemFocusable && this.itemFocusable.focusableId ) {
                        this.setState({ isChannelProgramDaysInitialized: true });
                        this.navigation.retainChannelProgramDayId( this.daysItemFocusableIds[ channelsProgramDay ] );
                    }
                }
            } else if ( channelsProgramList[0]?.info?.currentDay === dayInt ) {
                if ( el ) this.itemFocusable = el;
                if ( this.navigation && this.itemFocusable && this.itemFocusable.focusableId ) {
                    this.setState({ isChannelProgramDaysInitialized: true });
                    this.navigation.retainChannelProgramDayId( this.itemFocusable.focusableId );
                }
            }
        }
    };

    refSidebar(el) {
        if ( el ) this.sidebar = el;
        this.focusSidebarTimeout = setTimeout(() => {
            if ( this.navigation && this.sidebar?.menu?.monz3?.item?.focusableId ) {
                if ( this.sidebar?.menu?.monz3?.item?.focusableId ) this.navigation.retainSideBarMainId( this.sidebar.menu.monz3.item.focusableId );
                Object.keys( this.sidebar.menu ).forEach( el_ => {
                    if ( this.sidebar.menu[el_]?.item?.focusableId ) this.navigation.retainSideBarIds( this.sidebar.menu[el_].item?.focusableId );
                });
            }
        }, 500);
    };

    setActualIndex(index) {
        this.setState({ actualIndex: index });
    };

    setCurrentDayIntFocused(active) {
        this.setState({ currentDayIntFocused: active });
    };

    setItemActive(index = null) {
        this.setState({ activeItem: index });
    };

    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    };

    updateChannelsProgramDisplay( isInitialized ) {
        const { channelsProgramDay, channelsProgramList } = this.props;
        const { currentDayIntFocused, currentDayIntSelected } = this.state;

        if ( channelsProgramList[0]?.info?.currentDay ) {
            const currentDay = isInitialized ? currentDayIntSelected : ( channelsProgramDay ? channelsProgramDay : channelsProgramList[0].info.currentDay );

            let daysList = {};
            let currentChannelMoviesList = [];
            channelsProgramList.forEach( ( item, index ) => {
                item.moviesList.forEach( ( el, i ) => {
                    if ( el ) {
                        const dateFull = new Intl.DateTimeFormat( 'fr', { dateStyle: 'full' } ).format(el.startAt * 1000);
                        const dateFullArr = dateFull.split( ' ' );
                        channelsProgramList[index].moviesList[i].dayInt = dateFullArr[ 1 ];
                        if ( dateFull && ! Object.keys( daysList ).includes( dateFull ) ) {
                            daysList[ dateFull ] = { dayStg: dateFullArr[ 0 ], dayInt: dateFullArr[ 1 ], monthStg: dateFullArr[ 2 ] };
                        }
                    }
                });
                currentChannelMoviesList.push( channelsProgramList[index].moviesList.filter( item => item.dayInt === currentDay ) );
            });

            const currentDay_ = currentDayIntFocused ? currentDayIntFocused : currentDay;
            const keys = Object.keys( daysList );
            const values = Object.values( daysList );
            let indexOf = null;
            for ( let i = 0; i < values.length; i++ ) {
                if ( values[ i ].dayInt === currentDay_ ) { indexOf = i; break; }
            }

            const daysListTreated = {};
            let showLeftArrow = false;
            let showRightArrow = false;
            if ( ( indexOf || indexOf === 0 ) && values.length > 6 ) {
                if ( indexOf === 0 ) {
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    daysListTreated[ keys[ indexOf + 1 ] ] = values[ indexOf + 1 ];
                    daysListTreated[ keys[ indexOf + 2 ] ] = values[ indexOf + 2 ];
                    daysListTreated[ keys[ indexOf + 3 ] ] = values[ indexOf + 3 ];
                    daysListTreated[ keys[ indexOf + 4 ] ] = values[ indexOf + 4 ];
                    daysListTreated[ keys[ indexOf + 5 ] ] = values[ indexOf + 5 ];
                    daysListTreated[ keys[ indexOf + 6 ] ] = values[ indexOf + 6 ];
                    showRightArrow = true;
                } else if ( indexOf === 1 ) {
                    daysListTreated[ keys[ indexOf - 1 ] ] = values[ indexOf - 1 ];
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    daysListTreated[ keys[ indexOf + 1 ] ] = values[ indexOf + 1 ];
                    daysListTreated[ keys[ indexOf + 2 ] ] = values[ indexOf + 2 ];
                    daysListTreated[ keys[ indexOf + 3 ] ] = values[ indexOf + 3 ];
                    daysListTreated[ keys[ indexOf + 4 ] ] = values[ indexOf + 4 ];
                    daysListTreated[ keys[ indexOf + 5 ] ] = values[ indexOf + 5 ];
                    showRightArrow = true;
                } else if ( indexOf === 2 ) {
                    daysListTreated[ keys[ indexOf - 2 ] ] = values[ indexOf - 2 ];
                    daysListTreated[ keys[ indexOf - 1 ] ] = values[ indexOf - 1 ];
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    daysListTreated[ keys[ indexOf + 1 ] ] = values[ indexOf + 1 ];
                    daysListTreated[ keys[ indexOf + 2 ] ] = values[ indexOf + 2 ];
                    daysListTreated[ keys[ indexOf + 3 ] ] = values[ indexOf + 3 ];
                    daysListTreated[ keys[ indexOf + 4 ] ] = values[ indexOf + 4 ];
                    showRightArrow = true;
                } else if ( indexOf > 2 && indexOf < values.length - 4 ) {
                    daysListTreated[ keys[ indexOf - 3 ] ] = values[ indexOf - 3 ];
                    daysListTreated[ keys[ indexOf - 2 ] ] = values[ indexOf - 2 ];
                    daysListTreated[ keys[ indexOf - 1 ] ] = values[ indexOf - 1 ];
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    daysListTreated[ keys[ indexOf + 1 ] ] = values[ indexOf + 1 ];
                    daysListTreated[ keys[ indexOf + 2 ] ] = values[ indexOf + 2 ];
                    daysListTreated[ keys[ indexOf + 3 ] ] = values[ indexOf + 3 ];
                    showLeftArrow = true;
                    showRightArrow = true;
                } else if ( indexOf === values.length - 4 ) {
                    daysListTreated[ keys[ indexOf - 4 ] ] = values[ indexOf - 4 ];
                    daysListTreated[ keys[ indexOf - 3 ] ] = values[ indexOf - 3 ];
                    daysListTreated[ keys[ indexOf - 2 ] ] = values[ indexOf - 2 ];
                    daysListTreated[ keys[ indexOf - 1 ] ] = values[ indexOf - 1 ];
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    daysListTreated[ keys[ indexOf + 1 ] ] = values[ indexOf + 1 ];
                    daysListTreated[ keys[ indexOf + 2 ] ] = values[ indexOf + 2 ];
                    showLeftArrow = true;
                } else if ( indexOf === values.length - 3 ) {
                    daysListTreated[ keys[ indexOf - 5 ] ] = values[ indexOf - 5 ];
                    daysListTreated[ keys[ indexOf - 4 ] ] = values[ indexOf - 4 ];
                    daysListTreated[ keys[ indexOf - 3 ] ] = values[ indexOf - 3 ];
                    daysListTreated[ keys[ indexOf - 2 ] ] = values[ indexOf - 2 ];
                    daysListTreated[ keys[ indexOf - 1 ] ] = values[ indexOf - 1 ];
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    daysListTreated[ keys[ indexOf + 1 ] ] = values[ indexOf + 1 ];
                    showLeftArrow = true;
                } else if ( indexOf === values.length - 2 ) {
                    daysListTreated[ keys[ indexOf - 6 ] ] = values[ indexOf - 6 ];
                    daysListTreated[ keys[ indexOf - 5 ] ] = values[ indexOf - 5 ];
                    daysListTreated[ keys[ indexOf - 4 ] ] = values[ indexOf - 4 ];
                    daysListTreated[ keys[ indexOf - 3 ] ] = values[ indexOf - 3 ];
                    daysListTreated[ keys[ indexOf - 2 ] ] = values[ indexOf - 2 ];
                    daysListTreated[ keys[ indexOf - 1 ] ] = values[ indexOf - 1 ];
                    daysListTreated[ keys[ indexOf ] ] = values[ indexOf ];
                    showLeftArrow = true;
                }
            }

        if ( isInitialized ) {
            this.setState({
                currentChannelMoviesList: currentChannelMoviesList,
                daysList: daysList,
                daysListToShow: daysListTreated,
                isCurrentDayLoaded: true,
                showLeftArrow: showLeftArrow,
                showRightArrow: showRightArrow,
            });
        } else {
            this.setState({
                currentDayIntSelected: currentDay,
                currentChannelMoviesList: currentChannelMoviesList,
                daysList: daysList,
                daysListToShow: daysListTreated,
                isCurrentDayLoaded: true,
                showLeftArrow: showLeftArrow,
                showRightArrow: showRightArrow,
            });
        }
    }
    };

    render() {
        const { channelsProgramList, history } = this.props;
        const { currentChannelMoviesList, daysList, daysListToShow, isCurrentDayLoaded, isNavigationLoaded, showLeftArrow, showRightArrow } = this.state;

        const daysListKeys = Object.keys( daysList );

        return (
            <Navigation specialChannelProgramPage={true} ref={el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <div className="category-page">
                    <HorizontalList>
                        <Sidebar
                            ref={el_ => this.refSidebar(el_)}
                            history={history}
                            navigation={this.navigation}
                            parent={this}
                        />
                        <VerticalList>
                            {
                                isNavigationLoaded &&
                                <>
                                    <HorizontalList className='channels-program-title-box'>
                                        <div className={ showLeftArrow ? '' : 'channels-program-days-arrow-hidden' }>
                                            <SvgIcon icon={ChevronLeft} height="2vw" width="2vw" />
                                        </div>    
                                        {
                                            daysListKeys && daysListKeys.length &&
                                            daysListKeys
                                                .filter( el => daysListKeys.indexOf( el ) !== daysListKeys.length - 1 )
                                                .map( ( el, i ) =>
                                                <div key={i} className={ daysListToShow[el] && daysListToShow[el].dayInt ? '' : 'channels-program-days-item-hidden' }>
                                                    <Focusable
                                                        ref={el_ => this.refDayItem( el_, daysList[ el ].dayInt )}
                                                        className={ this.getDayClasses( daysList[ el ].dayInt )}
                                                        onClick={() => this.handleDayClick( daysList[ el ].dayInt )}
                                                        onEnterDown={() => this.handleDayClick( daysList[ el ].dayInt, i )}
                                                        onFocus={() => this.setState({ currentDayIntFocused: daysList[ el ].dayInt })}
                                                        onMouseEnter={() => this.setState({ currentDayIntFocused: daysList[ el ].dayInt })}
                                                    >
                                                        <Text align={ALIGN_CENTER} className="channels-program-days-item" size={SIZE_SMALL}>
                                                            { daysList[ el ].dayStg }
                                                        </Text>
                                                        <Text align={ALIGN_CENTER} className="channels-program-days-item" size={SIZE_LARGE}>
                                                            { daysList[ el ].dayInt }
                                                        </Text>
                                                        <Text align={ALIGN_CENTER} className="channels-program-days-item" size={SIZE_SMALL}>
                                                            { daysList[ el ].monthStg }
                                                        </Text>
                                                    </Focusable>
                                                </div>
                                            )
                                        }
                                        <div className={ showRightArrow ? '' : 'channels-program-days-arrow-hidden' }>
                                            <SvgIcon icon={ChevronRight} height="2vw" width="2vw" />
                                        </div>
                                    </HorizontalList>
                                    <HorizontalList className="channels-program-box">
                                        {
                                            currentChannelMoviesList && currentChannelMoviesList.length > 0 &&
                                            currentChannelMoviesList.map( ( el, index ) =>
                                                <ChannelProgramColumn
                                                    key={index}
                                                    channelIndex={index}
                                                    history={history}
                                                    isLoaded={isCurrentDayLoaded}
                                                    isNavigationLoaded={isNavigationLoaded}
                                                    moviesInfo={channelsProgramList[index].info}
                                                    moviesList={el}
                                                    navigation={this.navigation}
                                                    parent={this}
                                                    setCurrentDayIntFocused={this.setCurrentDayIntFocused}
                                                    setItemActive={this.setItemActive}
                                                />
                                            )
                                        }
                                    </HorizontalList>
                                </>
                            }
                        </VerticalList>
                    </HorizontalList>
                </div>
            </Navigation>
        );
    }
}

ChannelsProgram.contextType = AuthContext;

const stateToProps = state => ({
    channelsProgramDay: state.catalog.channelsProgramDay,
    channelsProgramList: state.catalog.channelsProgramList,
});

const dispatchToProps = dispatch => ({
    clearSpecificLastFocus: type => dispatch(clearSpecificLastFocus(type)),
    getChannelsProgram: () => dispatch(getChannelsProgram()),
    setChannelsProgramDay: currentDay => dispatch(setChannelsProgramDay(currentDay)),
});

export default connect(stateToProps, dispatchToProps)(withLastLocation(ChannelsProgram));