import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navigation, { VerticalList, HorizontalList, Grid } from 'components/Navigation';
import { withLastLocation } from 'react-router-last-location';

import { AuthContext } from 'contexts';
import Sidebar from 'components/SideBar';
import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import ListItem from 'components/ListItem';
import Text, { ALIGN_CENTER } from 'components/Text';
import { clearSearch, getSearch } from 'redux/actions/CatalogActions';
import { goBack, goTo, isReturnEvent, PATH_HOME } from 'utils/NavigationUtils';

class Search extends Component {
    static GRID_COLUMNS = 4;
    static SEARCH_MIN_LEN = 3;
    images = [];
    scrollableDiv;

    constructor(props) {
        super(props);

        this.state = {
            isSearchStarted: false,
            inputActive: false,
            mouseFocus: false,
            actualIndex: null,
            isLoaded: false,
            isNavigationLoaded: false,
            search: '',
            activeItem: null,
        };

        this.inputFocusable = React.createRef();
        this.navigation = React.createRef();
        this.scrollableDiv = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);
        this.updateSearch = this.updateSearch.bind(this);


        this.refInput = this.refInput.bind(this);

        this.firstLineGridElements = [];
        this.gridTimeout = null;
        this.typingTimeout = null;
    }


    componentDidMount() {
        if ( this.props.sidebarExit && this.sidebar.menu.quit.item.focusableId ) this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
        this.setState({ isLoaded: true, search: this.props.searchText ? this.props.searchText : '' });
        window.addEventListener('keydown', this.keyDownListener);
    };

    componentDidUpdate( prevProps, prevState ) {
        const { lastFocusSearchGridIndex } = this.props;
        const { isNavigationLoaded } = this.state;

        if ( prevState.isNavigationLoaded !== isNavigationLoaded && isNavigationLoaded ) {
            this.gridTimeout = setTimeout( () => {
                if ( lastFocusSearchGridIndex || lastFocusSearchGridIndex === 0 ) {
                    this.setInputActive( false );
                    this.navigation.forceLastFocusElementId();
                } else {
                    this.navigation.forceFocusInput();
                }
            }, 300);
        }
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
        clearTimeout(this.gridTimeout);
        clearTimeout(this.typingTimeout);
    };
    
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.match.params.action === 'quit' && !nextProps.match.params.action) return false;
        return true;
    };

    handleInputChange(evnt) {
        if ( this.props.searchText ) this.props.clearSearch();
        this.setState({ isLoaded: false, search: evnt.target.value });
        if ( this.typingTimeout ) clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(this.updateSearch, 1000);  
    };

    keyDownListener(evnt) {
        // if (isReturnEvent(evnt) && this.sidebar && this.navigation && !this.sidebar.state.active) {
        //     this.sidebar.setActive(true);
        //     this.navigation.forceFocus(this.sidebar.menu.quit.item.focusableId);
        //     goTo(this.props.history, PATH_SEARCH, true);
        // }

        if ( isReturnEvent(evnt) ) {
            if ( ! this.state.inputActive && this.inputFocusable?.current?.inputFocusable?.current?.focusableId ) {
                this.navigation.forceFocus( this.inputFocusable.current.inputFocusable.current.focusableId );
            } else {
                goTo( this.props.history, PATH_HOME );
            }
        }
    };

    async search(value) {
        const { profileVersion, user } = this.context;
        this.props.getSearch( value, user.NoCli, profileVersion ).then( () => this.setState({ isLoaded: true }) );
    };

    setActualIndex(index) {
        this.setState({ actualIndex: index });
    };

    setInputActive(active) {
        this.setState({ inputActive: active });
    };

    setItemActive(index = null) {
        this.setState({ activeItem: index });
    };

    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    };

    updateSearch() {
        const { search } = this.state;

        this.setState({ isLoaded: false });

        if ( search && search.length >= Search.SEARCH_MIN_LEN ) {
            this.setState({ activeItem: null});
            this.search( search.trim() );
        }
    };

    refInput(el) {
        if ( el ) this.inputFocusable = el;
        if ( this.navigation && this.inputFocusable.inputFocusable.current.focusableId ) {
            this.navigation.retainInputId(this.inputFocusable.inputFocusable.current.focusableId);
        } 
    };

    render() {
        const { history, lastFocusSearchGridIndex, searchResult } = this.props;
        const { isLoaded, isNavigationLoaded, search } = this.state;

        return (
            <Navigation specialSearchPage={true} ref={el => {
                this.navigation = el;
                if ( ! isNavigationLoaded ) this.setState({ isNavigationLoaded: true });
            }}>
                <div ref={el => (this.searchPage = el)} className="search">
                    <HorizontalList>
                        <Sidebar
                            ref={el => (this.sidebar = el)}
                            history={history}
                            navigation={this.navigation}
                            parent={this}
                        />
                        <VerticalList>
                            {
                                isNavigationLoaded &&
                                <>
                                    <Wrapper>
                                        <Input
                                            ref={el => this.refInput(el)}
                                            type="text"
                                            label="search"
                                            name="search"
                                            navigation={this.navigation}
                                            onChange={this.handleInputChange}
                                            onFocus
                                            focusOnLoad={true}
                                            autoFocus={!lastFocusSearchGridIndex && lastFocusSearchGridIndex !== 0}
                                            withLabel={false}
                                            value={search}
                                            parent={this}
                                            autoComplete="off"
                                        />
                                    </Wrapper>
                                    <div className="search__content" ref={this.scrollableDiv}>
                                        {
                                            ( search?.length < 3 || ! isLoaded ) &&
                                            <Text align={ALIGN_CENTER}>
                                                Tape un/des mots clefs correspondant aux film que tu
                                                recherches.
                                                <br />
                                                Tu peux rechercher directement le titre d'un film, un
                                                lieu, un type de gibier/poisson, une technique, un
                                                personnage...
                                            </Text>
                                        }
                                        {
                                            isLoaded && searchResult?.length <= 0 ?
                                            <Text align={ALIGN_CENTER}>Aucun résultat</Text>
                                            :
                                            <Grid
                                                ref={el => (this.grid = el)}
                                                columns={Search.GRID_COLUMNS}
                                                rows={Math.ceil( searchResult.length / Search.GRID_COLUMNS )}
                                            >
                                                {
                                                    searchResult.map((item, i) => {
                                                        return (
                                                            <ListItem
                                                                key={i}
                                                                categoryIndex={`0-${i}`}
                                                                goBack={() => goBack(history)}
                                                                isFirstLineGrid={Search.GRID_COLUMNS > i}
                                                                isFirstLineGridElement={i === 0}
                                                                isLastFocusElement={i === lastFocusSearchGridIndex}
                                                                item={item}
                                                                itemIndex={i}
                                                                lazyLoadingShift={20}
                                                                navigation={this.navigation}
                                                                parentCategory={this}
                                                                parentList={this}
                                                                redirectTo={url => goTo(history, url)}
                                                                retainLastFocusSearch
                                                                setThisItemActive={() => this.setItemActive(i)}
                                                                verticalAutoScroll
                                                                vGap={40}
                                                                viewport={this.scrollableDiv.current}
                                                            />
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        }
                                    </div>
                                </>
                            }
                        </VerticalList>
                    </HorizontalList>
                </div>
            </Navigation>
        );
    }
}

Search.contextType = AuthContext;

const stateToProps = state => ({
    lastFocusSearchGridIndex: state.catalog.lastFocusSearchGridIndex,
    searchText: state.catalog.searchText,
    searchResult: state.catalog.searchResult,
});

const dispatchToProps = dispatch => ({
    clearSearch: () => dispatch(clearSearch()),
    getSearch: (search, userId, version) => dispatch(getSearch(search, userId, version)),
});

export default connect(stateToProps, dispatchToProps)(withLastLocation(Search));