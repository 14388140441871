import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VerticalList from './VerticalList.jsx';

// const reverseDirection = {
//     up: 'down',
//     down: 'up',
//     left: 'right',
//     right: 'left'
// };

/*
This component listen the window keys events.
*/

class Navigation extends Component {
    currentFocusedPath = null;
    lastFocusedPath = null;
    lastDirection = null;
    pause = false;
    default = null;
    root = null;
    focusableComponents = {};
    focusableIds = 0;

    onKeyDown = evt => {
        if (this._pause || evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey) {
            return;
        }

        const preventDefault = function () {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        };

        const direction = this.props.keyMapping[evt.keyCode];
        if (!direction) {
            if (evt.keyCode === this.props.keyMapping['enter']) {
                if (this.currentFocusedPath) {
                    if (
                        !this.fireEvent(this.getLastFromPath(this.currentFocusedPath), 'enter-down')
                    ) {
                        return preventDefault();
                    }
                }
            }
            return;
        }

        let currentFocusedPath = this.currentFocusedPath;
        if (!currentFocusedPath || currentFocusedPath.length === 0) {
            currentFocusedPath = this.lastFocusedPath;

            if (!currentFocusedPath || currentFocusedPath.length === 0) {
                //this.focusDefault();
                return preventDefault();
            }
        }

        const currentFocus = this.getLastFromPath(currentFocusedPath);

        // special treatement for search page to fix ghost focus element when updating search results
        if ( this.props.specialSearchPage && currentFocus && currentFocus.focusableId ) {
            if ( currentFocus.focusableId === this.inputId && direction === 'down' && this.firstLineGridElementId ) {
                this.forceFirstLineGridElement();
                return preventDefault();
            } else if ( this.firstLineGridIds.includes( currentFocus.focusableId ) && direction === 'up' && this.inputId ) {
                this.forceFocusInput();
                return preventDefault();
            }
        }

        // special treatement for channelProgram page
        if ( this.props.specialChannelProgramPage && currentFocus && currentFocus.focusableId ) {
            if ( this.channelProgramAvatarIds.includes( currentFocus.focusableId ) ) {
                const indexOfAvatar = this.channelProgramAvatarIds.indexOf( currentFocus.focusableId );

                if ( direction === 'up' ) {
                    this.forceFocusChannelProgramDay();
                    return preventDefault();
                } else if ( direction === 'right' ) {
                    this.forceFocusChannelProgramMovie( indexOfAvatar );
                    return preventDefault();
                } else if ( direction === 'left' ) {
                    if ( this.channelProgramAvatarIds[ indexOfAvatar - 1 ] ) {
                        this.forceFocusChannelProgramAvatar( indexOfAvatar - 1 );
                        return preventDefault();
                    } else {
                        this.forceSideBarMain();
                        return preventDefault();
                    }
                }
            } else if ( this.sideBarIds.includes( currentFocus.focusableId ) ) {
                if ( direction === 'right' ) {
                    this.forceFocusChannelProgramMainAvatar();
                    return preventDefault();
                }
            } else {
                const channelProgramMovieIdsKeys = Object.keys( this.channelProgramMovieIds );
                for ( let i = 0; i < channelProgramMovieIdsKeys.length; i++ ) {
                    if ( this.channelProgramMovieIds[channelProgramMovieIdsKeys[i]].includes( currentFocus.focusableId ) ) {
                        if ( direction === 'right' ) {
                            if ( channelProgramMovieIdsKeys[i+1] ) this.forceFocusChannelProgramAvatar(channelProgramMovieIdsKeys[i+1])
                            return preventDefault();
                        } else if ( direction === 'left' ) {
                            this.forceFocusChannelProgramAvatar(channelProgramMovieIdsKeys[i])
                            return preventDefault();
                        }
                    }
                }
            }
        }

        // end special treatement

        this.focusNext(direction, currentFocusedPath);
        return preventDefault();
    };

    fireEvent(element, evt, evtProps) {
        switch (evt) {
            case 'willmove':
                if (element.props.onWillMove) element.props.onWillMove(evtProps);
                break;
            case 'onfocus':
                element.focus(evtProps);
                break;
            case 'onblur':
                element.blur(evtProps);
                break;
            case 'enter-down':
                if (element.props.onEnterDown) element.props.onEnterDown(evtProps, this);
                break;
            default:
                return false;
        }

        return true;
    }

    focusNext(direction, focusedPath) {
        const next = this.getLastFromPath(focusedPath).getNextFocusFrom(direction);

        if (next) {
            this.lastDirection = direction;
            this.focus(next);
        }
    }

    blur(nextTree) {
        if (this.currentFocusedPath === null) return;

        let changeNode = null;

        for (let i = 0; i < Math.min(nextTree.length, this.currentFocusedPath.length); ++i) {
            if (nextTree[i] !== this.currentFocusedPath[i]) {
                changeNode = i;
                break;
            }
        }

        if (changeNode === null) return;

        for (let i = changeNode; i < this.currentFocusedPath.length; ++i) {
            if (this.currentFocusedPath[i].focusableId === null) {
                continue;
            }

            this.currentFocusedPath[i].blur();

            if (i < this.currentFocusedPath.length - 1) {
                this.currentFocusedPath[i].lastFocusChild =
                    this.currentFocusedPath[i + 1].indexInParent;
            }
        }
    }

    focus(next) {
        if ( next ) {
            this.blur(next.treePath);
            next.focus();

            const lastPath = this.currentFocusedPath;
            this.currentFocusedPath = next.treePath;
            this.lastFocusedPath = lastPath;
        }
    }

    getLastFromPath(path) {
        return path[path.length - 1];
    }

    focusDefault() {
        if (this.default !== null) {
            this.focus(this.default.getDefaultFocus());
        } else {
            this.focus(this.root.getDefaultFocus());
        }
    }

    setDefault(component) {
        this.default = component;
    }

    addComponent(component, id = null) {
        if (this.focusableComponents[id]) {
            return id;
            // throw new Error('Focusable component with id "' + id + '" has already existed!');
        }

        if (!id) {
            id = 'focusable-' + this.focusableIds++;
        }

        this.focusableComponents[id] = component;
        return id;
    }

    forceFocus(focusableId) {
        if (!this.focusableComponents[focusableId]) {
            throw new Error('Focusable component with id "' + focusableId + '" doesn\'t exists!');
        }

        this.focus(this.focusableComponents[focusableId].getDefaultFocus());
    }

    removeFocusableId(focusableId) {
        if (this.focusableComponents[focusableId]) delete this.focusableComponents[focusableId];
    }

    // React Functions
    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);
        this.focusDefault();
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.onKeyUp);
        window.removeEventListener('keydown', this.onKeyDown);
    }

    getChildContext() {
        return { navigationComponent: this };
    }

    getRoot() {
        return this.root;
    }

    // special treatement for search page to fix ghost focus element when updating search results
    inputId = null;
    retainInputId(id) { this.inputId = id; };
    forceFocusInput() { if ( this.inputId ) this.forceFocus( this.inputId ); };

    firstLineGridElementId = null;
    firstLineGridIds = [];
    lastFocusElementId = null;
    retainFirstLineGridElementId(id) { this.firstLineGridElementId = id; }
    retainFirstLineGridId(id) { if( ! this.firstLineGridIds.includes( id ) ) this.firstLineGridIds.push( id ); }
    retainLastFocusElementId(id) { this.lastFocusElementId = id; }
    forceFirstLineGridElement() { if ( this.firstLineGridElementId ) this.forceFocus( this.firstLineGridElementId ); };
    forceLastFocusElementId() { if ( this.lastFocusElementId || this.lastFocusElementId === 0 ) this.forceFocus( this.lastFocusElementId ); };

    // special treatement for channelProgram page
    channelProgramDayId = null;
    retainChannelProgramDayId(id) { this.channelProgramDayId = id; };
    forceFocusChannelProgramDay() { if ( this.channelProgramDayId ) this.forceFocus( this.channelProgramDayId ); };

    channelProgramAvatarIds = [];
    channelProgramMainAvatarId = null;
    retainChannelProgramAvatarIds(id) { this.channelProgramAvatarIds.push( id ); }
    retainChannelProgramMainAvatarId(id) { this.channelProgramMainAvatarId = id; }
    forceFocusChannelProgramAvatar(index) { if ( this.channelProgramAvatarIds && this.channelProgramAvatarIds[index] ) this.forceFocus( this.channelProgramAvatarIds[index] ); };
    forceFocusChannelProgramMainAvatar() { if ( this.channelProgramMainAvatarId ) this.forceFocus( this.channelProgramMainAvatarId ); };

    channelProgramMovieIds = {};
    channelProgramMovieFocusedId = {};
    retainChannelProgramMovieIds(id, index) {
        if ( this.channelProgramMovieIds[index] && this.channelProgramMovieIds[index].length > 0 && ! this.channelProgramMovieIds[index].includes( id ) ) {
            this.channelProgramMovieIds[index].push( id );
        } else {
            this.channelProgramMovieIds[index] = [ id ];
        }
    };
    retainChannelProgramMovieFocusedId(id, index) { this.channelProgramMovieFocusedId[index] = id; };
    forceFocusChannelProgramMovie(index) { if ( this.channelProgramMovieFocusedId[index] ) this.forceFocus( this.channelProgramMovieFocusedId[index] ); };

    sideBarIds = [];
    sideBarMainId = null;
    retainSideBarIds(id) { this.sideBarIds.push( id ); }
    retainSideBarMainId(id) { this.sideBarMainId = id; };
    forceSideBarMain() { if ( this.sideBarMainId ) this.forceFocus( this.sideBarMainId ); };

    // end special treatement

    render() {
        return (
            <VerticalList ref={element => (this.root = element)} focusId="navigation">
                {this.props.children}
            </VerticalList>
        );
    }
}

Navigation.defaultProps = {
    keyMapping: {
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        enter: 13
    }
};

Navigation.childContextTypes = {
    navigationComponent: PropTypes.object
};

export default Navigation;
