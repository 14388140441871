import React, { Component  } from 'react';

import { AuthContext } from 'contexts';

import { Focusable } from 'components/Navigation';
import SvgIcon from 'components/SvgIcon';

import { getImageAvatarUrl } from 'api/ImageManager';
import { ReactComponent as IconPen } from 'assets/icons/Icon-pen.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';

class AvatarItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            isChannelProgramAvatarInitialized: false,
            isFocusInitialized: false,
        };

        this.avatarImage = React.createRef();
        this.itemFocusable = React.createRef();

        this.isInViewport = this.isInViewport.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);

        this.focusTimeout = null;
    };

    componentDidMount() { 
        this._isMounted = true;
    };
      
    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout( this.focusTimeout );
    };

    getClasses( active, className ) {
        const baseClass = 'avatar-item';
        let classes = baseClass;
        classes += active ? ` ${baseClass}--active` : '';
        classes += className ? ` ${baseClass}--${className}` : '';
        return classes;
    };

    isInViewport( horizontal, horizontalGap, vertical, verticalGap ) {
        const image = this.avatarImage;
        if ( ! image ) return false;

        if ( horizontal ) {
            const list = image.parentNode.parentNode.parentNode;

            const itemLeft = image.getBoundingClientRect().left - image.offsetWidth * 0.05;
            const itemRight = image.getBoundingClientRect().right + image.offsetWidth * 0.05;
            const { left, right } = list.getBoundingClientRect();

            if ( itemRight > right ) {
                list.scrollLeft += itemRight - right + horizontalGap;
            } else if ( itemLeft < left ) {
                list.scrollLeft += itemLeft - left;
            }
        }

        if ( vertical ) {
            const { viewport } = this.props;

            const itemTop = image.getBoundingClientRect().top - image.offsetHeight * 0.05;
            const itemBottom = image.getBoundingClientRect().bottom + image.offsetHeight * 0.05;
            const { top, bottom } = viewport.getBoundingClientRect();

            if ( itemBottom > bottom - 200 ) {
                viewport.scrollTop += itemBottom - bottom + verticalGap;
            } else if ( itemTop < top ) {
                viewport.scrollTop += itemTop - top - verticalGap;
            }
        }
    };

    onFocus(index) {
        const { horizontalAutoScroll, hGap, setActualIndex, setChannelFocused, setCurrentDayIntFocused, setMouseFocus, verticalAutoScroll, vGap } = this.props;

        if ( this._isMounted ) this.setState({ active: true });
        this.isInViewport( horizontalAutoScroll, hGap, verticalAutoScroll, vGap );
        if ( setActualIndex ) setActualIndex( index );
        if ( setChannelFocused ) setChannelFocused( true );
        if ( setCurrentDayIntFocused ) setCurrentDayIntFocused( false );
        if ( setMouseFocus ) setMouseFocus( false );
    };

    onBlur() {
        if ( this._isMounted ) this.setState({ active: false });
    };

    onMouseEnter() {
        const { navigation, setMouseFocus } = this.props;

        if ( navigation ) this.props.navigation.forceFocus( this.itemFocusable.focusableId );
        if ( setMouseFocus ) setMouseFocus( true );
    };

    render() {
        const { action, alt, avatar, className, catIndex, editable, focus, icon, isChannelProgramAvatar, isChannelProgramMainAvatar, navigation, notFocusable, selected } = this.props;
        const { active, isChannelProgramAvatarInitialized, isFocusInitialized } = this.state;

        if ( notFocusable ) {
            if ( icon ) {
                return (
                    <SvgIcon icon={icon} className={ 'avatar-item--icon' } />
                )
            }

            return (
                <div className={this.getClasses( active, className )}>
                    <img
                        ref={el => (this.itemFocusable = el)}
                        src={getImageAvatarUrl( avatar )}
                        alt={alt}
                    />
                    { editable && <SvgIcon icon={IconPen} className={ 'avatar-item--icon-pen' } /> }
                    { selected && <SvgIcon icon={IconCheck} className={ 'avatar-item--icon-check' } /> }
                </div>
            )
        }

        if ( icon ) {
            return (
                <Focusable
                    className={this.getClasses( active, className )}
                    ref={el => (this.itemFocusable = el)}
                    onMouseEnter={this.onMouseEnter}
                    onFocus={() => this.onFocus(catIndex)}
                    onBlur={this.onBlur}
                    onClick={action}
                    onEnterDown={action}
                >
                    <SvgIcon icon={icon} className={ 'avatar-item--icon' } />
                </Focusable>
            )
        }

        return (
            <Focusable
                className={this.getClasses( active, className )}
                ref={ el => {
                    this.itemFocusable = el;
                    if ( focus && ! isFocusInitialized ) {
                        this.focusTimeout = setTimeout(() => {
                            this.setState({ isFocusInitialized: true });
                            if ( el && el.focusableId ) navigation.forceFocus( el.focusableId );
                        }, 500);
                    }

                    if ( isChannelProgramAvatar && ! isChannelProgramAvatarInitialized ) {
                        if ( navigation && this.itemFocusable && this.itemFocusable.focusableId ) {
                            this.setState({ isChannelProgramAvatarInitialized: true });
                            navigation.retainChannelProgramAvatarIds( this.itemFocusable.focusableId );
                            if ( isChannelProgramMainAvatar ) navigation.retainChannelProgramMainAvatarId( this.itemFocusable.focusableId );
                        }
                    }
                }}
                onMouseEnter={this.onMouseEnter}
                onFocus={() => this.onFocus(catIndex)}
                onBlur={this.onBlur}
                onClick={action}
                onEnterDown={action}
            >
                <img
                    ref={el => (this.avatarImage = el)}
                    src={getImageAvatarUrl( avatar )}
                    alt={alt}
                />
                { editable && <SvgIcon icon={IconPen} className={ 'avatar-item--icon-pen' } /> }
                { selected && <SvgIcon icon={IconCheck} className={ 'avatar-item--icon-check' } /> }
            </Focusable>
        )
    }
}

AvatarItem.contextType = AuthContext;

export default AvatarItem;