import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { VerticalList } from 'components/Navigation';

import Episode from './Episode';
import Arrow from 'components/Arrow';

const Episodes = ({
    navigation,
    episodes,
    episodeFocusedIndex,
    manageFocus,
    mouseFocus,
    setMouseFocus,
    episodeToFocus,
    setEpisodeToFocus,
    episodeToScrollTo,
    setEpisodeToScrollTo
}) => {
    let episodesListRef = createRef();

    const focusNewElement = direction => {
        setMouseFocus(true);
        const newIndex = episodeFocusedIndex + (direction ? 1 : -1);
        if (newIndex >= 0 && newIndex < episodes.length) {
            setEpisodeToFocus(episodes[newIndex].transaction);
        }
    };

    return (
        <>
            {mouseFocus && episodeFocusedIndex > 0 && (
                <Arrow direction={Arrow.DIRECTION_UP} onClick={() => focusNewElement(false)} />
            )}
            {mouseFocus && episodeFocusedIndex < episodes.length - 1 && (
                <Arrow direction={Arrow.DIRECTION_DOWN} onClick={() => focusNewElement(true)} />
            )}
            <div className="episodes">
                <VerticalList ref={episodesListRef}>
                    {episodes?.map((episode, i) => (
                        <Episode
                            episode={episode}
                            key={i}
                            isFocus={episodeFocusedIndex === i}
                            navigation={navigation}
                            episodesListRef={episodesListRef}
                            setEpisodeFocusedIndex={() => manageFocus(i)}
                            parentSetEpisodeToFocus={setEpisodeToFocus}
                            forceFocus={episode.transaction === episodeToFocus}
                            parentSetEpisodeToScrollTo={setEpisodeToScrollTo}
                            setMouseFocus={setMouseFocus}
                            scrollTo={episode.transaction === episodeToScrollTo}
                        />
                    ))}
                </VerticalList>
            </div>
        </>
    );
};

Episodes.propTypes = {
    episodes: PropTypes.array.isRequired,
    mouseFocus: PropTypes.bool,
    setMouseFocus: PropTypes.func,
    episodeToFocus: PropTypes.string,
    setEpisodeToFocus: PropTypes.func,
    episodeToScrollTo: PropTypes.string,
    setEpisodeToScrollTo: PropTypes.func
};

Episodes.defaultProps = {};

export default Episodes;
