import React, { createContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import { forceDataReload } from 'redux/actions/CatalogActions';
import ApiService from 'services/ApiService';
import { THUMB_ADD_DOWN, THUMB_ADD_UP, THUMB_REMOVE_DOWN, THUMB_REMOVE_UP } from 'utils/ThumbsUtils';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    profileEdit: {},
    profileVersion: 0,
    thumbsDown: [],
    thumbsUp: [],
    user: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_PROFILE_EDIT': {
            const { profileEdit } = action.payload;
            return {
                ...state,
                profileEdit,
            };
        }
        case 'CHANGE_PROFILE_VERSION': {
            const { profileVersion, thumbsDown, thumbsUp } = action.payload;
            return {
                ...state,
                profileVersion,
                thumbsDown,
                thumbsUp,
            };
        }
        case 'INIT': {
            const { isAuthenticated, profileVersion, thumbsDown, thumbsUp, user } = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialized: true,
                profileVersion,
                thumbsDown,
                thumbsUp,
                user,
            };
        }
        case 'LOGIN': {
            const { profileVersion, thumbsDown, thumbsUp, user } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                profileVersion,
                thumbsDown,
                thumbsUp,
                user,
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                profileVersion: 0,
                user: null,
            };
        }
        case 'UPDATE_PROFILE': {
            const { profileVersion, thumbsDown, thumbsUp, user } = action.payload;
            return {
                ...state,
                profileVersion,
                thumbsDown,
                thumbsUp,
                user,
            }
        }
        case 'UPDATE_THUMBS': {
            const { thumbsDown, thumbsUp } = action.payload;
            return {
                ...state,
                thumbsDown,
                thumbsUp,
            }
        }
        default: {
            return { ...state };
        }
    }
};

const ITEM_NO_CLIENT = 'NO_CLIENT';
const ITEM_PROFILE_VERSION = 'ITEM_PROFILE_VERSION';
const LOGIN_TOKEN = 'LOGIN_TOKEN';

const clearSession = () => {
    localStorage.removeItem(ITEM_NO_CLIENT);
    localStorage.removeItem(ITEM_PROFILE_VERSION);
    localStorage.removeItem(LOGIN_TOKEN);
};

const setProfileVersion = profileVersion => {
    localStorage.setItem(ITEM_PROFILE_VERSION, profileVersion);
};

const setSession = user => {
    localStorage.setItem(ITEM_NO_CLIENT, user.NoCli);
    localStorage.setItem(LOGIN_TOKEN, JSON.stringify(user));
};

const AuthContext = createContext({
    ...initialState,
    addProfile: () => Promise.resolve(),
    removeProfile: () => Promise.resolve(),
    updateProfile: () => Promise.resolve(),
    changeProfileEdit: () => {},
    changeProfileVersion: () => {},
    updateThumbs: () => {},
    login: () => Promise.resolve(),
    logout: () => {},
});

const AuthProvider = ({ children }) => {
    const dispatch_ = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect( () => {
        ( async () => {
            try {
                let isAuthenticated = false;
                let profileVersion = localStorage.getItem(ITEM_PROFILE_VERSION) ? JSON.parse(localStorage.getItem(ITEM_PROFILE_VERSION)) : 0;
                let thumbsDown = [];
                let thumbsUp = [];
                let user = JSON.parse(localStorage.getItem(LOGIN_TOKEN));

                if (user && user.NoCli) {
                    // Platform is not accessible if you're not subscribed to the service, so authenticated = subscribed
                    const checkSub = await checkSubscription(user.NoCli);
                    if ( checkSub.result && checkSub.data && checkSub.data.user ) {
                        user = checkSub.data.user;

                        isAuthenticated = true;
                        if ( ! checkSub.profileExists ) {
                            localStorage.removeItem(ITEM_PROFILE_VERSION);
                            profileVersion = 0;
                        }

                        if ( profileVersion === 0 ) {
                            if ( checkSub.data.preferences && checkSub.data.preferences.thumbDown && checkSub.data.preferences.thumbUp ) {
                                if ( checkSub.data.preferences.thumbDown.length > 0 ) thumbsDown = checkSub.data.preferences.thumbDown;
                                if ( checkSub.data.preferences.thumbUp.length > 0 ) thumbsUp = checkSub.data.preferences.thumbUp;
                            }
                        } else if ( profileVersion && user.profiles && user.profiles.length > 0 ) {
                            user.profiles.forEach( el => {
                                if ( el.version === profileVersion ) {
                                    if ( el.preferences && el.preferences.thumbDown && el.preferences.thumbUp ) {
                                        if ( el.preferences.thumbDown.length > 0 ) thumbsDown = el.preferences.thumbDown;
                                        if ( el.preferences.thumbUp.length > 0 ) thumbsUp = el.preferences.thumbUp;
                                    }
                                };
                            });
                        }
                    } else {
                        logout();
                    }
                }

                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated,
                        profileVersion,
                        thumbsDown,
                        thumbsUp,
                        user,
                    }
                });
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        profileVersion: 0,
                        thumbsDown: [],
                        thumbsUp: [],
                        user: null,
                    }
                });
            }
        })();
    }, []);

    const addProfile = async ({ avatarId, firstName, NoCli }) => {
        const params = new URLSearchParams();
        params.append('avatarId', avatarId);
        params.append('firstName', firstName);
        params.append('NoCli', NoCli);

        const res = await ApiService.post('user/addProfile.php', params);
        if ( res && res.data && res.data.ret ) {
            const checkSub = await checkSubscription(NoCli);
            if ( checkSub.result && checkSub.data && checkSub.data.user ) {
                const user = checkSub.data.user;

                let profileVersion = localStorage.getItem(ITEM_PROFILE_VERSION) ? JSON.parse(localStorage.getItem(ITEM_PROFILE_VERSION)) : 0;
                if ( ! checkSub.profileExists ) {
                    localStorage.removeItem(ITEM_PROFILE_VERSION);
                    profileVersion = 0;
                }

                let thumbsDown = [];
                let thumbsUp = [];
                if ( profileVersion === 0 ) {
                    if ( checkSub.data.preferences && checkSub.data.preferences.thumbDown && checkSub.data.preferences.thumbUp ) {
                        if ( checkSub.data.preferences.thumbDown.length > 0 ) thumbsDown = checkSub.data.preferences.thumbDown;
                        if ( checkSub.data.preferences.thumbUp.length > 0 ) thumbsUp = checkSub.data.preferences.thumbUp;
                    }
                } else if ( profileVersion && user.profiles && user.profiles.length > 0 ) {
                    user.profiles.forEach( el => {
                        if ( el.version === profileVersion ) {
                            if ( el.preferences && el.preferences.thumbDown && el.preferences.thumbUp ) {
                                if ( el.preferences.thumbDown.length > 0 ) thumbsDown = el.preferences.thumbDown;
                                if ( el.preferences.thumbUp.length > 0 ) thumbsUp = el.preferences.thumbUp;
                            }
                        };
                    });
                }

                setSession(user);
                dispatch({
                    type: 'UPDATE_PROFILE',
                    payload: {
                        profileVersion,
                        thumbsDown,
                        thumbsUp,
                        user,
                    }
                });

                return true;
            }
        }
        
        return false;
    };

    const removeProfile = async ({ NoCli, version }) => {
        const params = new URLSearchParams();
        params.append('NoCli', NoCli);
        params.append('version', version);

        const res = await ApiService.post('user/removeProfile.php', params);
        if ( res && res.data && res.data.ret ) {
            const checkSub = await checkSubscription(NoCli);
            if ( checkSub.result && checkSub.data && checkSub.data.user ) {
                const user = checkSub.data.user;

                let profileVersion = localStorage.getItem(ITEM_PROFILE_VERSION) ? JSON.parse(localStorage.getItem(ITEM_PROFILE_VERSION)) : 0;
                if ( ! checkSub.profileExists ) {
                    localStorage.removeItem(ITEM_PROFILE_VERSION);
                    profileVersion = 0;
                }

                setSession(user);
                dispatch({
                    type: 'UPDATE_PROFILE',
                    payload: { profileVersion, user }
                });

                return true;
            }
        }
        
        return false;
    };

    const updateProfile = async ({ avatarId, firstName, NoCli, version }) => {
        const params = new URLSearchParams();
        params.append('avatarId', avatarId);
        params.append('firstName', firstName);
        params.append('NoCli', NoCli);
        params.append('version', version);

        const res = await ApiService.post('user/updateProfile.php', params);
        if ( res && res.data && res.data.ret ) {
            const checkSub = await checkSubscription(NoCli);
            if ( checkSub.result && checkSub.data && checkSub.data.user ) {
                const user = checkSub.data.user;

                let profileVersion = localStorage.getItem(ITEM_PROFILE_VERSION) ? JSON.parse(localStorage.getItem(ITEM_PROFILE_VERSION)) : 0;
                if ( ! checkSub.profileExists ) {
                    localStorage.removeItem(ITEM_PROFILE_VERSION);
                    profileVersion = 0;
                }

                let thumbsDown = [];
                let thumbsUp = [];
                if ( profileVersion === 0 ) {
                    if ( checkSub.data.preferences && checkSub.data.preferences.thumbDown && checkSub.data.preferences.thumbUp ) {
                        if ( checkSub.data.preferences.thumbDown.length > 0 ) thumbsDown = checkSub.data.preferences.thumbDown;
                        if ( checkSub.data.preferences.thumbUp.length > 0 ) thumbsUp = checkSub.data.preferences.thumbUp;
                    }
                } else if ( profileVersion && user.profiles && user.profiles.length > 0 ) {
                    user.profiles.forEach( el => {
                        if ( el.version === profileVersion ) {
                            if ( el.preferences && el.preferences.thumbDown && el.preferences.thumbUp ) {
                                if ( el.preferences.thumbDown.length > 0 ) thumbsDown = el.preferences.thumbDown;
                                if ( el.preferences.thumbUp.length > 0 ) thumbsUp = el.preferences.thumbUp;
                            }
                        };
                    });
                }

                setSession(user);
                dispatch({
                    type: 'UPDATE_PROFILE',
                    payload: {
                        profileVersion,
                        thumbsDown,
                        thumbsUp,
                        user,
                    }
                });

                return true;
            }
        }
        
        return false;
    };

    const changeProfileEdit = profileEdit => {
        dispatch({
            type: 'CHANGE_PROFILE_EDIT',
            payload: { profileEdit }
        })
    };

    const changeProfileVersion = profileVersion => {
        setProfileVersion( profileVersion );

        const user = state.user;
        let thumbsDown = [];
        let thumbsUp = [];
        if ( profileVersion === 0 ) {
            if ( user.thumbsDown && user.thumbsUp ) {
                if ( user.thumbsDown.length > 0 ) thumbsDown = user.thumbsDown;
                if ( user.thumbsUp.length > 0 ) thumbsUp = user.thumbsUp;
            }
        } else if ( profileVersion && user.profiles && user.profiles.length > 0 ) {
            user.profiles.forEach( el => {
                if ( el.version === profileVersion ) {
                    if ( el.preferences && el.preferences.thumbDown && el.preferences.thumbUp ) {
                        if ( el.preferences.thumbDown.length > 0 ) thumbsDown = el.preferences.thumbDown;
                        if ( el.preferences.thumbUp.length > 0 ) thumbsUp = el.preferences.thumbUp;
                    }
                };
            });
        }

        dispatch_(forceDataReload());

        dispatch({
            type: 'CHANGE_PROFILE_VERSION',
            payload: {
                profileVersion,
                thumbsDown,
                thumbsUp,
            }
        })
    };

    const updateThumbs = async ({ action, movieId, userId, version }) => {
        ApiService.get(`user/toggleThumbs.php?action=${action}&NoCli=${userId}&version=${version}&movieId=${movieId}`)
            .then(response => {
                if (!response.data.ret) throw new Error();
                return true;
            })
            .catch(() => false);

        let newThumbsDown = [ ...state.thumbsDown ];
        let newThumbsUp = [ ...state.thumbsUp ];

        if ( action === THUMB_ADD_DOWN ) {
            if ( ! newThumbsDown.includes( movieId ) ) newThumbsDown.push( movieId );
            if ( newThumbsUp.includes( movieId ) ) newThumbsUp.splice( newThumbsUp.indexOf( movieId ), 1 );
        } else if ( action === THUMB_ADD_UP ) {
            if ( ! newThumbsUp.includes( movieId ) ) newThumbsUp.push( movieId );
            if ( newThumbsDown.includes( movieId ) ) newThumbsDown.splice( newThumbsDown.indexOf( movieId ), 1 );
        } else if ( action === THUMB_REMOVE_DOWN ) {
            if ( newThumbsDown.includes( movieId ) ) newThumbsDown.splice( newThumbsDown.indexOf( movieId ), 1 );
        } else if ( action === THUMB_REMOVE_UP ) {
            if ( newThumbsUp.includes( movieId ) ) newThumbsUp.splice( newThumbsUp.indexOf( movieId ), 1 );
        }

        dispatch({
            type: 'UPDATE_THUMBS',
            payload: { thumbsDown: newThumbsDown, thumbsUp: newThumbsUp },
        });    
    };

    const checkSubscription = async noCli => {
        return ApiService.get(`user/getUserInfo.php?NoCli=${noCli}`)
            .then(res => {
                if ( res.data && res.data.ret && res.data.data && res.data.data.status && ! res.data.data.status.discarded ) {
                    const profileVersion = localStorage.getItem(ITEM_PROFILE_VERSION) ? JSON.parse(localStorage.getItem(ITEM_PROFILE_VERSION)) : 0;
                    const user = res.data.data.user;
                    let profileExists = false;
                    if ( profileVersion !== 0 || ! user.profiles || user.profiles.length === 0 ) {
                        user.profiles.forEach( el => {
                            if ( el.version === profileVersion ) profileExists = true;
                        });
                    }

                    let data = res.data.data;
                    if ( data.preferences && data.preferences.thumbDown && data.preferences.thumbUp ) {
                        data.user.thumbsDown = data.preferences.thumbDown.length > 0 ? data.preferences.thumbDown : [];
                        data.user.thumbsUp = data.preferences.thumbUp.length > 0 ? data.preferences.thumbUp : [];
                    }

                    return {
                        result: true,
                        data: data,
                        profileExists: profileExists,
                    }
                } else {
                    return { result: false };
                }
            })
            .catch(() => {
                return { result: false };
            });
    };

    const login = async (email, password) => {
        const params = new URLSearchParams();
        params.append('email', email);
        params.append('password', password);

        let result = { sub: false };
        const res = await ApiService.post('user/login.php', params);

        if ( res && res.data ) {
            if ( res.data.ret ) {
                let user = res.data.user;
                if (user && user.NoCli) {
                    const checkSub = await checkSubscription(user.NoCli);
                    if ( checkSub.result && checkSub.data && checkSub.data.user ) {
                        user = checkSub.data.user;

                        let profileVersion = localStorage.getItem(ITEM_PROFILE_VERSION) ? JSON.parse(localStorage.getItem(ITEM_PROFILE_VERSION)) : 0;
                        if ( ! checkSub.profileExists ) {
                            localStorage.removeItem(ITEM_PROFILE_VERSION);
                            profileVersion = 0;
                        }

                        let thumbsDown = [];
                        let thumbsUp = [];
                        if ( profileVersion === 0 ) {
                            if ( checkSub.data.preferences && checkSub.data.preferences.thumbDown && checkSub.data.preferences.thumbUp ) {
                                if ( checkSub.data.preferences.thumbDown.length > 0 ) thumbsDown = checkSub.data.preferences.thumbDown;
                                if ( checkSub.data.preferences.thumbUp.length > 0 ) thumbsUp = checkSub.data.preferences.thumbUp;
                            }
                        } else if ( profileVersion && user.profiles && user.profiles.length > 0 ) {
                            user.profiles.forEach( el => {
                                if ( el.version === profileVersion ) {
                                    if ( el.preferences && el.preferences.thumbDown && el.preferences.thumbUp ) {
                                        if ( el.preferences.thumbDown.length > 0 ) thumbsDown = el.preferences.thumbDown;
                                        if ( el.preferences.thumbUp.length > 0 ) thumbsUp = el.preferences.thumbUp;
                                    }
                                };
                            });
                        }

                        setSession(user);
                        dispatch({
                            type: 'LOGIN',
                            payload: {
                                profileVersion,
                                thumbsDown,
                                thumbsUp,
                                user,
                            }
                        });

                        result.sub = true;
                        if ( user && user.profileMax && parseInt( user.profileMax ) > 1 ) result.isMultiProfile = true;
                    }
                }
            } else if ( res.data.data ) {
                result.error = res.data.data;
            }
        }

        return result;
    };

    const logout = () => {
        clearSession();
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                addProfile,
                removeProfile,
                updateProfile,
                changeProfileEdit,
                changeProfileVersion,
                updateThumbs,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext as default, AuthProvider };
