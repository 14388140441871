import React, { useEffect, createRef, useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import DetailsButton from './DetailsButton';
import DetailsInformation from './DetailsInformation';
import DetailsSeries from './DetailsSeries';
import Image from 'components/Image';
import Navigation, { VerticalList } from 'components/Navigation';
import Overlay from 'components/Overlay';

import { getImageUrl, typeFullscreen, visual4 } from 'api/ImageManager';
import { getMovie } from 'redux/actions/CatalogActions';
import { goBack, isReturnEvent } from 'utils/NavigationUtils';

const PageDetails = () => {
    const { profileVersion, user } = useAuth();
    const dispatch = useDispatch();
    const { FilmID: filmId } = useParams();
    const history = useHistory();

    const movie = useSelector(state => state.catalog.movie);
    const [seriesFocus, setSeriesFocus] = useState(false);

    const navigation = createRef();
    const verticalList = createRef();
    const detailsRef = createRef();

    const keyDownHandler = useCallback( event => {
            if ( isReturnEvent( event ) ) goBack(history);
        }
    , [history]);

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => { window.removeEventListener('keydown', keyDownHandler); };
    }, [keyDownHandler]);

    useEffect(() => {
        if (movie?.transaction !== filmId) {
            dispatch(getMovie(filmId, user.NoCli, profileVersion));
        }
    }, [filmId, dispatch, movie, profileVersion, user]);

    const backgroundUrl = useMemo(() => getImageUrl(filmId, typeFullscreen, visual4), [filmId]);

    const buttonOnClickHandler = () => { setEpisodesFocus(!seriesFocus); };
    const setEpisodesFocus = value => { setSeriesFocus(value); };

    return (
        <Navigation ref={navigation}>
            <div className="details" ref={detailsRef}>
                <VerticalList ref={verticalList}>
                    <Image
                        url={backgroundUrl}
                        size={Image.SIZE_FULLSCREEN}
                        onError={evt => {
                            evt.target.src = backgroundUrl.replace('VISUEL4', 'VISUEL');
                        }}
                    />
                    <Overlay blur={seriesFocus}>
                        <div
                            className={
                                'details__scrollable' +
                                (seriesFocus
                                    ? ` details__scrollable--episodes`
                                    : ` details__scrollable--infos`)
                            }
                        >
                            <DetailsInformation
                                navigation={navigation}
                                movie={movie}
                                isVisible={!seriesFocus}
                                setVisible={() => setEpisodesFocus(false)}
                            />
                            {movie && movie?.type === 'series' && (
                                <>
                                    <DetailsButton
                                        buttonOnClickHandler={buttonOnClickHandler}
                                        episodesFocus={seriesFocus}
                                    />
                                    <DetailsSeries
                                        title={movie.titre}
                                        seasons={movie.seasons}
                                        isVisible={seriesFocus}
                                        setVisible={() => setEpisodesFocus(true)}
                                        navigation={navigation}
                                    />
                                </>
                            )}
                        </div>
                    </Overlay>
                </VerticalList>
            </div>
        </Navigation>
    );
};

export default PageDetails;
